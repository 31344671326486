import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { JsonNameReadonly } from 'tserialize';

export class MeetingPhotoModel {
  @JsonNameReadonly('id')
  id!: string;

  @JsonNameReadonly('type')
  type!: MEETING_PHOTO_TYPE;

  constructor(data: MeetingPhotoModel) {
    Object.assign(this, data);
  }
}
