import { IAvatarProps } from '@t15-ui-kit/avatar/interface';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import * as styles from 'common/components/ui/like-navigator/like-navigator.styles';
import { cloneElement, PropsWithChildren, ReactElement } from 'react';

type TLikeNavigatorButtonProps = PropsWithChildren<{
  accLeft?: ReactElement<IAvatarProps>;
  onClick(): void;
}>;

export function LikeNavigatorButton({ children, accLeft, onClick }: TLikeNavigatorButtonProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <div className={styles.button} onClick={onClick}>
      <CellOffset offset={[isMobile ? 'xs' : 's', 'm']}>
        <Grid container alignItems="center" elementOffset={['none', 's']} wrap="nowrap">
          {!!accLeft && <Grid>{cloneElement(accLeft, { size: 'm' })}</Grid>}

          <Grid>
            <Typography size="l" weight="500">
              {children}
            </Typography>
          </Grid>
        </Grid>
      </CellOffset>
    </div>
  );
}
