import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { ActionsSpecialMeetingSection } from 'modules/special/views/single/components/sections/actions-special-meeting-section';
import { AddressAndDatetimeOfSpecialMeetingSection } from 'modules/special/views/single/components/sections/address-and-datetime-of-special-meeting-section';
import { CommentsOfSpecialMeetingSection } from 'modules/special/views/single/components/sections/comments-of-special-meeting-section';
import { DetailsOfSpecialMeetingSection } from 'modules/special/views/single/components/sections/details-of-special-meeting-section';
import { GroupOfSpecialMeetingSection } from 'modules/special/views/single/components/sections/group-of-special-meeting-section';

export function ViewSpecialMeetingAction(): JSX.Element {
  const isMobile = useMobile();

  return (
    <>
      <ActionsSpecialMeetingSection />
      <Grid container direction="column" elementOffset={[isMobile ? 'm' : 'xl', 'none']} wrap="nowrap">
        <Grid>
          <AddressAndDatetimeOfSpecialMeetingSection />
        </Grid>
        <Grid>
          <DetailsOfSpecialMeetingSection />
        </Grid>
        <Grid>
          <GroupOfSpecialMeetingSection />
        </Grid>
        <Grid>
          <CommentsOfSpecialMeetingSection />
        </Grid>
      </Grid>
    </>
  );
}
