import axios, { AxiosError } from 'axios';
import { IWithMetaError } from 'common/errors/with-meta.error';
import { WithRawError } from 'common/errors/with-raw.error';

type THttpRequestErrorRequest = {
  url?: string;
  method?: string;
  data?: string | object;
};

type THttpRequestErrorResponse<T extends object = object> = {
  data?: T;
  status?: number;
};

type THttpRequestErrorMeta<T extends object = object> = {
  request?: THttpRequestErrorRequest;
  response?: THttpRequestErrorResponse<T>;
};

export class HttpRequestError<T extends object = object>
  extends WithRawError
  implements IWithMetaError<THttpRequestErrorMeta<T>>
{
  meta?: THttpRequestErrorMeta<T>;

  isAbort: boolean;

  constructor(rawError?: Error, message?: string) {
    super(rawError, message);

    this.name = 'HttpRequestError';
    this.isAbort = axios.isCancel(this.rawError);

    if (HttpRequestError.#isAxiosError(rawError)) {
      this.message = rawError.message;

      let data: object | string = rawError.config?.data;
      try {
        data = JSON.parse(rawError.config?.data);
      } catch (e) {
        void 0;
      }

      this.meta = {
        request: {
          url: rawError.config?.url,
          method: rawError.config?.method,
          data
        },
        response: {
          data: rawError.response?.data,
          status: rawError.response?.status
        }
      };
    }
  }

  static #isAxiosError(error: unknown): error is AxiosError {
    return !!error && typeof error === 'object' && 'isAxiosError' in error && !!(error as AxiosError).isAxiosError;
  }
}
