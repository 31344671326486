import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { JsonName, serialize } from 'tserialize';

export class GetSpecialMeetingListRequestModel {
  @JsonName('pageNumber')
  page?: number;

  @JsonName('pageSize')
  limit?: number;

  @JsonName('search')
  query?: string;

  @JsonName('groupIds', (groupIds?: Array<number>) => groupIds?.join(','))
  groupIds?: Array<number>;

  @JsonName('statuses', (statuses?: Array<MEETING_STATUS>) => statuses?.join(','))
  statuses?: Array<MEETING_STATUS>;

  constructor(data: Omit<GetSpecialMeetingListRequestModel, 'toQueryString'>) {
    Object.assign(this, data);
  }

  toQueryString(): string {
    return new URLSearchParams(serialize(this) as Record<string, string>).toString();
  }
}
