import { MeetingCommentRow } from 'common/components/ui/meeting-comment-row';
import { MeetingCommentModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useContext } from 'react';

export function CommentsForMeetingAction(): JSX.Element {
  const { store } = useContext(MeetingSingleCompositionContext);

  const comments = useRxBind(store.comments$);

  return (
    <>
      {comments.map(
        (comment: MeetingCommentModel, index: number): JSX.Element => (
          <MeetingCommentRow key={index} comment={comment} />
        )
      )}
    </>
  );
}
