import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext } from 'react';

export function AddressAndDatetimeOfMeetingActionFooter(): JSX.Element {
  const {
    store,
    forceLoadList,
    addressAndDatetime: {
      store: { date$, time$, address$ },
      saveAddressAndDatetime
    }
  } = useContext(MeetingSingleCompositionContext);

  const [isLoading, setLoading] = useSafeState(false);
  const date = useRxBind(date$);
  const time = useRxBind(time$);
  const address = useRxBind(address$);

  const handleClick = useCallback((): void => {
    setLoading(true);

    void saveAddressAndDatetime()
      .then((): void => {
        forceLoadList(MEETING_LIST_TYPE.MY);
        store.action = MEETING_SINGLE_ACTION.VIEW_MEETING;
      })
      .catch(propagateError)
      .then((): void => setLoading(false));
  }, [store, forceLoadList, saveAddressAndDatetime, setLoading]);

  return (
    <Footer>
      <Button disabled={!date || !time || !address} onClick={handleClick} isLoading={isLoading}>
        Сохранить
      </Button>
    </Footer>
  );
}
