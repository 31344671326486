import { useCallback, useEffect, useRef } from 'react';

export function useSafeDispatch(): (dispatcher: VoidFunction) => void {
  const isMounted = useRef<boolean>(true);
  useEffect(
    (): VoidFunction => (): void => {
      isMounted.current = false;
    },
    []
  );

  return useCallback((dispatch: VoidFunction): void => {
    isMounted.current && dispatch();
  }, []);
}
