import { UserGuard } from 'common/components/ui/user-guard';
import { UserRight } from 'core/enums/user-right.enum';
import { DashboardOfSpecialListSection } from 'modules/special/views/list/components/sections/dashboard-of-special-list-section';
import { SpecialListComposition } from 'modules/special/views/list/compositions/special-list.composition';
import { SpecialListCompositionContext } from 'modules/special/views/list/contexts/special-list-composition.context';
import { useEffect, useMemo } from 'react';

function SpecialListInternal(): JSX.Element {
  const composition = useMemo((): SpecialListComposition => new SpecialListComposition(), []);

  useEffect((): VoidFunction => {
    return (): void => composition?.destroy();
  }, [composition]);

  return (
    <SpecialListCompositionContext.Provider value={composition}>
      <DashboardOfSpecialListSection />
    </SpecialListCompositionContext.Provider>
  );
}

export function SpecialList(): JSX.Element {
  return (
    <UserGuard right={UserRight.SPECIAL}>
      <SpecialListInternal />
    </UserGuard>
  );
}
