import { IDispatcherService, TDispatcherListener } from 'common/services/dispatcher/dispatcher.interface';

export class DispatcherService implements IDispatcherService {
  readonly #listeners: Record<string, Set<(event: CustomEvent) => void>> = {};

  listen(type: string, listener: TDispatcherListener): VoidFunction {
    let listeners = this.#listeners[type];

    if (!listeners) {
      listeners = new Set<(event: CustomEvent) => void>();
      this.#listeners[type] = listeners;
    }

    listeners.add(listener);

    return (): void => {
      listeners.delete(listener);
    };
  }

  dispatch(event: CustomEvent): void {
    const listeners = this.#listeners[event.type];

    listeners?.forEach((listener: (event: CustomEvent) => void): void => {
      listener(event);
    });
  }
}
