import { CASE_TYPE } from 'common/utils/formatters/case-formatter/case-formatter.enum';

export class CaseFormatter {
  static uppercase(value: string): string {
    return value.toUpperCase();
  }

  static lowercase(value: string): string {
    return value.toLowerCase();
  }

  static split(value: string, type: CASE_TYPE): Array<string> {
    switch (type) {
      case CASE_TYPE.CAMEL_CASE:
        return value.split(/(?=[A-Z])/);
      case CASE_TYPE.KEBAB_CASE:
        return value.split('-');
      case CASE_TYPE.SNAKE_CASE:
        return value.split('_');
      default:
        return [value];
    }
  }

  static format(from: CASE_TYPE, to: CASE_TYPE, value: string): string {
    const words = this.split(value, from);

    switch (to) {
      case CASE_TYPE.CAMEL_CASE: {
        const [firstWord, ...restWords] = words;

        return [
          CaseFormatter.lowercase(firstWord),
          ...restWords.map(([firstLetter, ...restLetters]: string): string =>
            [CaseFormatter.uppercase(firstLetter), ...restLetters.map(CaseFormatter.lowercase)].join('')
          )
        ].join('');
      }
      case CASE_TYPE.KEBAB_CASE:
        return words.map(CaseFormatter.lowercase).join('-');
      case CASE_TYPE.SNAKE_CASE:
        return words.map(CaseFormatter.lowercase).join('_');
      default:
        return value;
    }
  }

  static map<T extends string>(from: CASE_TYPE, to: CASE_TYPE, ...values: Array<T>): Record<T, string> {
    return values.reduce(
      (acc: Record<T, string>, value: T): Record<T, string> => ({
        ...acc,
        [value]: CaseFormatter.format(from, to, value)
      }),
      {} as unknown as Record<T, string>
    );
  }
}
