import { HttpRequestError } from 'common/services/http-request';

export enum LimeErrorCode {
  INVALID_ADDRESS = '1'
}

type TLimeErrorResponse = {
  order_id?: string;
  meeting_id?: string;
  code: LimeErrorCode;
  message: string;
};

export class LimeError extends HttpRequestError<TLimeErrorResponse> {
  readonly code?: LimeErrorCode;

  constructor(rawError?: Error, message?: string) {
    super(rawError, message);

    if (rawError instanceof HttpRequestError) {
      Object.assign(this, rawError);
    }

    this.name = 'LimeError';
    this.message = this.meta?.response?.data?.message ?? this.message;
    this.code = this.meta?.response?.data?.code;
  }
}
