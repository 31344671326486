import { RoutingInDialog } from 'common/components/internal/routing-in-dialog';
import { withUiKitEnvironment } from 'common/components/internal/with-ui-kit-environment';
import { LoadingScreen } from 'common/components/ui/loading-screen';
import { UserModel } from 'common/models/rpc/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { CORE_MODAL_ROUTING_PATH } from 'core/enums/core-modal-routing.enum';
import { CORE_ROOT_ROUTING_PATH } from 'core/enums/core-root-routing.enum';
import { coreApplicationRoutingProvider } from 'core/providers/core-application-routing.provider';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { UserContextProvider } from 'core/providers/user-context-provider';
import { authorizationService } from 'modules/authorization/services/authorization.service';
import { Authorization } from 'modules/authorization/views/main';
import { MeetingSingle } from 'modules/meeting/views/single';
import { Root } from 'modules/root/views/main';
import { SpecialSingle } from 'modules/special/views/single';
import { useLayoutEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router';

type TApplicationState = {
  isAuthorizationVerified: boolean;
  user?: UserModel;
};

export const Application = withUiKitEnvironment(
  (): JSX.Element => {
    const [{ isAuthorizationVerified, user }, setState] = useState<TApplicationState>({
      isAuthorizationVerified: false
    });

    useLayoutEffect((): void => {
      coreApplicationRoutingProvider.init();

      void authorizationService.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          authorizationService
            .getUser()
            .then((authorizedUser: UserModel) => setState({ isAuthorizationVerified: true, user: authorizedUser }))
            .catch((error: Error) => {
              propagateError(error);
            });
        } else {
          setState({ isAuthorizationVerified: true });
          coreRootRouting.goToAuthorization();
        }
      });
    }, []);

    if (!isAuthorizationVerified) {
      return <LoadingScreen />;
    }

    return (
      <UserContextProvider value={user}>
        <Router history={coreRootRouting.history}>
          <Switch>
            <Route path={CORE_ROOT_ROUTING_PATH.AUTHORIZATION} component={Authorization} />
            {user && <Route path={CORE_ROOT_ROUTING_PATH.ROOT} component={Root} />}
          </Switch>
        </Router>

        {user && (
          <Router history={coreModalRouting.history}>
            <RoutingInDialog>
              <Route path={CORE_MODAL_ROUTING_PATH.MEETING} component={MeetingSingle} />
              <Route path={CORE_MODAL_ROUTING_PATH.SPECIAL} component={SpecialSingle} />
            </RoutingInDialog>
          </Router>
        )}
      </UserContextProvider>
    );
  },
  { url: '/proxy/ui-kit' }
);
