import { capitalize } from '../formatters/capitalize';

export function hashCode(str: string): number {
  let hashNumber = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise, @typescript-eslint/no-magic-numbers
    hashNumber = (Math.imul(31, hashNumber) + str.charCodeAt(i)) | 0;
  }

  return hashNumber;
}

export enum PrettyColor {
  CORAL = 'coral',
  ORCHID = 'orchid',
  LAVENDER = 'lavender',
  SKY = 'sky',
  EMERALD = 'emerald',
  SAND = 'sand',
  FLAMINGO = 'flamingo',
  AMETHYST = 'amethyst',
  INDIGO = 'indigo',
  MINT = 'mint'
}

export type TPrettyColor =
  | 'Coral'
  | 'Orchid'
  | 'Lavender'
  | 'Sky'
  | 'Emerald'
  | 'Sand'
  | 'Flamingo'
  | 'Amethyst'
  | 'Indigo'
  | 'Mint';

const PrettyColors = Object.keys(PrettyColor);

export function stringToColor(str: string): PrettyColor {
  const index = Math.abs(hashCode(str)) % PrettyColors.length;
  const name = PrettyColors[index] as keyof typeof PrettyColor;

  return PrettyColor[name];
}

export function stringToColorUIKit(str: string): TPrettyColor {
  const color = stringToColor(str);

  return capitalize(color) as TPrettyColor;
}
