import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext } from 'react';

export function AddCommentForMeetingActionFooter(): JSX.Element {
  const {
    store,
    comments: {
      store: { comment$ },
      saveDetails
    }
  } = useContext(MeetingSingleCompositionContext);

  const [isLoading, setLoading] = useSafeState(false);
  const comment = useRxBind(comment$);

  const handleClick = useCallback((): void => {
    setLoading(true);

    void saveDetails()
      .then((): void => {
        store.action = MEETING_SINGLE_ACTION.VIEW_MEETING;
      })
      .catch(propagateError)
      .then((): void => setLoading(false));
  }, [store, saveDetails, setLoading]);

  return (
    <Footer>
      <Button disabled={!comment} onClick={handleClick} isLoading={isLoading}>
        Сохранить
      </Button>
    </Footer>
  );
}
