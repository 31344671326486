import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export const enum FORMAT_DATE_USE_DAY_TYPE {
  NONE,
  WITH,
  ONLY
}

export function formatDate(
  dateTime: dayjs.Dayjs,
  useDayType: FORMAT_DATE_USE_DAY_TYPE = FORMAT_DATE_USE_DAY_TYPE.NONE
): string {
  const date = dateTime.locale('ru').format('D MMMM');

  if (useDayType !== FORMAT_DATE_USE_DAY_TYPE.NONE) {
    let dayType: NotRequired<string>;

    if (dateTime.isToday()) {
      dayType = 'Сегодня';
    } else if (dateTime.isTomorrow()) {
      dayType = 'Завтра';
    } else if (dateTime.isYesterday()) {
      dayType = 'Вчера';
    }

    if (dayType) {
      if (useDayType === FORMAT_DATE_USE_DAY_TYPE.ONLY) {
        return dayType;
      } else if (useDayType === FORMAT_DATE_USE_DAY_TYPE.WITH) {
        return `${dayType}, ${date}`;
      }
    }
  }

  return date;
}
