export class DeleteMeetingPhotoRequestModel {
  /** Идентификатор встречи */
  id!: number;

  photoId!: string;

  constructor(data: DeleteMeetingPhotoRequestModel) {
    Object.assign(this, data);
  }
}
