import { LikeNavigator, LikeNavigatorRow } from 'common/components/ui/like-navigator';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext } from 'react';

export function GroupOfSpecialMeetingSection(): JSX.Element {
  const {
    data: { group }
  } = useContext(SpecialSingleCompositionContext);

  return (
    <LikeNavigator title="Группа">
      <LikeNavigatorRow title="Группа">{group.name}</LikeNavigatorRow>
      {!!group.taxCode && (
        <LikeNavigatorRow title="ИНН" copyValue={group.taxCode}>
          {group.taxCode}
        </LikeNavigatorRow>
      )}
    </LikeNavigator>
  );
}
