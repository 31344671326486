import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { useCallback, useContext } from 'react';

export function DatetimeOfSpecialMeetingActionFooter(): JSX.Element {
  const {
    store,
    forceLoadList,
    datetime: {
      store: { date$, time$ },
      saveDatetime
    }
  } = useContext(SpecialSingleCompositionContext);

  const [isLoading, setLoading] = useSafeState(false);
  const date = useRxBind(date$);
  const time = useRxBind(time$);

  const handleClick = useCallback((): void => {
    setLoading(true);

    void saveDatetime()
      .then((): void => {
        forceLoadList();
        store.action = SPECIAL_SINGLE_ACTION.VIEW_SPECIAL_MEETING;
      })
      .catch(propagateError)
      .then((): void => setLoading(false));
  }, [store, forceLoadList, saveDatetime, setLoading]);

  return (
    <Footer>
      <Button disabled={!date || !time} onClick={handleClick} isLoading={isLoading}>
        Сохранить
      </Button>
    </Footer>
  );
}
