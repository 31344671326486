import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { JsonName, serialize } from 'tserialize';

export class GetMeetingListRequestModel {
  /** Тип списка */
  @JsonName()
  type!: MEETING_LIST_TYPE;

  /** Максимальное количество списка */
  @JsonName()
  limit!: number;

  /** Запрос поиска: если строка пустая - отправлять на бек ее не надо */
  @JsonName('search', (value?: string): NotRequired<string> => value || void 0)
  search?: string;

  /** Токен для получения продолжения списка */
  @JsonName()
  continueToken?: number;

  constructor(data: Omit<GetMeetingListRequestModel, 'toQueryString'>) {
    Object.assign(this, data);
  }

  toQueryString(): string {
    return new URLSearchParams(serialize(this) as Record<string, string>).toString();
  }
}
