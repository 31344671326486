import { css } from 'emotion';

export const selectGridItem = css({
  maxHeight: '100%',
  overflowY: 'auto'
});

export const select = css({
  padding: '2px'
});
