import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { MeetingDataModel, MeetingPhotoModel } from 'common/models/rpc/lime';
import { BehaviorSubject } from 'rxjs';

export class MeetingPhotosStore implements IDestroyable {
  readonly photos$: BehaviorSubject<Partial<Record<MEETING_PHOTO_TYPE, string>>>;

  get photos(): Partial<Record<MEETING_PHOTO_TYPE, string>> {
    return this.photos$.getValue();
  }

  set photos(photos: Partial<Record<MEETING_PHOTO_TYPE, string>>) {
    this.photos$.next(photos);
  }

  constructor({ photos }: MeetingDataModel) {
    this.photos$ = new BehaviorSubject<Partial<Record<MEETING_PHOTO_TYPE, string>>>(
      photos?.reduce(
        (
          acc: Partial<Record<MEETING_PHOTO_TYPE, string>>,
          photo: MeetingPhotoModel
        ): Partial<Record<MEETING_PHOTO_TYPE, string>> => {
          acc[photo.type] = photo.id;

          return acc;
        },
        {}
      ) ?? {}
    );
  }

  destroy(): void {
    this.photos$.complete();
  }
}
