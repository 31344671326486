import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { MeetingCommentModel, MeetingDataModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { SpecialListComposition } from 'modules/special/views/list/compositions/special-list.composition';
import { SpecialAssignToCourierService } from 'modules/special/views/single/services/special-assign-to-courier.service';
import { SpecialAssignToSellerService } from 'modules/special/views/single/services/special-assign-to-seller.service';
import { SpecialCommentsService } from 'modules/special/views/single/services/special-comments.service';
import { SpecialDatetimeService } from 'modules/special/views/single/services/special-datetime.service';
import { SpecialSingleStore } from 'modules/special/views/single/stores/special-single.store';

export class SpecialSingleComposition implements IDestroyable {
  readonly store: SpecialSingleStore;

  readonly comments: SpecialCommentsService;
  readonly assignToCourier: SpecialAssignToCourierService;
  readonly assignToSeller: SpecialAssignToSellerService;
  readonly datetime: SpecialDatetimeService;

  protected constructor(readonly data: MeetingDataModel) {
    this.store = new SpecialSingleStore(this.data);

    this.comments = new SpecialCommentsService(this.data);
    this.assignToCourier = new SpecialAssignToCourierService(this.data);
    this.assignToSeller = new SpecialAssignToSellerService(this.data);
    this.datetime = new SpecialDatetimeService(this.data);

    this.comments.dispatcher.listenNextComment(this.#handleNextComment);
  }

  static async make(id: number): Promise<SpecialSingleComposition> {
    const data = await limeTransport.getSpecialMeeting(id);

    return new SpecialSingleComposition(data);
  }

  forceLoadList = (): void => {
    SpecialListComposition.dispatcher.dispatchForceLoad();
  };

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
    this.comments.destroy();
    this.assignToCourier.destroy();
    this.assignToSeller.destroy();
    this.datetime.destroy();
  }

  #handleNextComment = (comment: MeetingCommentModel): void => {
    this.store.comments$.next([comment, ...this.store.comments]);
  };
}
