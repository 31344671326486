import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { Illustration } from '@t15-ui-kit/illustrations';
import { Typography } from '@t15-ui-kit/typography';
import { UserRight } from 'core/enums/user-right.enum';
import { userContext } from 'core/providers/user-context-provider';
import { css } from 'emotion';
import { ReactNode, useContext } from 'react';

type TUserGuardProps = {
  right: UserRight;
  children: ReactNode;
};

const styles = {
  root: css({
    height: '100%',
    padding: '40px 0'
  })
};

export function UserGuard({ right, children }: TUserGuardProps): JSX.Element {
  const user = useContext(userContext);
  const hasAccess = user?.userRights?.includes(right);

  if (hasAccess) {
    return <>{children}</>;
  } else {
    return (
      <div className={styles.root}>
        <Illustration type="NoRights" isClickToPauseDisabled={false} isPaused={false} isStopped={false} />
        <CellEmpty height="30" />
        <Typography align="center" size="l" weight="500">
          Доступ запрещён
        </Typography>
      </div>
    );
  }
}
