import { DControl, DControlGroup } from '@t15-ui-kit/dControl';
import { DOption, DSelect } from '@t15-ui-kit/dSelect';
import { ColorsBasicEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { SimpleRow } from 'common/components/ui/simple-row';
import { SpinnerAtCenter } from 'common/components/ui/spinner-at-center';
import { TimeSlotModel } from 'common/models/rpc/lime';
import { EqualityService } from 'common/services/equality.service';
import { ScheduleMeetings } from 'common/utils/business/schedule-meetings';
import { formatTimePart } from 'common/utils/formatters/format-time-part';
import dayjs from 'dayjs';
import { useLayoutEffect, useMemo, useState } from 'react';

type TMeetingTimeSlotSelectRowProps = {
  date: NotRequired<dayjs.Dayjs>;
  timeSlots: NotRequired<Array<TimeSlotModel>>;
  timezone: NotRequired<string>;
  city: NotRequired<string>;
  defaultTime: NotRequired<TimeSlotModel>;
  onSelect(time: NotRequired<TimeSlotModel>): void;
};

export function MeetingTimeSlotSelectRow({
  date,
  timeSlots,
  timezone,
  city,
  defaultTime,
  onSelect
}: TMeetingTimeSlotSelectRowProps): JSX.Element {
  const isMobile = useMobile();

  const [selectedHour, setSelectedHour] = useState<NotRequired<string>>(
    defaultTime ? formatTimePart(defaultTime.hour) : void 0
  );
  const [selectedMinute, setSelectedMinute] = useState<NotRequired<string>>(
    defaultTime ? formatTimePart(defaultTime.minute) : '00'
  );
  const selectedTimeSlot = useMemo(
    (): NotRequired<TimeSlotModel> =>
      selectedHour && selectedMinute
        ? new TimeSlotModel({ hour: Number(selectedHour), minute: Number(selectedMinute) })
        : void 0,
    [selectedHour, selectedMinute]
  );

  const hours = useMemo((): Array<string> => ScheduleMeetings.defaultHours.map(formatTimePart), []);
  const minutes = useMemo((): Array<string> => ScheduleMeetings.defaultMinutes.map(formatTimePart), []);

  const [isInvalid, setInvalid] = useState(false);

  useLayoutEffect((): void => {
    if (!selectedTimeSlot) {
      onSelect(void 0);
    } else if (timeSlots) {
      const isAvailableTimeSlot = timeSlots && EqualityService.includes(timeSlots, selectedTimeSlot);
      setInvalid(!isAvailableTimeSlot);

      onSelect(isAvailableTimeSlot ? selectedTimeSlot : void 0);
    }
  }, [timeSlots, selectedTimeSlot, onSelect]);

  return (
    <SimpleRow
      title={'Во\xA0сколько'}
      description={
        timezone || city
          ? `Время местное, ${
              city ? `${city}${timezone ? ` (UTC${timezone})` : ''}` : timezone ? `UTC${timezone}` : ''
            }`
          : void 0
      }
    >
      {date && timeSlots ? (
        timeSlots.length ? (
          <DControlGroup template={['3', '9']}>
            <DControl label="Часов" isMobile={isMobile}>
              <DSelect
                label={selectedHour ?? <Typography color={ColorsShadesEnum.Grey400}>Выберите время встречи</Typography>}
                titleMobile="Во сколько часов"
                onChange={setSelectedHour}
                block
              >
                {hours.map(
                  (hour: string): JSX.Element => (
                    <DOption key={hour} value={hour} selected={hour === selectedHour}>
                      {hour}
                    </DOption>
                  )
                )}
              </DSelect>
            </DControl>
            <DControl label="Минут" invalidMessage={!!isInvalid && 'Это время недоступно'} isMobile={isMobile}>
              <DSelect onChange={setSelectedMinute} titleMobile="Во сколько минут" block>
                {minutes.map(
                  (minute: string): JSX.Element => (
                    <DOption key={minute} value={minute} selected={minute === selectedMinute}>
                      {minute}
                    </DOption>
                  )
                )}
              </DSelect>
            </DControl>
          </DControlGroup>
        ) : (
          <Typography size="m" weight="400" color={ColorsBasicEnum.Alert}>
            В&nbsp;этот день нет свободного времени
          </Typography>
        )
      ) : date ? (
        <SpinnerAtCenter />
      ) : (
        <Typography type="div" size="m" weight="400">
          Сначала выберите день встречи
        </Typography>
      )}
    </SimpleRow>
  );
}
