import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import {
  DeleteMeetingPhotoRequestModel,
  MeetingDataModel,
  ProductDocumentInfoModel,
  UploadMeetingPhotoRequestModel,
  UploadMeetingPhotoResponseModel
} from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { MeetingPhotosStore } from 'modules/meeting/views/single/stores/meeting-photos.store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class MeetingPhotosService implements IDestroyable {
  readonly store: MeetingPhotosStore;

  readonly isAllUploaded$: Observable<boolean>;

  readonly #data: MeetingDataModel;
  readonly #controllers: Partial<Record<MEETING_PHOTO_TYPE, AbortController>> = {};

  constructor(data: MeetingDataModel) {
    this.#data = data;
    this.store = new MeetingPhotosStore(this.#data);

    this.isAllUploaded$ = this.store.photos$.pipe(
      map((photos: Partial<Record<MEETING_PHOTO_TYPE, string>>): boolean => {
        const uploadedPhotoTypes = Object.keys(photos) as Array<MEETING_PHOTO_TYPE>;

        return this.#data.product.documents
          .filter((document: ProductDocumentInfoModel) => document.isRequired)
          .every((document: ProductDocumentInfoModel) => uploadedPhotoTypes.includes(document.type));
      }),
      distinctUntilChanged()
    );
  }

  uploadPhoto(file: File, type: MEETING_PHOTO_TYPE, onProgress?: (progress: number) => void): Promise<void> {
    if (!this.#data.product.documents.some((document: ProductDocumentInfoModel) => document.type === type)) {
      return Promise.reject(new Error(`Файл типа ${type} не ожидается к загрузке`));
    }

    if (type in this.#controllers) {
      return Promise.reject(new Error(`Файл типа ${type} уже загружается`));
    }

    const controller = new AbortController();
    this.#controllers[type] = controller;

    return limeTransport
      .uploadMeetingPhoto(
        new UploadMeetingPhotoRequestModel({ id: this.#data.id, file, type }),
        controller.signal,
        onProgress
      )
      .then(({ id }: UploadMeetingPhotoResponseModel): void => {
        this.store.photos = {
          ...this.store.photos,
          [type]: id
        };
      })
      .finally((): void => {
        delete this.#controllers[type];
      });
  }

  cancelUploadPhoto(type: MEETING_PHOTO_TYPE): void {
    if (type in this.#controllers) {
      this.#controllers[type]?.abort();
      delete this.#controllers[type];
    }
  }

  removePhoto(type: MEETING_PHOTO_TYPE): Promise<void> {
    const photoId = this.store.photos[type];

    if (photoId) {
      return limeTransport
        .deleteMeetingPhoto(new DeleteMeetingPhotoRequestModel({ id: this.#data.id, photoId }))
        .then((): void => {
          delete this.store.photos[type];

          this.store.photos = { ...this.store.photos };
        });
    } else {
      return Promise.resolve();
    }
  }

  destroy(): void {
    this.store.destroy();
  }
}
