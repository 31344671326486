import { AutocompleteField, IAutocompleteFieldElementType } from '@t15-ui-kit/autocompleteField';
import { Button } from '@t15-ui-kit/button';
import { DControl } from '@t15-ui-kit/dControl';
import { useMobile } from '@t15-ui-kit/media';
import { Modal } from '@t15-ui-kit/modal';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { useDebounce } from 'common/utils/hooks/use-debounce';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { propagateError } from 'common/utils/processing/propagate-error';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CityListComposition } from '../compositions/city-list.composition';

type CreateCityPopupProps = {
  cityListComposition: CityListComposition;
};

const DEBOUNCE_MS = 500;

export const CreateCityPopup = ({ cityListComposition }: CreateCityPopupProps): JSX.Element => {
  const { store, loadCityList, suggestCity } = cityListComposition;
  const [optionList, setOptionList] = useState<Array<IAutocompleteFieldElementType>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const query = useRxBind(store.query$);
  const isMobile = useMobile();
  const debouncedQuery = useDebounce(query, DEBOUNCE_MS);

  useEffect(() => {
    return () => {
      store.query = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!debouncedQuery) {
      return;
    }

    suggestCity(debouncedQuery).then(setOptionList).catch(setOptionList);
  }, [debouncedQuery, suggestCity]);

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      store.query = value;
    },
    [store]
  );

  const handleSelect = useCallback(
    (el: IAutocompleteFieldElementType) => {
      store.query = el.title;
    },
    [store]
  );

  const handleCreate = useCallback(() => {
    setIsLoading(true);
    void cityListComposition
      .createCity(query)
      .then(loadCityList)
      .catch(propagateError)
      .finally(() => {
        setIsLoading(false);
        uiKitService.closePopup();
      });
  }, [query, loadCityList, cityListComposition]);

  return (
    <Modal
      headerText="Создать город"
      singleButton={
        <Button isLoading={isLoading} onClick={handleCreate}>
          Добавить
        </Button>
      }
      secondButton={<Button onClick={() => uiKitService.closePopup()}>Закрыть</Button>}
    >
      <DControl type="input" borderRadius="l" isMobile={isMobile} isMobileBottomOffset={false}>
        <AutocompleteField
          value={query}
          emptyMessage="Ничего не найдено"
          placeholder="Введите название города"
          onChange={handleChange}
          list={optionList}
          onSelect={handleSelect}
        />
      </DControl>
    </Modal>
  );
};
