import { TimeSlotModel } from 'common/models/rpc/lime';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const HOUR_MINUTES_INTERVALS = [0, 15, 30, 45];
const MIN_HOUR = 8;
const MAX_HOUR = 23;

// Дата "Сегодня" будет выводиться только если до следующего дня не меньше указанного количества минут
const MIN_DIFF_WITH_NEXT_DAY_MINUTES = 30;
const DAYS_IN_WEEK = 7;

export class ScheduleMeetings {
  static defaultHours: Array<number> = Array(MAX_HOUR - MIN_HOUR + 1)
    .fill(MIN_HOUR)
    .map((hour: number, index: number): number => hour + index);

  static defaultMinutes: Array<number> = HOUR_MINUTES_INTERVALS;

  /** Возвращает доступные дни на 1 неделю вперед */
  static getDateSlotsSchedule(): Array<dayjs.Dayjs> {
    const diffWithNextDay = dayjs()
      .add(1, 'day')
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .diff(dayjs(), 'minute');
    const isTodayExcluded = diffWithNextDay < MIN_DIFF_WITH_NEXT_DAY_MINUTES;

    return Array(DAYS_IN_WEEK + (isTodayExcluded ? 1 : 0))
      .fill(0)
      .reduce((acc: Array<dayjs.Dayjs>, _: number, index: number): Array<dayjs.Dayjs> => {
        if (index === 0 && isTodayExcluded) {
          return acc;
        } else {
          acc.push(dayjs().add(index, 'day'));
        }

        return acc;
      }, []);
  }

  /**
   * Возвращает доступные временные слоты для выбранного дня
   * Для сегодняшнего дня минимальный час - текущий +1
   */
  static getTimeSlotsSchedule(day: dayjs.Dayjs): Array<TimeSlotModel> {
    const minHour = day.isToday() ? day.add(1, 'hour').hour() : MIN_HOUR;
    if (!minHour) {
      // Добавив +1 к текущему часу получили следующий день
      return [];
    }

    const schedule: Array<TimeSlotModel> = [];

    for (let currentHour = minHour; currentHour <= MAX_HOUR; currentHour++) {
      HOUR_MINUTES_INTERVALS.forEach((minute: number): void => {
        schedule.push(new TimeSlotModel({ hour: currentHour, minute: minute }));
      });
    }

    return schedule;
  }
}
