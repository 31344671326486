import { LoadingScreen } from 'common/components/ui/loading-screen';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { DashboardOfSingleMeeting } from 'modules/meeting/views/single/components/dashboard/dashboard-of-single-meeting';
import { MeetingSingleComposition } from 'modules/meeting/views/single/compositions/meeting-single.composition';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useEffect, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router';

export function MeetingSingle(): JSX.Element {
  const {
    params: { id }
  } = useRouteMatch<{ id: string }>();

  const [composition, setComposition] = useSafeState<NotRequired<MeetingSingleComposition>>(void 0);

  useLayoutEffect((): VoidFunction => {
    setComposition(void 0);

    MeetingSingleComposition.make(Number(id)).then(setComposition).catch(propagateError);

    return (): void => propagateError.clear();
  }, [id, setComposition]);

  useEffect((): VoidFunction => {
    return (): void => composition?.destroy();
  }, [composition]);

  if (!composition) {
    return <LoadingScreen onCloseRequest={coreModalRouting.goToRoot} />;
  }

  return (
    <MeetingSingleCompositionContext.Provider value={composition}>
      <DashboardOfSingleMeeting />
    </MeetingSingleCompositionContext.Provider>
  );
}
