import { useMobile } from '@t15-ui-kit/media';
import { Sheet } from '@t15-ui-kit/sheet';
import { DropDownMenu, DropDownMenuContent } from 'common/components/ui/drop-down-menu';
import { TSettingsOptionProps } from 'common/components/ui/settings/settings-option';
import * as styles from 'common/components/ui/settings/settings.styles';
import { ReactElement } from 'react';

type TSettingsProps = {
  children: ReactElement<TSettingsOptionProps> | Array<ReactElement<TSettingsOptionProps>>;
  isOpen: boolean;
  header?: string;
  className?: string;
  onCloseRequest(): void;
};

/**
 * Компонент списка настроек для пользователя
 * Для мобильной версии будет отображен внутри Sheet, для десктоп через выпадающее меню
 */
export function Settings({ children, isOpen, header, className, onCloseRequest }: TSettingsProps): JSX.Element {
  const isMobile = useMobile();

  return isMobile ? (
    <Sheet isOpen={isOpen} onClose={onCloseRequest}>
      <DropDownMenuContent className={styles.mobileContent} header={header}>
        {children}
      </DropDownMenuContent>
    </Sheet>
  ) : (
    <DropDownMenu className={className} header={header} isOpen={isOpen} onCloseRequest={onCloseRequest}>
      {children}
    </DropDownMenu>
  );
}
