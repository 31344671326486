import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { SpecialListCompositionContext } from 'modules/special/views/list/contexts/special-list-composition.context';
import { useContext } from 'react';

export function EmptyListOfSpecialMeetingsSection(): JSX.Element {
  const {
    store: { filters$ }
  } = useContext(SpecialListCompositionContext);

  const filters = useRxBind(filters$);
  const isFiltered = !!filters.query || !!filters.groupIds?.length || !!filters.statuses?.length;

  if (!isFiltered) {
    return <></>;
  }

  return (
    <div>
      <CellEmpty height="40" />
      <EmptyView text="Встречи не найдены. Попробуйте изменить параметры фильтров" />
    </div>
  );
}
