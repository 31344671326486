import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import {
  EditMeetingRequestModel,
  GetMeetingListRequestModel,
  GetMeetingListResponseModel
} from 'common/models/rpc/lime';
import { DefiniteDispatcherService } from 'common/services/dispatcher';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingListStore } from 'modules/meeting/views/list/stores/meeting-list.store';

const DEFAULT_LIMIT = 10;

export enum MEETING_LIST_EVENT_TYPE {
  FORCE_LOAD = 'force_load'
}

export class MeetingListComposition implements IDestroyable {
  static readonly dispatcher = DefiniteDispatcherService.make<
    MEETING_LIST_EVENT_TYPE,
    {
      [MEETING_LIST_EVENT_TYPE.FORCE_LOAD]: MEETING_LIST_TYPE;
    }
  >(Object.values(MEETING_LIST_EVENT_TYPE));

  isAllLoaded: boolean = false;
  #isLoading?: boolean;
  #continueToken?: number;
  #lastQuery?: string;

  readonly store: MeetingListStore;
  readonly #unlisten: VoidFunction;

  constructor(readonly type: MEETING_LIST_TYPE) {
    this.store = new MeetingListStore();
    this.#unlisten = MeetingListComposition.dispatcher.listenForceLoad(this.#handleForceLoad);
  }

  loadList = (): void => {
    if (this.isAllLoaded || this.#isLoading) {
      return;
    }

    this.#isLoading = true;

    limeTransport
      .getMeetingList(
        new GetMeetingListRequestModel({
          type: this.type,
          limit: DEFAULT_LIMIT,
          continueToken: this.#continueToken,
          search: this.store.query
        })
      )
      .then(({ list }: GetMeetingListResponseModel): void => {
        const isOverrideList = this.store.query !== this.#lastQuery || !this.#continueToken;

        this.isAllLoaded = list.length < DEFAULT_LIMIT;
        this.#continueToken = (this.store.list?.length ?? 0) + list.length;

        this.store.isFiltered = !!this.store.query;
        this.store.list = isOverrideList ? list : [...(this.store.list ?? []), ...list];

        this.#lastQuery = this.store.query;
      })
      .catch(propagateError)
      .finally((): void => {
        this.#isLoading = false;
      });
  };

  forceLoadList = (): void => {
    this.isAllLoaded = false;
    this.#continueToken = void 0;

    this.loadList();
  };

  takeToWork = (id: number): Promise<void> => {
    return limeTransport.editMeeting(new EditMeetingRequestModel({ id, isAssigned: true }));
  };

  destroy(): void {
    propagateError.clear();

    this.store.destroy();

    this.#unlisten();
  }

  #handleForceLoad = (type: MEETING_LIST_TYPE): void => {
    if (type === this.type) {
      this.forceLoadList();
    }
  };
}
