import { useEffect, useState } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Хук который позволяет в компоненте использовать значение из потока
 */
export function useRxBind<T>(stream: BehaviorSubject<T>): T;
export function useRxBind<T>(stream: Observable<T>): T | void;
export function useRxBind<T>(stream: Observable<T>, defaultValue: T): T;
export function useRxBind<T>(stream: Observable<T>, defaultValue?: T): T | void {
  const [value, setValue] = useState<T | void>((): T | void => {
    if (stream instanceof BehaviorSubject) {
      return stream.getValue() as T;
    }
    if (defaultValue !== void 0) {
      return defaultValue;
    }

    return void 0;
  });
  useEffect((): VoidFunction => {
    const subscription = stream.subscribe(setValue);

    return (): void => subscription.unsubscribe();
  }, [stream]);

  return value;
}
