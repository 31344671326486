import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const actions = (isMobile: boolean): string =>
  css({
    label: 'action-panel__actions',
    display: 'flex',
    alignItems: 'center',
    columnGap: isMobile ? '15px' : '10px'
  });

export const action = (isMobile: boolean): string =>
  css({
    label: 'action-panel__action',
    padding: isMobile ? '12px 10px 12px 0' : '9px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '10px',
    cursor: 'pointer',
    border: isMobile ? 'none' : `1px solid transparent`,
    borderRadius: isMobile ? 0 : '6px',
    color: Colors.Brand,
    transition: 'color .2s ease-in-out, border-color .2s ease-in-out',

    [':hover']: {
      borderColor: isMobile ? 'transparent' : Colors.Grey300
    }
  });
