import { DChip } from '@t15-ui-kit/dChip';
import { Grid } from '@t15-ui-kit/grid/Grid';
import { SimpleRow } from 'common/components/ui/simple-row';
import { SpinnerAtCenter } from 'common/components/ui/spinner-at-center';
import { FORMAT_DATE_USE_DAY_TYPE, formatDate } from 'common/utils/formatters/format-date';
import dayjs from 'dayjs';

type TMeetingDateSelectRowProps = {
  date: NotRequired<dayjs.Dayjs>;
  dateSlots: NotRequired<Array<dayjs.Dayjs>>;
  onSelect(nextDate: dayjs.Dayjs): void;
};

export function MeetingDateSelectRow({ date, dateSlots, onSelect }: TMeetingDateSelectRowProps): JSX.Element {
  const formattedDate = date ? formatDate(date, FORMAT_DATE_USE_DAY_TYPE.ONLY) : '';

  return (
    <SimpleRow title="Когда">
      {dateSlots ? (
        <Grid container elementOffset={['xs', 'xs']}>
          {dateSlots.map((iterableDate: dayjs.Dayjs): JSX.Element => {
            const formattedIterableDate = formatDate(iterableDate, FORMAT_DATE_USE_DAY_TYPE.ONLY);

            return (
              <Grid key={formattedIterableDate}>
                <DChip
                  text={formattedIterableDate}
                  isSelected={formattedIterableDate === formattedDate}
                  onClick={() => onSelect(iterableDate)}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <SpinnerAtCenter />
      )}
    </SimpleRow>
  );
}
