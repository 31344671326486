import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  label: 'autocomplete',
  position: 'relative'
});

export const input = css({
  padding: 'inherit',
  margin: 'inherit'
});

export const spinner = css({
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  zIndex: 999999
});

export const options = css({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  width: '100%',
  marginTop: '10px',
  boxShadow: '0 8px 25px -5px rgba(0, 0, 0, .25)',
  backgroundColor: Colors.Default,
  zIndex: 999999
});

export const option = (isActive: boolean, isDisabled: boolean): string =>
  css({
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '5px 15px',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: isActive ? Colors.Brand : Colors.Default,
    color: isActive ? Colors.Default : Colors.Primary,
    cursor: isDisabled ? 'not-allowed' : 'pointer',

    ['&:not(:last-child)']: {
      borderBottom: `1px solid ${Colors.Grey200}`
    },

    ['&:hover']: {
      backgroundColor: Colors.Brand,
      color: Colors.Default
    }
  });
