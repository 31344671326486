import { Avatar } from '@t15-ui-kit/avatar';
import { ColorsBasicEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Image } from '@tochka-modules/t15-ui-kit-icons/24/Extensions/Image';
import { Cross } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Cross';
import { Redo } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Redo';
import { CircleProgress } from 'common/components/ui/circle-progress';
import * as styles from 'common/components/ui/single-file-loader/single-file-loader.styles';

type TSingleFileLoaderFileCardContentProps = {
  isUploading?: boolean;
  isError?: boolean;
  progress?: number;
  preview?: string;
  onCancelRequest?(): void;
  onRetryRequest?(): void;
};

export function SingleFileLoaderFileCardContent({
  isUploading,
  isError,
  progress,
  preview,
  onCancelRequest,
  onRetryRequest
}: TSingleFileLoaderFileCardContentProps): JSX.Element {
  const isMobile = useMobile();

  const buttonAction = isUploading ? onCancelRequest : isError ? onRetryRequest : void 0;

  if (isMobile) {
    return (
      <>
        {preview ? (
          <Avatar size="m" bgr={ColorsBasicEnum.Default} src={preview} srcsize="cover" sq />
        ) : (
          <div className={styles.fileCardContentIcon}>
            <Avatar size="m" bgr={ColorsShadesEnum.Grey100} sq>
              <Image />
            </Avatar>
          </div>
        )}

        {!!buttonAction && (
          <button className={styles.fileCardContentButton} onClick={buttonAction} type="button">
            <Avatar size="m" bgr={!preview ? ColorsBasicEnum.Default : void 0} sq>
              {!!preview && <div className={styles.fileCardContentMobileShade} />}

              {isUploading ? (
                <>
                  <CircleProgress
                    value={progress ?? 0}
                    color={!preview ? ColorsBasicEnum.Brand : ColorsBasicEnum.Default}
                  />
                  <Cross size="s" color={!preview ? ColorsBasicEnum.Brand : ColorsBasicEnum.Default} />
                </>
              ) : (
                <Redo size="l" color={!preview ? ColorsBasicEnum.Brand : ColorsBasicEnum.Default} />
              )}
            </Avatar>
          </button>
        )}
      </>
    );
  } else {
    return (
      <>
        {!!buttonAction && (
          <button className={styles.fileCardContentButton} onClick={buttonAction} type="button">
            <Avatar size="m" bgr={ColorsBasicEnum.Default} sq>
              {isUploading ? (
                <>
                  <CircleProgress value={progress ?? 0} color={ColorsBasicEnum.Brand} />
                  <Cross size="m" color={ColorsBasicEnum.Brand} />
                </>
              ) : (
                <Avatar size="m" bgr={ColorsBasicEnum.Default} sq>
                  <Redo size="l" color={ColorsBasicEnum.Brand} />
                </Avatar>
              )}
            </Avatar>
          </button>
        )}

        {preview ? (
          <img className={styles.fileCardImage} src={preview} />
        ) : (
          <div className={styles.fileCardContentIcon}>
            <Avatar size="m" bgr={ColorsBasicEnum.Default} sq>
              <Image />
            </Avatar>
          </div>
        )}
      </>
    );
  }
}
