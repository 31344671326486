import { MutableRefObject, Ref, RefObject, useEffect, useRef } from 'react';

export function useCombinedRefs<T>(ref?: Ref<T>): RefObject<T> {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }

    if (typeof ref === 'object') {
      (ref as MutableRefObject<T | null>).current = targetRef.current;
    } else if (typeof ref === 'function') {
      ref(targetRef.current);
    }
  }, [ref]);

  return targetRef;
}
