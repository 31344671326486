import { LoadingScreen } from 'common/components/ui/loading-screen';
import { UserGuard } from 'common/components/ui/user-guard';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { UserRight } from 'core/enums/user-right.enum';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { DashboardOfSingleSpecialMeetingSection } from 'modules/special/views/single/components/sections/dashboard-of-single-special-meeting-section';
import { SpecialSingleComposition } from 'modules/special/views/single/compositions/special-single.composition';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useEffect, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router';

function SpecialSingleInternal(): JSX.Element {
  const {
    params: { id }
  } = useRouteMatch<{ id: string }>();

  const [composition, setComposition] = useSafeState<NotRequired<SpecialSingleComposition>>(void 0);

  useLayoutEffect((): VoidFunction => {
    setComposition(void 0);

    SpecialSingleComposition.make(Number(id)).then(setComposition).catch(propagateError);

    return (): void => propagateError.clear();
  }, [id, setComposition]);

  useEffect((): VoidFunction => {
    return (): void => composition?.destroy();
  }, [composition]);

  if (!composition) {
    return <LoadingScreen onCloseRequest={coreModalRouting.goToRoot} />;
  }

  return (
    <SpecialSingleCompositionContext.Provider value={composition}>
      <DashboardOfSingleSpecialMeetingSection />
    </SpecialSingleCompositionContext.Provider>
  );
}

export function SpecialSingle(): JSX.Element {
  return (
    <UserGuard right={UserRight.SPECIAL}>
      <SpecialSingleInternal />
    </UserGuard>
  );
}
