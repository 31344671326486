import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { Link } from '@t15-ui-kit/link';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { CORE_ROOT_ROUTING_PATH } from 'core/enums/core-root-routing.enum';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { MEETING_LIST_TYPE_EMPTY_LIST_DICTIONARY } from 'modules/meeting/views/list/dictionary/meeting-list-type-empty-list.dictionary';
import { useCallback, useContext, useMemo, MouseEvent, SyntheticEvent } from 'react';
import { generatePath } from 'react-router';

export function EmptyListOfMeetings(): JSX.Element {
  const {
    type,
    store: { isFiltered$ }
  } = useContext(MeetingListCompositionContext);

  const isMobile = useMobile();

  const availableUrl = useMemo((): string => generatePath(CORE_ROOT_ROUTING_PATH.MEETINGS, { type: 'available' }), []);
  const handleClickAvailable = useCallback((event?: MouseEvent & SyntheticEvent): void => {
    event?.preventDefault();

    coreRootRouting.goToMeetings({ type: 'available' });
  }, []);

  const isFiltered = useRxBind(isFiltered$);

  if (isFiltered) {
    return <></>;
  }

  return (
    <div>
      <CellEmpty height="40" />
      <EmptyView text={MEETING_LIST_TYPE_EMPTY_LIST_DICTIONARY[type]} />

      {type === MEETING_LIST_TYPE.MY && (
        <>
          {isMobile ? (
            <>
              <CellEmpty height="10" />
              <CellEmpty height="15" />
            </>
          ) : (
            <>
              <CellEmpty height="20" />
              <CellEmpty height="5" />
            </>
          )}

          <Typography align="center">
            <Link href={availableUrl} onClick={handleClickAvailable}>
              Выбрать из&nbsp;свободных
            </Link>
          </Typography>
        </>
      )}
    </div>
  );
}
