import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { EditMeetingRequestModel, MeetingDataModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingAssignToCourierStore } from 'modules/meeting/views/single/stores/meeting-assign-to-courier.store';

export class MeetingAssignToCourierService implements IRevertable, IDestroyable {
  readonly store: MeetingAssignToCourierStore;

  readonly #data: MeetingDataModel;

  constructor(data: MeetingDataModel) {
    this.#data = data;

    this.store = new MeetingAssignToCourierStore();
  }

  assignToCourier = (): Promise<void> => {
    const comment = this.store.comment;
    if (!comment) {
      return Promise.reject(new Error('Укажите комментарий'));
    }

    return limeTransport.editMeeting(
      new EditMeetingRequestModel({
        id: this.#data.id,
        comment,
        isCanceled: true
      })
    );
  };

  revert(): void {
    this.store.revert();
  }

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
  }
}
