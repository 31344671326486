import { useSafeDispatch } from 'common/utils/hooks/use-safe-dispatch';
import { useCallback, useState } from 'react';

export function useSafeState<S>(initialState?: S): [S, (nextState: S) => void] {
  const [state, setState] = useState(initialState);

  const safetyDispatch = useSafeDispatch();
  const safetySetState = useCallback(
    (nextState: S): void => safetyDispatch((): void => setState(nextState)),
    [safetyDispatch]
  );

  return [state as S, safetySetState];
}
