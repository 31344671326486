import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { EditSpecialMeetingRequestModel, MeetingDataModel, UserModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { SpecialAssignToSellerStore } from 'modules/special/views/single/stores/special-assign-to-seller.store';

export class SpecialAssignToSellerService implements IRevertable, IDestroyable {
  readonly store: SpecialAssignToSellerStore;

  readonly #data: MeetingDataModel;

  constructor(data: MeetingDataModel) {
    this.#data = data;

    this.store = new SpecialAssignToSellerStore(data);
  }

  assignToSeller = (): Promise<void> => {
    return limeTransport.editSpecialMeeting(
      new EditSpecialMeetingRequestModel({
        id: this.#data.id,
        sellerId: this.store.sellerId
      })
    );
  };

  getAvailableSellers = (): Promise<Array<UserModel>> => {
    return limeTransport.getSellerListByGroup(this.#data.group.id);
  };

  revert(): void {
    this.store.revert();
  }

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
  }
}
