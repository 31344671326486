import { IRoutingService } from 'common/services/routing/routing.interface';
import { stripSlashes } from 'common/utils/features/strip-slashes';
import { createBrowserHistory, History, Location } from 'history';

export class ApplicationRoutingService<S extends object> {
  static #modalDelimiter: string = '/m/';

  /** Флаг, что в данный момент декоративно меняем адресную строку */
  #isApplyingStateToBrowser: boolean = false;

  /** Флаг, что в данный момент история браузера поменялась по воле браузера и мы меняем историю */
  #isApplyingBrowserToState: boolean = false;

  get #currentState(): string {
    if (this.#currentRootState && this.#currentModalState) {
      return [this.#currentRootState ? `/${this.#currentRootState}` : '', this.#currentModalState || ''].join(
        ApplicationRoutingService.#modalDelimiter
      );
    }

    if (this.#currentRootState && !this.#currentModalState) {
      return `/${this.#currentRootState}`;
    }

    if (this.#currentModalState && !this.#currentRootState) {
      return `${ApplicationRoutingService.#modalDelimiter}${this.#currentModalState}`;
    }

    return '/';
  }

  #currentRootState: string = '';
  #currentModalState: string = '';

  readonly #history: History<S>;

  readonly #rootRouting: IRoutingService<S>;
  readonly #modalRouting: IRoutingService<S>;

  constructor(rootRouting: IRoutingService<S>, modalRouting: IRoutingService<S>, basename: string = '/') {
    this.#history = createBrowserHistory({
      basename
    });

    this.#rootRouting = rootRouting;
    this.#modalRouting = modalRouting;
  }

  init(): void {
    this.#history.listen(this.#handleChangeHistory);
    this.#rootRouting.history.listen(this.#handleChangeRootHistory);
    this.#modalRouting.history.listen(this.#handleChangeModalHistory);

    this.#handleChangeHistory(this.#history.location);
    this.#currentRootState = stripSlashes(this.#rootRouting.history.location.pathname);
    this.#currentModalState = stripSlashes(this.#modalRouting.history.location.pathname);
  }

  #applyStateToBrowserPathname(state?: S): void {
    this.#isApplyingStateToBrowser = true;
    this.#history.push(this.#currentState, state);
    this.#isApplyingStateToBrowser = false;
  }

  #handleChangeHistory = (location: Location<S>): void => {
    if (this.#isApplyingStateToBrowser) {
      return;
    }

    this.#isApplyingBrowserToState = true;

    const [rootPath, modalPath] = location.pathname.split(ApplicationRoutingService.#modalDelimiter).map(stripSlashes);
    this.#rootRouting.goTo(rootPath ? `/${rootPath}${location.search}` : `/${location.search}`, {}, location.state);
    this.#modalRouting.goTo(modalPath ? `/${modalPath}${location.search}` : `/${location.search}`, {}, location.state);

    this.#isApplyingBrowserToState = false;
  };

  #handleChangeRootHistory = (location: Location<S>): void => {
    if (this.#isApplyingBrowserToState) {
      return;
    }

    // Сменился путь страницы. Закрыть модалку и сменить путь в браузере
    // Это может произойти при навигации по роутам с помощью link
    this.#currentRootState = `${stripSlashes(location.pathname)}${location.search}`;
    this.#currentModalState = '';

    this.#isApplyingBrowserToState = true;
    this.#modalRouting.history.push('/');
    this.#isApplyingBrowserToState = false;

    this.#applyStateToBrowserPathname(location.state);
  };

  #handleChangeModalHistory = (location: Location<S>): void => {
    if (this.#isApplyingBrowserToState) {
      return;
    }

    this.#currentModalState = `${stripSlashes(location.pathname)}${location.search}`;
    this.#applyStateToBrowserPathname(location.state);
  };
}
