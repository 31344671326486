import { ColorsBasicEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { Avatar } from '@t15-ui-kit/icon';
import { AllColors } from '@t15-ui-kit/types';
import { Plus } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Plus';
import { LikeNavigator, LikeNavigatorButton, LikeNavigatorRow } from 'common/components/ui/like-navigator';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { MeetingCommentModel } from 'common/models/rpc/lime';
import { formatDate } from 'common/utils/formatters/format-date';
import { formatTime } from 'common/utils/formatters/format-time';

type TCommentsOfMeetingProps = {
  status: MEETING_STATUS;
  comments: Array<MeetingCommentModel>;
  onAddComment(): void;
  onShowAll(): void;
};

const NAVIGATOR_MAX_ROWS = 3;

export function CommentsOfMeeting({ status, comments, onAddComment, onShowAll }: TCommentsOfMeetingProps): JSX.Element {
  return (
    <LikeNavigator title="Комментарии" maxRows={NAVIGATOR_MAX_ROWS} onShowAllRequest={onShowAll}>
      {[
        status === MEETING_STATUS.DRAFT && (
          <LikeNavigatorButton
            key="add"
            accLeft={
              <Avatar size="m" bgr={ColorsBasicEnum.Default} border={ColorsShadesEnum.Grey200}>
                <Plus size="m" color={'inherit' as AllColors} />
              </Avatar>
            }
            onClick={onAddComment}
          >
            Добавить комментарий
          </LikeNavigatorButton>
        ),
        ...comments.map(
          (comment: MeetingCommentModel, index: number): JSX.Element => (
            <LikeNavigatorRow
              key={index}
              title={
                comment.createdAt
                  ? `${formatDate(comment.createdAt)} в ${formatTime(comment.createdAt)}`
                  : 'Время неизвестно'
              }
            >
              {comment.value}
            </LikeNavigatorRow>
          )
        )
      ]}
    </LikeNavigator>
  );
}
