import { Typography } from '@t15-ui-kit/typography';
import { DDialog } from '@t15-ui-kit/dDialog';
import { LayoutFull, Footer } from '@t15-ui-kit/layoutFull';
import { Button } from '@t15-ui-kit/button';
import { useState, useEffect, useCallback } from 'react';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { useParams } from 'react-router';
import { Grid } from '@t15-ui-kit/grid';
import { limeTransport } from 'common/transport/lime';
import { TCityModel, UserModel } from 'common/models/rpc/lime';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { ContractorSkeleton } from '@t15-ui-kit/contractorSkeleton';
import { City } from 'modules/city/views/list/components';
import { AddCityPopup } from './add-city-popup';

const SKELETON_CHILD_COUNT = 20;

export const Seller = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<NotRequired<UserModel>>(undefined);
  const [cityList, setCityList] = useState<Array<TCityModel>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUserInfo = useCallback(async () => {
    setLoading(true);
    const [seller, cities] = await Promise.all([
      limeTransport.getSeller(Number(id)),
      limeTransport.getSellerCityList(Number(id))
    ]);
    setUser(seller);
    setCityList(cities);
    setLoading(false);
  }, [id]);

  const openAddCityPopup = useCallback(() => {
    uiKitService.openPopup(<AddCityPopup sellerId={Number(id)} reloadData={getUserInfo} sellerCityList={cityList} />);
  }, [cityList, id, getUserInfo]);

  useEffect(() => {
    void getUserInfo();
  }, [getUserInfo]);

  return (
    <DDialog isOpen={true}>
      <LayoutFull
        isLoading={loading}
        headerNode={
          <Typography size="m" weight="500" align="center">
            {user?.name}
          </Typography>
        }
        backAction={coreRootRouting.goToSellers}
        closeAction={coreRootRouting.goToSellers}
        footerNode={
          <Footer>
            <Button onClick={openAddCityPopup}>Добавить город</Button>
          </Footer>
        }
      >
        {cityList ? (
          cityList.length ? (
            cityList.map((city: TCityModel) => (
              <City key={city.id} city={city} sellerId={Number(id)} reloadData={getUserInfo} />
            ))
          ) : (
            <EmptyView text="Нету городов" />
          )
        ) : (
          <Grid key="skeleton">
            <ContractorSkeleton childCount={SKELETON_CHILD_COUNT} />
          </Grid>
        )}
      </LayoutFull>
    </DDialog>
  );
};
