import { LimeError } from 'common/errors/rpc/lime/lime.error';
import {
  DeleteMeetingPhotoRequestModel,
  EditMeetingRequestModel,
  EditSpecialMeetingRequestModel,
  GetMeetingListRequestModel,
  GetMeetingListResponseModel,
  GetMeetingRequestModel,
  GetMeetingResponseModel,
  GetSellerListResponseModel,
  GetSpecialMeetingListRequestModel,
  GetUserListResponseModel,
  GetUserResponseModel,
  GroupInfoModel,
  MeetingDataModel,
  TAddCityRequest,
  TAddSellerRequest,
  TCityModel,
  TEditCityRequest,
  TEditSellerRequest,
  TGetSellerListRequestModel,
  TGetUserListRequestModel,
  TSellerCityLinkRequest,
  UploadMeetingPhotoRequestModel,
  UploadMeetingPhotoResponseModel,
  UserModel
} from 'common/models/rpc/lime';
import { httpRequestService } from 'common/services/http-request';
import { LIME_API_URL } from 'common/transport/lime/lime.const';

class LimeTransport {
  getUser(): Promise<GetUserResponseModel> {
    return httpRequestService
      .get(`${LIME_API_URL}/user`)
      .then(GetUserResponseModel.fromServer)
      .catch(this.#handleError);
  }

  authCheck(): Promise<void> {
    return httpRequestService.get<void>(`${LIME_API_URL}/auth/check`).catch(this.#handleError);
  }

  logout(): Promise<void> {
    return httpRequestService.post<void>(`${LIME_API_URL}/logout`).catch(this.#handleError);
  }

  getMeetingList(request: GetMeetingListRequestModel): Promise<GetMeetingListResponseModel> {
    return httpRequestService
      .get(`${LIME_API_URL}/web/meetings?${request.toQueryString()}`)
      .then(GetMeetingListResponseModel.fromServer)
      .catch(this.#handleError);
  }

  getSpecialMeetingList(request: GetSpecialMeetingListRequestModel): Promise<Array<MeetingDataModel>> {
    return httpRequestService
      .get<Array<object>>(`${LIME_API_URL}/web/special/meetings?${request.toQueryString()}`)
      .then((meetings: Array<object>) => meetings.map(MeetingDataModel.fromServer))
      .catch(this.#handleError);
  }

  getMeeting(request: GetMeetingRequestModel): Promise<GetMeetingResponseModel> {
    return httpRequestService
      .get(`${LIME_API_URL}/web/meetings/${request.id}`)
      .then(GetMeetingResponseModel.fromServer)
      .catch(this.#handleError);
  }

  getSpecialMeeting(id: number): Promise<MeetingDataModel> {
    return httpRequestService
      .get(`${LIME_API_URL}/web/special/meetings/${id}`)
      .then(MeetingDataModel.fromServer)
      .catch(this.#handleError);
  }

  getDownloadsLink(id: number): string {
    return `${LIME_API_URL}/download/archive?meetingId=${id}`;
  }

  getGroupsList(): Promise<Array<GroupInfoModel>> {
    return httpRequestService
      .get<Array<object>>(`${LIME_API_URL}/web/special/groups`)
      .then((groups: Array<object>) => groups.map(GroupInfoModel.fromServer))
      .catch(this.#handleError);
  }

  getGroup(id: number): Promise<GroupInfoModel> {
    return httpRequestService
      .get(`${LIME_API_URL}/web/special/groups/${id}`)
      .then(GroupInfoModel.fromServer)
      .catch(this.#handleError);
  }

  editMeeting(request: EditMeetingRequestModel): Promise<void> {
    return httpRequestService
      .put<void>(`${LIME_API_URL}/web/meetings/${request.id}`, request.toServer())
      .catch(this.#handleError);
  }

  editSpecialMeeting(request: EditSpecialMeetingRequestModel): Promise<void> {
    return httpRequestService
      .put<void>(`${LIME_API_URL}/web/special/meetings/${request.id}`, request.toServer())
      .catch(this.#handleError);
  }

  uploadMeetingPhoto(
    request: UploadMeetingPhotoRequestModel,
    signal?: AbortSignal,
    onProgress?: (progress: number) => void
  ): Promise<UploadMeetingPhotoResponseModel> {
    return httpRequestService
      .upload(`${LIME_API_URL}/web/meetings/${request.id}/photos`, request.toFormData(), signal, onProgress)
      .then(UploadMeetingPhotoResponseModel.fromServer)
      .catch(this.#handleError);
  }

  deleteMeetingPhoto(request: DeleteMeetingPhotoRequestModel): Promise<void> {
    return httpRequestService
      .delete<void>(`${LIME_API_URL}/web/meetings/${request.id}/photos/${request.photoId}`)
      .catch(this.#handleError);
  }

  getSellerListByGroup(groupId: number): Promise<Array<UserModel>> {
    return httpRequestService
      .get<Array<object>>(`${LIME_API_URL}/web/special/sellers?groupId=${groupId}`)
      .then((sellers: Array<object>) => sellers.map(UserModel.fromServer))
      .catch(this.#handleError);
  }

  getSellerList(request: TGetSellerListRequestModel): Promise<GetSellerListResponseModel> {
    const params = new URLSearchParams(request as unknown as Record<string, string>).toString();
    return httpRequestService
      .get(`${LIME_API_URL}/user/search?${params}`)
      .then(GetSellerListResponseModel.fromServer)
      .catch(this.#handleError);
  }

  getSeller(id: number): Promise<UserModel> {
    return httpRequestService.get(`${LIME_API_URL}/user/${id}`).then(UserModel.fromServer).catch(this.#handleError);
  }

  getUserList(request: TGetUserListRequestModel): Promise<GetUserListResponseModel> {
    const params = new URLSearchParams(request as unknown as Record<string, string>).toString();

    return httpRequestService
      .get<GetUserListResponseModel>(`${LIME_API_URL}/user/external/search?${params}`)
      .then(GetUserListResponseModel.fromServer)
      .catch(this.#handleError);
  }

  getSellerCityList(id: number): Promise<Array<TCityModel>> {
    return httpRequestService
      .get<Array<TCityModel>>(`${LIME_API_URL}/web/links/cities?seller_id=${id}`)
      .then((cities: Array<TCityModel>) => cities)
      .catch(this.#handleError);
  }

  addSeller(request: TAddSellerRequest): Promise<void> {
    return httpRequestService.post<void>(`${LIME_API_URL}/user`, request).catch(this.#handleError);
  }

  editSeller(id: number, request: TEditSellerRequest): Promise<void> {
    return httpRequestService.put<void>(`${LIME_API_URL}/user/${id}`, request).catch(this.#handleError);
  }

  addSellerCityLink(request: TSellerCityLinkRequest): Promise<void> {
    return httpRequestService.post<void>(`${LIME_API_URL}/web/links`, request).catch(this.#handleError);
  }

  deleteSellerCityLink(request: TSellerCityLinkRequest): Promise<void> {
    return httpRequestService.delete<void>(`${LIME_API_URL}/web/links`, request).catch(this.#handleError);
  }

  getCityList(): Promise<Array<TCityModel>> {
    return httpRequestService.get<Array<TCityModel>>(`${LIME_API_URL}/web/cities/all`).catch(this.#handleError);
  }

  getCity(id: number): Promise<TCityModel> {
    return httpRequestService.get<TCityModel>(`${LIME_API_URL}/web/cities/${id}`).catch(this.#handleError);
  }

  addCity(request: TAddCityRequest): Promise<void> {
    return httpRequestService.post<void>(`${LIME_API_URL}/web/cities`, request).catch(this.#handleError);
  }

  editCity(request: TEditCityRequest): Promise<void> {
    const { id, ...data } = request;
    return httpRequestService.put<void>(`${LIME_API_URL}/web/cities/${id}`, data).catch(this.#handleError);
  }

  #handleError = (error: Error): Promise<never> => {
    return Promise.reject(new LimeError(error));
  };
}

export const limeTransport = new LimeTransport();
