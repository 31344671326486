import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid/Grid';
import { useMobile } from '@t15-ui-kit/media';
import { AllColors } from '@t15-ui-kit/types';
import { Typography } from '@t15-ui-kit/typography';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { formatTimeSlot } from 'common/utils/formatters/format-time-slot';

type TMeetingCardAddressAndTimeProps = {
  data: MeetingDataModel;
  isExpired: boolean;
};

export function MeetingCardAddressAndTime({ data, isExpired }: TMeetingCardAddressAndTimeProps): JSX.Element {
  const isMobile = useMobile();

  let timeColor: AllColors = ColorsBasicEnum.Primary;
  if (isExpired) {
    timeColor = ColorsBasicEnum.Alert;
  }

  return (
    <Grid
      container
      direction={isMobile ? 'column-reverse' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justify={isMobile ? 'flex-start' : 'space-between'}
      elementOffset={isMobile ? ['2xs', 'none'] : void 0}
    >
      <Grid>
        <Typography type="h3" size="l" weight="500" wordBreak="break-word">
          {data.address?.address ?? 'Адрес не указан'}
        </Typography>
      </Grid>
      <Grid>
        <Typography type="span" color={timeColor} size="l" weight="500">
          {data.time ? formatTimeSlot(data.time) : 'Время не\xA0указано'}
        </Typography>
      </Grid>
    </Grid>
  );
}
