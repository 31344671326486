import { CellCard } from '@t15-ui-kit/cellCard';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { useMobile } from '@t15-ui-kit/media';
import { TActionPanelActionProps } from 'common/components/ui/action-panel/action-panel.action';
import * as styles from 'common/components/ui/action-panel/action-panel.styles';
import { ReactElement } from 'react';

type TActionPanelProps = {
  children: ReactElement<TActionPanelActionProps> | Array<ReactElement<TActionPanelActionProps>>;
};

export function ActionPanel({ children }: TActionPanelProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <CellCard shadow="Card">
      <CellOffset offset={[isMobile ? 'xs' : 'm', isMobile ? 'm' : 'xs']}>
        <div className={styles.actions(isMobile)}>{children}</div>
      </CellOffset>
    </CellCard>
  );
}
