export enum CityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type TCityModel = {
  id: number;
  name: string;
  code: string;
  timezone: string;
  meetingLimit: number;
  status: CityStatus;
};
