import { DDialog } from '@t15-ui-kit/dDialog';
import { Children, ReactElement, useMemo } from 'react';
import { matchPath, RouteProps, Switch, useLocation } from 'react-router';

type TRoutingInDialogProps = {
  children: ReactElement<RouteProps> | Array<ReactElement<RouteProps>>;
};

export function RoutingInDialog({ children }: TRoutingInDialogProps): JSX.Element {
  const location = useLocation();

  const paths = useMemo(
    (): Array<string> =>
      Children.map(
        children,
        (child: ReactElement<RouteProps>): Array<string> => (child.props.path ? [child.props.path].flat() : [])
      ).flat(),
    [children]
  );

  const isOpen = useMemo(
    (): boolean => paths.some((path: string): boolean => matchPath(location.pathname, path) !== null),
    [location.pathname, paths]
  );

  return (
    <DDialog isOpen={isOpen} disableEscapeKeyDown>
      <Switch>{children}</Switch>
    </DDialog>
  );
}
