import { MeetingDataModel } from 'common/models/rpc/lime/internal';

export class GetMeetingResponseModel {
  data!: MeetingDataModel;

  constructor(data?: GetMeetingResponseModel) {
    data && Object.assign(this, data);
  }

  static fromServer(data: object): GetMeetingResponseModel {
    const model = new GetMeetingResponseModel();
    model.data = MeetingDataModel.fromServer(data);

    return model;
  }
}
