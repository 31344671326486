import { Avatar } from '@t15-ui-kit/avatar';
import { ColorsBasicEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { Plus } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Plus';
import classNames from 'classnames';
import * as styles from 'common/components/ui/single-file-loader/single-file-loader.styles';
import { useMemo } from 'react';
import { DropzoneInputProps, DropzoneOptions, DropzoneRootProps, useDropzone } from 'react-dropzone';

export type TSingleFileLoaderUploadCardProps = {
  title: string;
  description?: string;
  accept?: string;
  onUploadRequest(file: File): void;
  onRejectRequest(file: File): void;
};

export function SingleFileLoaderUploadCard({
  title,
  description,
  accept,
  onUploadRequest,
  onRejectRequest
}: TSingleFileLoaderUploadCardProps): JSX.Element {
  const isMobile = useMobile();

  const options = useMemo(
    (): DropzoneOptions => ({
      onDrop: (acceptedFiles: Array<File>, rejectedFiles: Array<File>): void => {
        if (acceptedFiles.length) {
          onUploadRequest(acceptedFiles[0]);
        } else if (rejectedFiles.length) {
          onRejectRequest(rejectedFiles[0]);
        }
      },
      accept,
      multiple: false,
      preventDropOnDocument: true,
      noClick: true,
      noKeyboard: true
    }),
    [accept, onUploadRequest, onRejectRequest]
  );

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone(options);

  const rootProps = useMemo((): DropzoneRootProps => getRootProps(), [getRootProps]);

  const inputProps = useMemo((): DropzoneInputProps => getInputProps({ hidden: true }), [getInputProps]);

  return (
    <div {...rootProps}>
      <div
        className={classNames(styles.uploadCard(isMobile), { [styles.uploadCardActive]: isDragActive })}
        onClick={open}
      >
        <input {...inputProps} />
        <div>
          <Avatar size="m" border={ColorsShadesEnum.Grey300}>
            <Plus color={ColorsBasicEnum.Brand} />
          </Avatar>
        </div>
        <div className={styles.uploadCardText(isMobile)}>
          <Typography
            type="div"
            size="l"
            weight="500"
            align={isMobile ? 'left' : 'center'}
            color={ColorsBasicEnum.Brand}
          >
            {title}
          </Typography>
          <Typography type="div" size="s" weight={isMobile ? '500' : '400'} color={ColorsBasicEnum.Secondary}>
            {description || 'Не\xA0загружено'}
          </Typography>
        </div>
      </div>
    </div>
  );
}
