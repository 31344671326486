import { css } from 'emotion';

export const root = (isOpen: boolean): string =>
  css({
    label: 'drop-down-menu',
    position: 'absolute',
    right: 0,
    minWidth: '375px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1)',
    userSelect: 'none',
    transform: isOpen ? 'translateY(0)' : 'translateY(-10px)',
    transition: 'transform .2s ease-in-out, opacity .2s ease-in-out',
    opacity: isOpen ? 1 : 0,
    pointerEvents: isOpen ? 'auto' : 'none'
  });

export const content = css({
  label: 'drop-down-menu__content',
  padding: '20px 0'
});

export const header = css({
  label: 'drop-down-menu__header',
  padding: '13px 20px',
  boxShadow: 'inset 0 -0.5px 0 #e1e1e1'
});

export const option = css({
  label: 'drop-down-menu__option',
  padding: '15px 20px',
  cursor: 'pointer',

  [':hover']: {
    backgroundColor: '#f6f6f6'
  }
});
