import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { AddressModel } from 'common/models/rpc/lime/internal/address.model';
import { CustomerModel } from 'common/models/rpc/lime/internal/customer.model';
import { GroupInfoModel } from 'common/models/rpc/lime/internal/group-info.model';
import { MeetingCommentModel } from 'common/models/rpc/lime/internal/meeting-comment.model';
import { MeetingPhotoModel } from 'common/models/rpc/lime/internal/meeting-photo.model';
import { ProductInfoModel } from 'common/models/rpc/lime/internal/product-info.model';
import { TimeSlotModel } from 'common/models/rpc/lime/internal/time-slot.model';
import { UserModel } from 'common/models/rpc/lime/internal/user.model';
import { deserializeDate } from 'common/utils/serializers/deserialize-date';
import dayjs from 'dayjs';
import { deserialize, JsonArray, JsonMeta, JsonNameReadonly, JsonStruct } from 'tserialize';

export class MeetingDataModel {
  @JsonNameReadonly('meeting_id')
  id!: number;

  @JsonNameReadonly('status')
  status!: MEETING_STATUS;

  @JsonMeta(CustomerModel)
  customer!: CustomerModel;

  @JsonMeta(AddressModel)
  address!: AddressModel;

  @JsonStruct(UserModel, 'seller')
  seller?: UserModel;

  @JsonArray(MeetingCommentModel, 'comments')
  comments?: Array<MeetingCommentModel>;

  @JsonStruct(ProductInfoModel, 'product')
  product!: ProductInfoModel;

  @JsonStruct(GroupInfoModel, 'group')
  group!: GroupInfoModel;

  @JsonArray(MeetingPhotoModel, 'photos')
  photos?: Array<MeetingPhotoModel>;

  @JsonNameReadonly('date', deserializeDate)
  date?: dayjs.Dayjs;

  @JsonStruct(TimeSlotModel, 'start_at')
  time?: TimeSlotModel;

  @JsonNameReadonly('timezone')
  timezone?: string;

  // TODO: новое поле, пока не интегрируем с UI
  @JsonNameReadonly('partner_meeting')
  partnerMeeting?: boolean;

  @JsonNameReadonly('downloads')
  hasDownloads?: boolean;

  constructor(data?: MeetingDataModel) {
    data && Object.assign(this, data);
  }

  static fromServer(data: object): MeetingDataModel {
    return deserialize(data, MeetingDataModel);
  }
}
