import { CacheDeferred } from '@shevsky/cache-deferred';
import { GetUserResponseModel, UserModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { noop } from 'common/utils/features/noop';

export class AuthorizationService {
  static readonly #oauthUrl: string = '/oauth2/authorization/tochka';
  static readonly #partnerLoginUrl: string = '/oauth2/authorization/lime-delivery';

  @CacheDeferred
  isAuthorized(): Promise<boolean> {
    return limeTransport
      .authCheck()
      .then((): boolean => true)
      .catch((): boolean => false);
  }

  @CacheDeferred
  getUser(): Promise<UserModel> {
    return limeTransport.getUser().then(({ user }: GetUserResponseModel): UserModel => user);
  }

  loginViaOauth(): void {
    location.href = AuthorizationService.#oauthUrl;
  }

  loginAsPartner(): void {
    location.href = AuthorizationService.#partnerLoginUrl;
  }

  logout(): void {
    void limeTransport
      .logout()
      .catch(noop)
      .then((): void => location.reload());
  }
}

export const authorizationService = new AuthorizationService();
