import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { useMobile } from '@t15-ui-kit/media';
import { SearchBar } from '@t15-ui-kit/searchBar';
import { ChangeEvent, useCallback, useRef } from 'react';
import * as styles from './filter-section.styles';

type TFilterSectionProps = {
  query: string;
  loadData: VoidFunction;
  onInputChange: (v: string) => void;
  placeholder: string;
};

const DEBOUNCE_MS = 500;

export function FilterSection({ query, loadData, onInputChange, placeholder }: TFilterSectionProps): JSX.Element {
  const isMobile = useMobile();

  const debounceRef = useRef<number | null>(null);
  const handleChange = useCallback(
    ({ target: { value: nextValue } }: ChangeEvent<HTMLInputElement>): void => {
      onInputChange(nextValue);

      if (debounceRef.current) {
        window.clearTimeout(debounceRef.current);
      }

      if (!query) {
        loadData();
      } else {
        debounceRef.current = window.setTimeout(loadData, nextValue ? DEBOUNCE_MS : 0);
      }
    },
    [query, loadData, onInputChange]
  );
  const handleCancelRequest = useCallback((): void => {
    onInputChange('');

    loadData();
  }, [onInputChange, loadData]);

  return (
    <div className={styles.root(isMobile)}>
      <SearchBar
        dataAt={styles.searchBar}
        isMobile={isMobile}
        withCancel={!!query}
        onCancel={handleCancelRequest}
        hideCleanCross
      >
        <input className={styles.input} placeholder={placeholder} value={query} onChange={handleChange} />
      </SearchBar>

      <CellEmpty height="30" />
      {!isMobile && <CellEmpty height="5" />}
    </div>
  );
}
