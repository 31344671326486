import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { LimeError, LimeErrorCode } from 'common/errors/rpc/lime/lime.error';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { propagateError } from 'common/utils/processing/propagate-error';
import { ViewMeetingActionCheckAddressSheet } from 'modules/meeting/views/single/components/actions/view-meeting-action/view-meeting-action-check-address-sheet';
import { ViewMeetingActionConfirmSheet } from 'modules/meeting/views/single/components/actions/view-meeting-action/view-meeting-action-confirm-sheet';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext, useState } from 'react';

export function ViewMeetingActionFooter(): JSX.Element {
  const {
    store,
    forceLoadList,
    confirm,
    addressAndDatetime: {
      store: { date$, time$, address$ }
    },
    photos: { isAllUploaded$ }
  } = useContext(MeetingSingleCompositionContext);

  const [isCheckAddressSheetOpen, setCheckAddressSheetOpen] = useState(false);
  const [isConfirmSheetOpen, setConfirmSheetOpen] = useState(false);

  const status = useRxBind(store.status$);
  const address = useRxBind(address$);
  const date = useRxBind(date$);
  const time = useRxBind(time$);
  const isAllUploaded = useRxBind(isAllUploaded$);

  const handleClickConfirm = useCallback(async (): Promise<void> => {
    setConfirmSheetOpen(true);
  }, []);
  const handleChangeAddress = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.ADDRESS_AND_DATETIME_OF_MEETING;
  }, [store]);
  const handleConfirmSheet = useCallback((): Promise<void> => {
    return confirm()
      .then((): void => {
        forceLoadList(MEETING_LIST_TYPE.MY);
      })
      .catch((error: Error) => {
        if (error instanceof LimeError && error.code === LimeErrorCode.INVALID_ADDRESS) {
          setCheckAddressSheetOpen(true);
        } else {
          propagateError(error);
        }
      });
  }, [forceLoadList, confirm]);
  const handleCloseRequestConfirmSheet = useCallback((): void => setConfirmSheetOpen(false), []);
  const handleCloseRequestCheckAddressSheet = useCallback((): void => setCheckAddressSheetOpen(false), []);

  if (status !== MEETING_STATUS.DRAFT) {
    return <></>;
  }

  const isDisabled = !address || !date || !time || !isAllUploaded;

  const disabledReasons: Array<string> = [];
  if (!address || !date || !time) {
    disabledReasons.push('назначить встречу');
  }
  if (!isAllUploaded) {
    disabledReasons.push('загрузить фото');
  }

  return (
    <>
      <Footer title={disabledReasons.length ? `Нужно ${disabledReasons.join(' и ')}` : ''}>
        <Button disabled={isDisabled} onClick={handleClickConfirm}>
          Завершить встречу
        </Button>
      </Footer>

      <ViewMeetingActionConfirmSheet
        isOpen={isConfirmSheetOpen}
        onConfirm={handleConfirmSheet}
        onCloseRequest={handleCloseRequestConfirmSheet}
      />

      <ViewMeetingActionCheckAddressSheet
        isOpen={isCheckAddressSheetOpen}
        address={address || ''}
        onChangeAddress={handleChangeAddress}
        onCloseRequest={handleCloseRequestCheckAddressSheet}
      />
    </>
  );
}
