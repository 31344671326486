import {
  SingleFileLoaderFileCard,
  TSingleFileLoaderFileCardProps
} from 'common/components/ui/single-file-loader/single-file-loader-file-card';
import {
  SingleFileLoaderUploadCard,
  TSingleFileLoaderUploadCardProps
} from 'common/components/ui/single-file-loader/single-file-loader-upload-card';
import * as styles from 'common/components/ui/single-file-loader/single-file-loader.styles';

type TSingleFileLoaderProps = TSingleFileLoaderFileCardProps & TSingleFileLoaderUploadCardProps;

export function SingleFileLoader(props: TSingleFileLoaderProps): JSX.Element {
  return (
    <div className={styles.root}>
      {props.file || props.isDone ? <SingleFileLoaderFileCard {...props} /> : <SingleFileLoaderUploadCard {...props} />}
    </div>
  );
}
