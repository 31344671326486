import { css } from 'emotion';

export const searchBar = css({
  label: 'filter-meetings-section__searchBar'
});

export const input = css({
  label: 'filter-meetings-section__input'
});

export const root = (isMobile: boolean): string =>
  css({
    label: 'filter-meetings-section',

    [`[data-at="${searchBar}"]`]: {
      paddingTop: isMobile ? '11px' : '5px',
      paddingBottom: isMobile ? '11px' : '5px',
      paddingLeft: 0,
      paddingRight: 0,

      [`.${input}`]: {
        fontSize: isMobile ? '16px' : '18px'
      }
    }
  });
