import { ICommitable } from 'common/interfaces/commitable.interface';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { MeetingDataModel, TimeSlotModel } from 'common/models/rpc/lime';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

export class MeetingAddressAndDatetimeStore implements ICommitable, IRevertable, IDestroyable {
  #defaultDate: NotRequired<dayjs.Dayjs>;

  readonly date$: BehaviorSubject<NotRequired<dayjs.Dayjs>>;

  get date(): NotRequired<dayjs.Dayjs> {
    return this.date$.getValue();
  }

  set date(dateTime: NotRequired<dayjs.Dayjs>) {
    this.date$.next(dateTime);
  }

  #defaultTime: NotRequired<TimeSlotModel>;

  readonly time$: BehaviorSubject<NotRequired<TimeSlotModel>>;

  get time(): NotRequired<TimeSlotModel> {
    return this.time$.getValue();
  }

  set time(time: NotRequired<TimeSlotModel>) {
    this.time$.next(time);
  }

  #defaultAddress: NotRequired<string>;

  readonly address$: BehaviorSubject<NotRequired<string>>;

  get address(): NotRequired<string> {
    return this.address$.getValue();
  }

  set address(address: NotRequired<string>) {
    this.address$.next(address);
  }

  isAddressApproved: boolean;

  constructor({ date, time, address: { address } }: MeetingDataModel) {
    this.date$ = new BehaviorSubject<NotRequired<dayjs.Dayjs>>(date);
    this.#defaultDate = date;

    this.time$ = new BehaviorSubject<NotRequired<TimeSlotModel>>(time);
    this.#defaultTime = time;

    this.address$ = new BehaviorSubject<NotRequired<string>>(address);
    this.#defaultAddress = address;

    this.isAddressApproved = !!address;
  }

  commit(): void {
    this.#defaultDate = this.date;
    this.#defaultTime = this.time;
    this.#defaultAddress = this.address;
  }

  revert(): void {
    this.date = this.#defaultDate;
    this.time = this.#defaultTime;
    this.address = this.#defaultAddress;
  }

  destroy(): void {
    this.date$.complete();
    this.time$.complete();
    this.address$.complete();
  }
}
