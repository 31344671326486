import { ZindexContext } from '@t15-ui-kit/providers';
import classNames from 'classnames';
import { DropDownMenuContent } from 'common/components/ui/drop-down-menu/drop-down-menu-content';
import { TDropDownMenuOptionProps } from 'common/components/ui/drop-down-menu/drop-down-menu-option';
import * as styles from 'common/components/ui/drop-down-menu/drop-down-menu.styles';
import {
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';

type TDropDownMenuProps = {
  children: ReactElement<TDropDownMenuOptionProps> | Array<ReactElement<TDropDownMenuOptionProps>>;
  header?: ReactNode;
  className?: string;
  isOpen?: boolean;
  onCloseRequest?(): void;
};

const DEFAULT_Z_INDEX = 99999;

function handleClick(event: SyntheticEvent): void {
  event.nativeEvent.stopImmediatePropagation();
}

/** Выпадающее меню, похожее на контекстное меню, но стилизованное под стиль Точки */
export function DropDownMenu({
  children: rawChildren,
  header,
  className,
  isOpen = false,
  onCloseRequest
}: TDropDownMenuProps): JSX.Element {
  const contextualZIndex = useContext(ZindexContext);
  const zIndex = useMemo((): number => (contextualZIndex ?? DEFAULT_Z_INDEX) - 1, [contextualZIndex]);

  const handleRequestClose = useCallback((): void => onCloseRequest?.(), [onCloseRequest]);

  const children = useMemo(
    (): Array<ReactElement<TDropDownMenuOptionProps>> =>
      Children.map(
        rawChildren,
        (element: ReactElement<TDropDownMenuOptionProps>): ReactElement<TDropDownMenuOptionProps> =>
          cloneElement(element, {
            onClick: (): void => {
              element.props.onClick?.();
              handleRequestClose();
            }
          })
      ),
    [rawChildren, handleRequestClose]
  );

  useEffect((): VoidFunction => {
    document.addEventListener('click', handleRequestClose);

    return (): void => document.removeEventListener('click', handleRequestClose);
  }, [handleRequestClose]);

  const handleKeydown = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        handleRequestClose();
      }
    },
    [handleRequestClose]
  );

  useEffect((): VoidFunction => {
    document.addEventListener('keydown', handleKeydown);

    return (): void => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  return (
    <div className={classNames(styles.root(isOpen), className)} style={{ zIndex }} onClick={handleClick}>
      <DropDownMenuContent header={header}>{children}</DropDownMenuContent>
    </div>
  );
}
