import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import {
  EditMeetingRequestModel,
  GetMeetingRequestModel,
  MeetingCommentModel,
  MeetingDataModel
} from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingListComposition } from 'modules/meeting/views/list/compositions/meeting-list.composition';
import { MeetingAddressAndDatetimeService } from 'modules/meeting/views/single/services/meeting-address-and-datetime.service';
import { MeetingAssignToCourierService } from 'modules/meeting/views/single/services/meeting-assign-to-courier.service';
import { MeetingCommentsService } from 'modules/meeting/views/single/services/meeting-comments.service';
import { MeetingPhotosService } from 'modules/meeting/views/single/services/meeting-photos.service';
import { MeetingSingleStore } from 'modules/meeting/views/single/stores/meeting-single.store';

export class MeetingSingleComposition implements IDestroyable {
  readonly store: MeetingSingleStore;

  readonly photos: MeetingPhotosService;
  readonly comments: MeetingCommentsService;
  readonly assignToCourier: MeetingAssignToCourierService;
  readonly addressAndDatetime: MeetingAddressAndDatetimeService;

  protected constructor(readonly data: MeetingDataModel) {
    this.store = new MeetingSingleStore(this.data);

    this.photos = new MeetingPhotosService(this.data);
    this.comments = new MeetingCommentsService(this.data);
    this.assignToCourier = new MeetingAssignToCourierService(this.data);
    this.addressAndDatetime = new MeetingAddressAndDatetimeService(this.data);

    this.comments.dispatcher.listenNextComment(this.#handleNextComment);
  }

  static async make(id: number): Promise<MeetingSingleComposition> {
    const { data } = await limeTransport.getMeeting(new GetMeetingRequestModel({ id }));

    return new MeetingSingleComposition(data);
  }

  forceLoadList = (type: MEETING_LIST_TYPE): void => {
    MeetingListComposition.dispatcher.dispatchForceLoad(type);
  };

  confirm = async (): Promise<void> => {
    await limeTransport.editMeeting(new EditMeetingRequestModel({ id: this.data.id, isFinished: true }));

    this.store.status$.next(MEETING_STATUS.DONE);
  };

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
    this.photos.destroy();
    this.comments.destroy();
    this.assignToCourier.destroy();
    this.addressAndDatetime.destroy();
  }

  #handleNextComment = (comment: MeetingCommentModel): void => {
    this.store.comments$.next([comment, ...this.store.comments]);
  };
}
