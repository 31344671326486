import { AddCommentForMeetingActionFooter } from 'modules/meeting/views/single/components/actions/add-comment-for-meeting-action';
import { AddressAndDatetimeOfMeetingActionFooter } from 'modules/meeting/views/single/components/actions/address-and-datetime-of-meeting-action';
import { AssignMeetingToCourierActionFooter } from 'modules/meeting/views/single/components/actions/assign-meeting-to-courier-action';
import { CommentsForMeetingActionFooter } from 'modules/meeting/views/single/components/actions/comments-for-meeting-action';
import { ViewMeetingActionFooter } from 'modules/meeting/views/single/components/actions/view-meeting-action';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { ComponentType } from 'react';

export const MEETING_SINGLE_ACTION_FOOTER_COMPONENT_DICTIONARY: Partial<Record<MEETING_SINGLE_ACTION, ComponentType>> =
  {
    [MEETING_SINGLE_ACTION.VIEW_MEETING]: ViewMeetingActionFooter,
    [MEETING_SINGLE_ACTION.ADDRESS_AND_DATETIME_OF_MEETING]: AddressAndDatetimeOfMeetingActionFooter,
    [MEETING_SINGLE_ACTION.COMMENTS_FOR_MEETING]: CommentsForMeetingActionFooter,
    [MEETING_SINGLE_ACTION.ADD_COMMENT_FOR_MEETING]: AddCommentForMeetingActionFooter,
    [MEETING_SINGLE_ACTION.ASSIGN_MEETING_TO_COURIER]: AssignMeetingToCourierActionFooter
  };
