import { getData, sizeList } from '@t15-ui-kit/avatar';
import { Radius } from '@t15-ui-kit/enums';
import { skeletonAnimation, skeletonLoafL } from '@t15-ui-kit/utils';
import { css } from 'emotion';

export const skeletonIcon = css(skeletonAnimation, {
  label: `skeleton__icon`,
  width: sizeList.m,
  height: sizeList.m,
  borderRadius: Radius.se + '!important',
  mask: `url("${getData('m')}")`
});

export const skeletonName = css(skeletonLoafL, {
  label: `skeleton__name`,
  width: '240px'
});
