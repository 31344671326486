export enum CORE_ROOT_ROUTING_PATH {
  ROOT = '/',
  AUTHORIZATION = '/authorization',
  MEETINGS = '/meetings/:type?',
  SPECIAL = '/special',
  ADMIN = '/admin',
  SELLERS = '/admin/sellers',
  SELLER = '/admin/sellers/:id',
  CITIES = '/admin/cities'
}
