import { ROUTING_TYPE } from 'common/services/routing/routing.enum';
import { IRoutingService } from 'common/services/routing/routing.interface';
import { stripSlashes } from 'common/utils/features/strip-slashes';
import { createBrowserHistory, createHashHistory, createMemoryHistory, History } from 'history';
import { generatePath } from 'react-router';

export class RoutingService<T extends ROUTING_TYPE, S extends object = object> implements IRoutingService<S> {
  readonly history: History<S>;

  get currentPath(): string {
    return stripSlashes(this.history.location.pathname);
  }

  constructor(protected readonly type: T, protected readonly basename: string = '/') {
    this.history =
      this.type === ROUTING_TYPE.BROWSER
        ? createBrowserHistory({
            basename: this.basename
          })
        : this.type === ROUTING_TYPE.HASH
        ? createHashHistory()
        : createMemoryHistory();
  }

  is(path: string): boolean {
    return this.currentPath === stripSlashes(path);

    // return matchPath(this.history.location.pathname, { path, exact: true }) !== null;
  }

  goTo(path: string, params?: Record<string, string>, state?: S): void {
    const nextPath = generatePath(path, params);

    if (!this.is(nextPath)) {
      this.history.push(nextPath, state);
    }
  }

  goBack(): void {
    this.history.goBack();
  }

  goForward(): void {
    this.history.goForward();
  }
}
