import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { MeetingCommentModel, MeetingDataModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { orderMeetingComments } from 'common/utils/business/order-meeting-comments';
import { createHashSubject } from 'common/utils/features/hash-subject';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { BehaviorSubject } from 'rxjs';

export class MeetingSingleStore implements IDestroyable {
  readonly action$: BehaviorSubject<MEETING_SINGLE_ACTION> = createHashSubject<MEETING_SINGLE_ACTION>(
    MEETING_SINGLE_ACTION.VIEW_MEETING
  );

  get action(): MEETING_SINGLE_ACTION {
    return this.action$.getValue();
  }

  set action(action: MEETING_SINGLE_ACTION) {
    this.action$.next(action);
  }

  readonly status$: BehaviorSubject<MEETING_STATUS>;

  get status(): MEETING_STATUS {
    return this.status$.getValue();
  }

  set status(status: MEETING_STATUS) {
    this.status$.next(status);
  }

  readonly comments$: BehaviorSubject<Array<MeetingCommentModel>>;

  get comments(): Array<MeetingCommentModel> {
    return this.comments$.getValue();
  }

  set comments(comments: Array<MeetingCommentModel>) {
    this.comments$.next(comments.sort(orderMeetingComments));
  }

  readonly downloadsLink$: BehaviorSubject<NotRequired<string>>;

  constructor({ id, status, comments, hasDownloads }: MeetingDataModel) {
    this.status$ = new BehaviorSubject<MEETING_STATUS>(status);
    this.comments$ = new BehaviorSubject<Array<MeetingCommentModel>>(comments?.sort(orderMeetingComments) ?? []);
    this.downloadsLink$ = new BehaviorSubject(hasDownloads ? limeTransport.getDownloadsLink(id) : undefined);
  }

  destroy(): void {
    this.action$.complete();
    this.status$.complete();
    this.comments$.complete();
  }
}
