import { Dashboard } from 'common/components/ui/dashboard';
import { setTitle } from 'common/utils/features/set-title';
import { FiltersOfSpecialMeetingsSection } from 'modules/special/views/list/components/sections/filters-of-special-meetings-section';
import { ListOfSpecialMeetingsSection } from 'modules/special/views/list/components/sections/list-of-special-meetings-section';
import { memo, useEffect } from 'react';

const title = 'Управление встречами';

export const DashboardOfSpecialListSection = memo((): JSX.Element => {
  useEffect((): VoidFunction => {
    setTitle(title);

    return (): void => setTitle.restore();
  }, []);

  return (
    <Dashboard title={title}>
      <FiltersOfSpecialMeetingsSection />
      <ListOfSpecialMeetingsSection />
    </Dashboard>
  );
});
