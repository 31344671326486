import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useCallback, useContext } from 'react';

export function AssignSpecialMeetingToCourierActionFooter(): JSX.Element {
  const {
    forceLoadList,
    assignToCourier: {
      store: { comment$ },
      assignToCourier
    }
  } = useContext(SpecialSingleCompositionContext);

  const [isLoading, setLoading] = useSafeState(false);
  const comment = useRxBind(comment$);

  const handleClick = useCallback((): void => {
    setLoading(true);

    void assignToCourier()
      .then((): void => {
        forceLoadList();
        coreModalRouting.goToRoot();
        coreRootRouting.goToSpecial();
      })
      .catch(propagateError)
      .then((): void => setLoading(false));
  }, [forceLoadList, assignToCourier, setLoading]);

  return (
    <Footer>
      <Button disabled={!comment} onClick={handleClick} isLoading={isLoading}>
        Передать курьеру
      </Button>
    </Footer>
  );
}
