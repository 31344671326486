import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = (isMobile: boolean): string =>
  css({
    ...(!isMobile
      ? {
          borderRadius: '10px',
          transition: 'background-color .2s ease-in-out',

          [':hover']: {
            backgroundColor: Colors.Grey100
          }
        }
      : void 0)
  });
