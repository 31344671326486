import { DControl } from '@t15-ui-kit/dControl';
import { useMobile } from '@t15-ui-kit/media';
import { TextArea } from '@t15-ui-kit/textArea';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { ChangeEvent, useCallback, useContext } from 'react';

const TEXTAREA_ROWS_DESKTOP = 6;
const TEXTAREA_ROWS_MOBILE = 2;

export function AssignMeetingToCourierActionComment(): JSX.Element {
  const isMobile = useMobile();

  const {
    assignToCourier: { store }
  } = useContext(MeetingSingleCompositionContext);

  const comment = useRxBind(store.comment$);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      store.comment = event.target.value;
    },
    [store]
  );

  return (
    <DControl type="textarea" borderRadius="l" isMobile={isMobile}>
      <TextArea
        placeholder="Причина передачи встречи"
        value={comment ?? ''}
        onChange={handleChange}
        minRows={isMobile ? TEXTAREA_ROWS_MOBILE : TEXTAREA_ROWS_DESKTOP}
        rows={isMobile ? TEXTAREA_ROWS_MOBILE : TEXTAREA_ROWS_DESKTOP}
        autoFocus
      />
    </DControl>
  );
}
