import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { MeetingCommentModel, MeetingDataModel } from 'common/models/rpc/lime';
import { orderMeetingComments } from 'common/utils/business/order-meeting-comments';
import { createHashSubject } from 'common/utils/features/hash-subject';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { BehaviorSubject } from 'rxjs';

export class SpecialSingleStore implements IDestroyable {
  readonly action$: BehaviorSubject<SPECIAL_SINGLE_ACTION> = createHashSubject<SPECIAL_SINGLE_ACTION>(
    SPECIAL_SINGLE_ACTION.VIEW_SPECIAL_MEETING
  );

  get action(): SPECIAL_SINGLE_ACTION {
    return this.action$.getValue();
  }

  set action(action: SPECIAL_SINGLE_ACTION) {
    this.action$.next(action);
  }

  readonly comments$: BehaviorSubject<Array<MeetingCommentModel>>;

  get comments(): Array<MeetingCommentModel> {
    return this.comments$.getValue();
  }

  set comments(comments: Array<MeetingCommentModel>) {
    this.comments$.next(comments.sort(orderMeetingComments));
  }

  constructor({ comments }: MeetingDataModel) {
    this.comments$ = new BehaviorSubject<Array<MeetingCommentModel>>(comments?.sort(orderMeetingComments) ?? []);
  }

  destroy(): void {
    this.action$.complete();
    this.comments$.complete();
  }
}
