import { MeetingsCardsGroup } from 'common/components/ui/meetings-cards-group';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { BriefOfMeetingSection } from 'modules/meeting/views/list/components/sections/brief-of-meeting-section';

type TGroupOfMeetingsSectionProps = {
  title: string;
  list: Array<MeetingDataModel>;
};

export function GroupOfMeetingsSection({ title, list }: TGroupOfMeetingsSectionProps): JSX.Element {
  return (
    <MeetingsCardsGroup title={title}>
      {list.map(
        (data: MeetingDataModel): JSX.Element => (
          <BriefOfMeetingSection key={data.id} data={data} />
        )
      )}
    </MeetingsCardsGroup>
  );
}
