import { deserialize, JsonNameReadonly } from 'tserialize';

export class GroupInfoModel {
  @JsonNameReadonly()
  id!: number;

  @JsonNameReadonly('external_id')
  externalId!: string;

  @JsonNameReadonly()
  name!: string;

  @JsonNameReadonly('inn')
  taxCode?: string;

  @JsonNameReadonly('deleted')
  isDeleted?: boolean;

  @JsonNameReadonly('special')
  isSpecial?: boolean;

  @JsonNameReadonly('meeting_age')
  meetingAge?: number;

  static fromServer(data: object): GroupInfoModel {
    return deserialize(data, GroupInfoModel);
  }
}
