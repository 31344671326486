import { AddCommentForSpecialMeetingActionComment } from 'modules/special/views/single/components/actions/add-comment-for-special-meeting-action/add-comment-for-special-meeting-action-comment';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext, useEffect } from 'react';

export function AddCommentForSpecialMeetingAction(): JSX.Element {
  const { comments } = useContext(SpecialSingleCompositionContext);

  useEffect((): VoidFunction => {
    return (): void => {
      comments.revert();
    };
  }, [comments]);

  return (
    <>
      <AddCommentForSpecialMeetingActionComment />
    </>
  );
}
