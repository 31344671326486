import { useMobile } from '@t15-ui-kit/media';
import { AllColors } from '@t15-ui-kit/types';
import { Typography } from '@t15-ui-kit/typography';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import * as styles from 'common/components/ui/action-panel/action-panel.styles';
import { cloneElement, ReactElement, SyntheticEvent, useCallback } from 'react';

export type TActionPanelActionProps = {
  children: string;
  icon: ReactElement<IIconProps>;
  href?: string;
  blank?: boolean;
  onClick?(): void;
};

export function ActionPanelAction({ children, icon, href, blank, onClick }: TActionPanelActionProps): JSX.Element {
  const isMobile = useMobile();

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (!href) {
        event.preventDefault();
      }

      onClick?.();
    },
    [href, onClick]
  );

  return (
    <a
      href={href ?? '#'}
      target={blank ? '_blank' : undefined}
      className={styles.action(isMobile)}
      onClick={handleClick}
    >
      {cloneElement(icon, { size: 'l', color: 'currentColor' as AllColors })}

      <Typography type="span" size="m" weight="500">
        {children}
      </Typography>
    </a>
  );
}
