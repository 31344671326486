import { CommentsOfMeeting } from 'common/components/ui/comments-of-meeting';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext } from 'react';

export function CommentsOfMeetingSection(): JSX.Element {
  const { store } = useContext(MeetingSingleCompositionContext);

  const status = useRxBind(store.status$);
  const comments = useRxBind(store.comments$);

  const handleClickAddComment = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.ADD_COMMENT_FOR_MEETING;
  }, [store]);

  const handleShowAllRequest = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.COMMENTS_FOR_MEETING;
  }, [store]);

  return (
    <CommentsOfMeeting
      status={status}
      comments={comments}
      onAddComment={handleClickAddComment}
      onShowAll={handleShowAllRequest}
    />
  );
}
