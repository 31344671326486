import { ActionSheet, ActionSheetRow } from '@t15-ui-kit/actionSheet';
import { Person } from '@tochka-modules/t15-ui-kit-icons/30/Filled/Person';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { useCallback } from 'react';

type TTakeMeetingToWorkSectionProps = {
  isOpen: boolean;
  onConfirm(): Promise<void>;
  onCloseRequest(): void;
};

export function TakeMeetingToWorkSection({
  isOpen,
  onConfirm,
  onCloseRequest
}: TTakeMeetingToWorkSectionProps): JSX.Element {
  const [isLoading, setLoading] = useSafeState(false);

  const handleClickConfirm = useCallback((): void => {
    setLoading(true);

    onConfirm().finally((): void => setLoading(false));
  }, [onConfirm, setLoading]);

  return (
    <ActionSheet text={'Взять встречу в\xA0работу?'} onClose={onCloseRequest} isOpen={isOpen}>
      <ActionSheetRow text={'Взять в\xA0работу'} icon={<Person />} onClick={handleClickConfirm} isLoading={isLoading} />
    </ActionSheet>
  );
}
