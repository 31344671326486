import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Link } from '@t15-ui-kit/link';
import { Typography } from '@t15-ui-kit/typography';
import { LikeNavigator, LikeNavigatorRow } from 'common/components/ui/like-navigator';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { formatPhoneNumber } from 'common/utils/formatters/format-phone-number';

type TDetailsOfMeetingProps = {
  data: MeetingDataModel;
};

export function DetailsOfMeeting({ data }: TDetailsOfMeetingProps): JSX.Element {
  const {
    customer: { headName, taxCode, snils, phoneNumber, additionalPhoneNumber },
    seller
  } = data;

  return (
    <LikeNavigator title="Детали встречи">
      <LikeNavigatorRow title="Руководитель">{headName}</LikeNavigatorRow>
      <LikeNavigatorRow title="Основной телефон" copyValue={formatPhoneNumber(phoneNumber)}>
        <Link href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</Link>
      </LikeNavigatorRow>
      {additionalPhoneNumber ? (
        <LikeNavigatorRow title="Дополнительный телефон" copyValue={formatPhoneNumber(additionalPhoneNumber)}>
          <Link href={`tel:${additionalPhoneNumber}`}>{formatPhoneNumber(additionalPhoneNumber)}</Link>
        </LikeNavigatorRow>
      ) : (
        <LikeNavigatorRow title="Дополнительный телефон">
          <Typography color={ColorsBasicEnum.Secondary} size="inherit" weight="inherit">
            Не&nbsp;указан
          </Typography>
        </LikeNavigatorRow>
      )}
      {!!taxCode && (
        <LikeNavigatorRow title="ИНН" copyValue={taxCode}>
          {taxCode}
        </LikeNavigatorRow>
      )}
      {!!snils && (
        <LikeNavigatorRow title="СНИЛС" copyValue={snils}>
          {snils}
        </LikeNavigatorRow>
      )}
      {!!seller && <LikeNavigatorRow title="Менеджер">{seller.name}</LikeNavigatorRow>}
    </LikeNavigator>
  );
}
