import { ProductDocumentInfoModel } from 'common/models/rpc/lime/internal/product-document-info.model';
import { JsonArray, JsonNameReadonly } from 'tserialize';

export class ProductInfoModel {
  @JsonNameReadonly('id')
  id!: string;

  @JsonNameReadonly('name')
  name!: string;

  @JsonArray(ProductDocumentInfoModel, 'documents')
  documents: Array<ProductDocumentInfoModel> = [];
}
