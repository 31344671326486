import dayjs from 'dayjs';

export function deserializeDatetime(value?: string): NotRequired<dayjs.Dayjs> {
  if (!value) {
    return void 0;
  }

  const date = dayjs(value);

  return date.isValid() ? date : void 0;
}
