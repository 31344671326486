import { httpRequestService } from 'common/services/http-request';
import { IJsonRpcRequestService } from 'common/services/json-rpc-request/json-rpc-request.interface';
import { TJsonRpcRequestResponse } from 'common/services/json-rpc-request/json-rpc-request.type';
import { nanoid } from 'nanoid';

export class JsonRpcRequestService implements IJsonRpcRequestService {
  post<T = object>(url: string, method: string, params?: object): Promise<T> {
    return httpRequestService
      .post<TJsonRpcRequestResponse<T>>(url, {
        id: nanoid(),
        jsonrpc: '2.0',
        method,
        params
      })
      .then((response: TJsonRpcRequestResponse<T>): Promise<never> | T => {
        if ('result' in response) {
          return response.result as T;
        }

        if (response.error) {
          return Promise.reject(
            new Error(`${response.error?.message ?? 'Request failed'} (${response.error?.code ?? 0})`)
          );
        }

        return Promise.reject(new Error('Request without response'));
      });
  }
}

export const jsonRpcRequestService = new JsonRpcRequestService();
