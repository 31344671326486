import dayjs from 'dayjs';

export function deserializeDate(value?: string): NotRequired<dayjs.Dayjs> {
  if (!value) {
    return void 0;
  }

  const date = dayjs(value, 'YYYY-MM-DD', true);

  return date.isValid() ? date : void 0;
}
