import { NotPropagatedError } from 'common/errors/not-propagated.error';

function createFakeElem(): HTMLTextAreaElement {
  const fakeElem = document.createElement('textarea');

  fakeElem.setAttribute('readonly', '');

  fakeElem.style.position = 'absolute';
  fakeElem.style.top = '-9999px';
  fakeElem.style.left = '-9999px';
  fakeElem.style.opacity = '0';

  return fakeElem;
}

export function copyToClipboard(text: string): Promise<void> {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }

  return new Promise((resolve: VoidFunction, reject: (error: Error) => void): void => {
    const fakeElem = createFakeElem();
    const activeElement = document.activeElement;
    let successful;

    fakeElem.value = text;

    document.body.appendChild(fakeElem);

    fakeElem.focus();
    fakeElem.select();

    try {
      successful = document.execCommand('copy');
    } catch (e) {
      successful = false;
    }

    document.body.removeChild(fakeElem);

    activeElement && (activeElement as HTMLElement).focus();

    successful ? resolve() : reject(new NotPropagatedError());
  });
}
