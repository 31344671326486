import { MeetingDataModel } from 'common/models/rpc/lime/internal';

export class GetMeetingListResponseModel {
  list!: Array<MeetingDataModel>;

  constructor(data?: GetMeetingListResponseModel) {
    data && Object.assign(this, data);
  }

  static fromServer(data: object): GetMeetingListResponseModel {
    const model = new GetMeetingListResponseModel();
    model.list = Array.isArray(data) ? data.map(MeetingDataModel.fromServer) : [];

    return model;
  }
}
