import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { userContext } from 'core/providers/user-context-provider';
import { DashboardOfMeetingList } from 'modules/meeting/views/list/components/dashboard/dashboard-of-meeting-list';
import { WebStubOfMeetingsSection } from 'modules/meeting/views/list/components/sections/web-stub-of-meetings-section';
import { MeetingListComposition } from 'modules/meeting/views/list/compositions/meeting-list.composition';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { useContext, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

export function MeetingList(): JSX.Element {
  const {
    params: { type = MEETING_LIST_TYPE.AVAILABLE }
  } = useRouteMatch<{ type?: MEETING_LIST_TYPE }>();

  const user = useContext(userContext);

  const composition = useMemo((): MeetingListComposition => new MeetingListComposition(type), [type]);

  useEffect((): VoidFunction => {
    return (): void => composition?.destroy();
  }, [composition]);

  if (user?.webRestriction) {
    return <WebStubOfMeetingsSection />;
  }

  return (
    <MeetingListCompositionContext.Provider value={composition}>
      <DashboardOfMeetingList />
    </MeetingListCompositionContext.Provider>
  );
}
