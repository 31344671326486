import { TimeSlotModel } from 'common/models/rpc/lime/internal';
import { serializeDate } from 'common/utils/serializers/serialize-date';
import dayjs from 'dayjs';
import { JsonName, JsonStruct, serialize } from 'tserialize';

export class EditSpecialMeetingRequestModel {
  /** Идентификатор встречи */
  id!: number;

  @JsonName('date', serializeDate)
  date?: dayjs.Dayjs;

  @JsonStruct(TimeSlotModel, 'start_at')
  startAt?: TimeSlotModel;

  @JsonStruct(TimeSlotModel, 'end_at')
  endAt?: TimeSlotModel;

  @JsonName('comment')
  comment?: string;

  @JsonName('cancel')
  isCanceled?: boolean;

  @JsonName('sellerId')
  sellerId?: number;

  constructor(data: Omit<EditSpecialMeetingRequestModel, 'toServer'>) {
    Object.assign(this, data);
  }

  toServer(): object {
    return serialize(this);
  }
}
