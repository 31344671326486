import { CellOffset } from '@t15-ui-kit/cellOffset';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Typography } from '@t15-ui-kit/typography';
import { PropsWithChildren } from 'react';

type TSimpleRowProps = PropsWithChildren<{
  title: string;
  description?: string;
}>;

export function SimpleRow({ children, title, description }: TSimpleRowProps): JSX.Element {
  return (
    <>
      <CellOffset offsetTop="m" offsetBottom="m">
        <Typography type="div" size="xl" weight="500">
          {title}
        </Typography>
        {!!description && (
          <Typography type="div" size="s" weight="400" color={ColorsBasicEnum.Secondary}>
            {description}
          </Typography>
        )}
      </CellOffset>

      {children}
    </>
  );
}
