import { JsonName } from 'tserialize';

export class PaginationModel {
  @JsonName('last_page')
  lastPage!: number;

  constructor(data: PaginationModel) {
    Object.assign(this, data);
  }
}
