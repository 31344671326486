import { JsonNameReadonly } from 'tserialize';

export class CustomerModel {
  /** Название компании */
  @JsonNameReadonly('company_name')
  companyName!: string;

  /** ИНН компании */
  @JsonNameReadonly('tax_code')
  taxCode?: string;

  /** СНИЛС */
  @JsonNameReadonly('snils')
  snils?: string;

  /** ФИО руководителя */
  @JsonNameReadonly('head_name')
  headName!: string;

  @JsonNameReadonly('phone_number')
  phoneNumber!: string;

  @JsonNameReadonly('additional_phone_number')
  additionalPhoneNumber?: string;

  constructor(data: CustomerModel) {
    Object.assign(this, data);
  }
}
