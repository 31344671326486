import { Grid } from '@t15-ui-kit/grid';
import { AssignSpecialMeetingToSellerActionSelect } from 'modules/special/views/single/components/actions/assign-special-meeting-to-seller-action/assign-special-meeting-to-seller-action-select';
import * as styles from 'modules/special/views/single/components/actions/assign-special-meeting-to-seller-action/assign-special-meeting-to-seller-action.styles';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext, useEffect } from 'react';

export function AssignSpecialMeetingToSellerAction(): JSX.Element {
  const { assignToSeller } = useContext(SpecialSingleCompositionContext);

  useEffect((): VoidFunction => {
    return (): void => {
      assignToSeller.revert();
    };
  }, [assignToSeller]);

  return (
    <Grid container wrap="nowrap" direction="column">
      <Grid item className={styles.selectGridItem} grow="1">
        <AssignSpecialMeetingToSellerActionSelect />
      </Grid>
    </Grid>
  );
}
