import { useMobile } from '@t15-ui-kit/media';
import { AllColors } from '@t15-ui-kit/types';
import { Typography } from '@t15-ui-kit/typography';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import classNames from 'classnames';
import * as styles from 'common/components/ui/main-page/main-page.styles';
import {
  AnchorHTMLAttributes,
  cloneElement,
  DetailedHTMLProps,
  forwardRef,
  ReactElement,
  Ref,
  SyntheticEvent,
  useCallback
} from 'react';

export type TMainPageLinkProps = {
  icon: ReactElement<IIconProps>;
  activeIcon?: ReactElement<IIconProps>;
  isActive?: boolean;
  onClick?(): void;
} & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const MainPageLink = forwardRef(
  (
    { children, icon, activeIcon, isActive, onClick, ...props }: TMainPageLinkProps,
    ref: Ref<HTMLAnchorElement>
  ): JSX.Element => {
    const isMobile = useMobile();

    const handleClick = useCallback(
      (event: SyntheticEvent): void => {
        if (onClick) {
          event.preventDefault();

          onClick();
        }
      },
      [onClick]
    );

    return (
      <a
        ref={ref}
        className={classNames(styles.link(isMobile, isActive ?? false), props.className)}
        {...props}
        href={props.href ?? '#'}
        onClick={handleClick}
      >
        {cloneElement(isActive && !!activeIcon ? activeIcon : icon, {
          color: 'currentColor' as AllColors,
          size: isMobile ? 'xl' : 'l'
        })}

        {!isMobile && (
          <Typography type="span" size="m" weight="700">
            {children}
          </Typography>
        )}
      </a>
    );
  }
);
