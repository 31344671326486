import { ActionSheet, ActionSheetRow } from '@t15-ui-kit/actionSheet';
import { Typography } from '@t15-ui-kit/typography';
import { Pencil } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Pencil';
import { useCallback } from 'react';

type TViewMeetingActionCheckAddressSheetProps = {
  address: string;
  isOpen: boolean;
  onChangeAddress(): void;
  onCloseRequest(): void;
};

export function ViewMeetingActionCheckAddressSheet({
  address,
  isOpen,
  onChangeAddress,
  onCloseRequest
}: TViewMeetingActionCheckAddressSheetProps): JSX.Element {
  const handleClickChangeAddress = useCallback((): void => {
    onCloseRequest();
    onChangeAddress();
  }, [onChangeAddress, onCloseRequest]);

  return (
    <ActionSheet
      text={
        <>
          Адрес встречи{' '}
          <Typography type="span" weight="500" whiteSpace="nowrap">
            {address}
          </Typography>{' '}
          указан не&nbsp;полностью. Для завершения встречи, укажите её полный адрес.
        </>
      }
      onClose={onCloseRequest}
      isOpen={isOpen}
    >
      <ActionSheetRow text="Изменить адрес" icon={<Pencil />} onClick={handleClickChangeAddress} />
    </ActionSheet>
  );
}
