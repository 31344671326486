import { CommentsOfMeeting } from 'common/components/ui/comments-of-meeting';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { useCallback, useContext } from 'react';

export function CommentsOfSpecialMeetingSection(): JSX.Element {
  const {
    data: { status },
    store
  } = useContext(SpecialSingleCompositionContext);

  const comments = useRxBind(store.comments$);

  const handleClickAddComment = useCallback((): void => {
    store.action = SPECIAL_SINGLE_ACTION.ADD_COMMENT_FOR_SPECIAL_MEETING;
  }, [store]);

  const handleShowAllRequest = useCallback((): void => {
    store.action = SPECIAL_SINGLE_ACTION.COMMENTS_FOR_SPECIAL_MEETING;
  }, [store]);

  return (
    <CommentsOfMeeting
      status={status}
      comments={comments}
      onAddComment={handleClickAddComment}
      onShowAll={handleShowAllRequest}
    />
  );
}
