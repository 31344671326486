import { CellCard } from '@t15-ui-kit/cellCard';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { TLikeNavigatorActionProps } from 'common/components/ui/like-navigator/like-navigator-action';
import * as styles from 'common/components/ui/like-navigator/like-navigator.styles';
import { PropsWithChildren, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';

type TLikeNavigatorProps = PropsWithChildren<{
  title: string;
  actions?: ReactElement<TLikeNavigatorActionProps> | Array<ReactElement<TLikeNavigatorActionProps>>;
  maxRows?: number;
  onShowAllRequest?(): void;
}>;

export function LikeNavigator({
  children,
  title,
  actions,
  maxRows,
  onShowAllRequest
}: TLikeNavigatorProps): JSX.Element {
  const isMobile = useMobile();

  const [isVisibleAll, setVisibleAll] = useState(false);

  const rows = useMemo(
    (): ReactNode => (Array.isArray(children) && !!maxRows ? children.slice(0, maxRows) : children),
    [children, maxRows]
  );

  const hasHiddenRows = Array.isArray(children) && Array.isArray(rows) && children.length > rows.length;

  const handleClickShowAll = useCallback(
    (): void => (onShowAllRequest ? onShowAllRequest() : setVisibleAll(true)),
    [onShowAllRequest]
  );

  return (
    <CellCard shadow="Card">
      <CellOffset offsetTop={isMobile ? void 0 : 's'} offsetBottom={isMobile ? '2xs' : 's'}>
        <CellOffset
          offsetTop={isMobile ? 'm' : actions ? '2xs' : 's'}
          offsetRight={isMobile ? 'm' : 'xs'}
          offsetBottom={isMobile ? 'xs' : actions ? '2xs' : 's'}
          offsetLeft="m"
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid>
              <Typography size="xl" weight="500">
                {title}
              </Typography>
            </Grid>

            {!!actions && <Grid>{actions}</Grid>}
          </Grid>
        </CellOffset>

        {isVisibleAll ? children : rows}

        {hasHiddenRows && !isVisibleAll && (
          <div className={styles.showAllButton(isMobile)} onClick={handleClickShowAll}>
            <Typography align="center" size="m" weight="500">
              Показать все
            </Typography>
          </div>
        )}
      </CellOffset>
    </CellCard>
  );
}
