import { IAutocompleteFieldElementType } from '@t15-ui-kit/autocompleteField';
import { UserModel } from 'common/models/rpc/lime';
import { GetUserListResponseModel } from 'common/models/rpc/lime/get-user-list-response.model';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { AddSellerStore } from 'modules/seller/views/list/stores/add-seller.store';

const USER_LIST_LIMIT = 10;
const PAGE = 0;

class AddSellerComposition {
  readonly store: AddSellerStore = new AddSellerStore();

  loadList = (query: string): Promise<Array<IAutocompleteFieldElementType>> => {
    return limeTransport
      .getUserList({
        query,
        page: PAGE,
        limit: USER_LIST_LIMIT
      })
      .then(({ sellers }: GetUserListResponseModel): Array<IAutocompleteFieldElementType> => {
        this.store.list = sellers;
        return sellers.map(
          (user: UserModel): IAutocompleteFieldElementType => ({
            title: user.name,
            id: user.code
          })
        );
      })
      .catch(() => {
        return [];
      });
  };

  addSeller = (): Promise<void> => {
    if (this.store.user) {
      const { name, user, code } = this.store.user;
      const { webRestriction } = this.store;

      return limeTransport.addSeller({ name, user, code, webRestriction }).catch(propagateError);
    }

    return Promise.reject(new Error('Выберите пользователя'));
  };
}

export const addSellerComposition = new AddSellerComposition();
