import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { ProductDocumentInfoModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { PhotosOfMeetingActionPhoto } from 'modules/meeting/views/single/components/actions/photos-of-meeting-action/photos-of-meeting-action-photo';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useContext } from 'react';

export function PhotosOfMeetingAction(): JSX.Element {
  const isMobile = useMobile();

  const {
    data: { product },
    addressAndDatetime: {
      store: { date$, time$ }
    }
  } = useContext(MeetingSingleCompositionContext);

  const date = useRxBind(date$);
  const time = useRxBind(time$);

  if (!date || !time) {
    return (
      <>
        <CellEmpty height="120" />
        <EmptyView text="Сначала назначьте встречу" />
      </>
    );
  }

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      template={isMobile ? ['12'] : ['4', '4', '4']}
      elementOffset={isMobile ? ['s', 'none'] : ['xl', 'xl']}
    >
      {product.documents.map(
        (document: ProductDocumentInfoModel): JSX.Element => (
          <Grid key={document.type}>
            <PhotosOfMeetingActionPhoto document={document} />
          </Grid>
        )
      )}
    </Grid>
  );
}
