import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import * as styles from 'common/components/ui/meeting-comment-row/meeting-comment-row.styles';
import { MeetingCommentModel } from 'common/models/rpc/lime';
import { formatDate } from 'common/utils/formatters/format-date';
import { formatTime } from 'common/utils/formatters/format-time';

type TMeetingCommentRowProps = {
  comment: MeetingCommentModel;
};

export function MeetingCommentRow({ comment }: TMeetingCommentRowProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <div className={styles.root(isMobile)}>
      <CellOffset offset={isMobile ? ['xs', null] : ['s', 'm']}>
        <Typography size="s" weight="500" color={ColorsBasicEnum.Secondary}>
          {comment.createdAt
            ? `${formatDate(comment.createdAt)} в ${formatTime(comment.createdAt)}`
            : 'Время неизвестно'}
        </Typography>
        <CellEmpty height="5" />
        <Typography size="m" weight="500">
          {comment.value}
        </Typography>
      </CellOffset>
    </div>
  );
}
