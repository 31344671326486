import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Typography } from '@t15-ui-kit/typography';
import { MeetingDataModel } from 'common/models/rpc/lime';

type TMeetingCardCustomerProps = {
  data: MeetingDataModel;
};

export function MeetingCardCustomer({ data }: TMeetingCardCustomerProps): JSX.Element {
  return (
    <>
      <Typography type="div" color={ColorsBasicEnum.Secondary} size="s" weight="500">
        {data.customer.companyName}
      </Typography>
      <CellEmpty height="2" />
      <Typography type="div" color={ColorsBasicEnum.Secondary} size="xs" weight="400">
        {[
          !!data.customer.taxCode && `ИНН ${data.customer.taxCode}`,
          !!data.customer.snils && `СНИЛС ${data.customer.snils}`
        ]
          .filter(Boolean)
          .join(', ')}
      </Typography>
    </>
  );
}
