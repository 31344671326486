import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { DChip } from '@t15-ui-kit/dChip';
import { Grid } from '@t15-ui-kit/grid';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { FilterSection } from 'common/components/ui/filter-section';
import { MEETING_STATUS_TITLE_DICTIONARY } from 'common/dictionaries/meeting-status.dictionary';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { GroupInfoModel } from 'common/models/rpc/lime';
import { formatRestValues } from 'common/utils/formatters/format-rest-values';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { GroupSelectPopup } from 'modules/special/views/list/components/popups/group-select-popup';
import { MeetingStatusSelectPopup } from 'modules/special/views/list/components/popups/meeting-status-select-popup';
import { SpecialListCompositionContext } from 'modules/special/views/list/contexts/special-list-composition.context';
import { memo, useCallback, useContext } from 'react';

const emptyArray = [];

export const FiltersOfSpecialMeetingsSection = memo((): JSX.Element => {
  const { store, forceLoadList } = useContext(SpecialListCompositionContext);
  const filters = useRxBind(store.filters$);
  const helper = useRxBind(store.helper$);

  const query = filters.query ?? '';
  const statuses = filters.statuses ?? emptyArray;
  const groupIds = filters.groupIds ?? emptyArray;

  const handleInputChange = useCallback(
    (value: string): void => {
      store.query = value || void 0;
    },
    [store]
  );

  const handleClickStatusFilter = useCallback(() => {
    uiKitService.openPopup(
      <MeetingStatusSelectPopup
        currentStatuses={statuses}
        onSelect={(nextStatuses: Array<MEETING_STATUS>) => {
          store.statuses = nextStatuses;
          forceLoadList();
        }}
      />,
      { onCloseRequest: uiKitService.closePopup }
    );
  }, [store, statuses, forceLoadList]);

  const handleCancelStatusFilter = useCallback(() => {
    store.statuses = void 0;
    forceLoadList();
  }, [store, forceLoadList]);

  const handleClickGroupFilter = useCallback(() => {
    uiKitService.openPopup(
      <GroupSelectPopup
        currentGroupIds={groupIds}
        onSelect={(nextGroups: Array<GroupInfoModel>) => {
          store.groupsByIds = Object.fromEntries(nextGroups.map((group: GroupInfoModel) => [group.id, group]));
          store.groupIds = nextGroups.map((group: GroupInfoModel) => group.id);
          forceLoadList();
        }}
      />,
      { onCloseRequest: uiKitService.closePopup }
    );
  }, [groupIds, store, forceLoadList]);

  const handleCancelGroupFilter = useCallback(() => {
    store.groupIds = void 0;
    forceLoadList();
  }, [store, forceLoadList]);

  return (
    <>
      <Grid container direction="row" elementOffset="s">
        <Grid item>
          <DChip
            variant={statuses.length ? 'action' : 'dropdown'}
            isSelected={!!statuses.length}
            text={
              statuses.length
                ? formatRestValues(statuses.map((status: MEETING_STATUS) => MEETING_STATUS_TITLE_DICTIONARY[status]))
                : 'Статус встречи'
            }
            onClick={handleClickStatusFilter}
            onCloseAction={statuses.length ? handleCancelStatusFilter : void 0}
          />
        </Grid>
        <Grid item>
          <DChip
            variant={groupIds.length ? 'action' : 'dropdown'}
            isSelected={!!groupIds.length}
            text={
              groupIds.length
                ? formatRestValues(groupIds.map((groupId: number) => helper.groupsByIds[groupId]?.name ?? '?'))
                : 'Группа'
            }
            onClick={handleClickGroupFilter}
            onCloseAction={groupIds.length ? handleCancelGroupFilter : void 0}
          />
        </Grid>
      </Grid>
      <CellEmpty height="20" />
      <FilterSection
        query={query}
        loadData={forceLoadList}
        onInputChange={handleInputChange}
        placeholder="Поиск по встречам"
      />
    </>
  );
});
