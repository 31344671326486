import { Dashboard } from 'common/components/ui/dashboard';
import { FilterSection } from 'common/components/ui/filter-section';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { setTitle } from 'common/utils/features/set-title';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { ListOfMeetingsSection } from 'modules/meeting/views/list/components/sections/list-of-meetings-section';
import { TypeOfMeetingsSelectSection } from 'modules/meeting/views/list/components/sections/type-of-meetings-select-section';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { memo, useCallback, useContext, useEffect } from 'react';

export const DashboardOfMeetingList = memo((): JSX.Element => {
  const { type, store, forceLoadList } = useContext(MeetingListCompositionContext);
  const query = useRxBind(store.query$);

  const title = type === MEETING_LIST_TYPE.HISTORY ? 'История' : 'Список встреч';

  const handleInputChange = useCallback(
    (value: string): void => {
      store.query = value;
    },
    [store]
  );

  useEffect((): VoidFunction => {
    setTitle(title);

    return (): void => setTitle.restore();
  }, [title]);

  return (
    <Dashboard title={title}>
      <FilterSection
        query={query}
        loadData={forceLoadList}
        onInputChange={handleInputChange}
        placeholder="Поиск по встречам"
      />
      {type !== MEETING_LIST_TYPE.HISTORY && <TypeOfMeetingsSelectSection />}
      <ListOfMeetingsSection />
    </Dashboard>
  );
});
