import { AssignMeetingToCourierActionComment } from 'modules/meeting/views/single/components/actions/assign-meeting-to-courier-action/assign-meeting-to-courier-action-comment';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useContext, useEffect } from 'react';

export function AssignMeetingToCourierAction(): JSX.Element {
  const { assignToCourier } = useContext(MeetingSingleCompositionContext);

  useEffect((): VoidFunction => {
    return (): void => {
      assignToCourier.revert();
    };
  }, [assignToCourier]);

  return (
    <>
      <AssignMeetingToCourierActionComment />
    </>
  );
}
