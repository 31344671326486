import { AssignSpecialMeetingToCourierActionComment } from 'modules/special/views/single/components/actions/assign-special-meeting-to-courier-action/assign-special-meeting-to-courier-action-comment';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext, useEffect } from 'react';

export function AssignSpecialMeetingToCourierAction(): JSX.Element {
  const { assignToCourier } = useContext(SpecialSingleCompositionContext);

  useEffect((): VoidFunction => {
    return (): void => {
      assignToCourier.revert();
    };
  }, [assignToCourier]);

  return (
    <>
      <AssignSpecialMeetingToCourierActionComment />
    </>
  );
}
