import { Avatar } from '@t15-ui-kit/avatar';
import { Button } from '@t15-ui-kit/button';
import Tochka from '@tochka-modules/t15-ui-kit-logos/Banks/Tochka.svg';

type TOauthButtonProps = {
  onClick(): void;
};

export function OauthButton({ onClick }: TOauthButtonProps): JSX.Element {
  return (
    <Button type="secondary" width="100%" accLeft={<Avatar src={Tochka} size="xs" />} onClick={onClick}>
      Войти через tochkaOauth
    </Button>
  );
}
