import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

const HEADER_HEIGHT_DESKTOP = '84px';
const HEADER_HEIGHT_MOBILE = '74px';

export const root = css({
  label: 'main-page',
  backgroundColor: '#fff',
  width: '100%',
  position: 'absolute',
  inset: 0,
  display: 'flex',
  flexDirection: 'column'
});

export const headerOut = css({
  label: 'main-page__header--out'
});

export const header = (isMobile: boolean): string =>
  css({
    label: 'main-page__header',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? '15px 20px' : '20px 40px',
    height: isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    transition: 'transform .3s ease-in-out',
    backgroundColor: '#fff',
    zIndex: 1,
    maxWidth: isMobile ? '100%' : '950px',
    margin: isMobile ? 0 : '0 auto',

    [`&.${headerOut}`]: {
      transform: `translateY(-${isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP})`
    }
  });

export const title = css({
  label: 'main-page__title',
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px'
});

export const logo = css({
  label: 'main-page__logo',
  width: '44px',
  height: '44px'
});

export const linksDesktop = css({
  label: 'main-page__links-desktop',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  columnGap: '30px',
  marginLeft: '80px',
  flexWrap: 'wrap',
  rowGap: '10px'
});

export const linksMobile = css({
  label: 'main-page__links-mobile',
  padding: '10px 0',
  display: 'flex',
  justifyContent: 'space-between'
});

export const link = (isMobile: boolean, isActive: boolean): string =>
  css({
    label: 'main-page__link',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isMobile ? 'center' : 'flex-start',
    flexGrow: isMobile ? 1 : 0,
    columnGap: '10px',
    color: isActive ? Colors.Primary : Colors.Brand,
    transition: 'color .2s ease-in-out',
    cursor: 'pointer',

    ...(!isMobile
      ? {
          [':hover']: {
            color: isActive ? Colors.Grey400 : Colors.LinkHover
          }
        }
      : void 0)
  });

export const userSection = css({
  label: 'main-page__user-section',
  display: 'flex',
  alignItems: 'center',
  position: 'relative'
});

export const userName = css({
  label: 'main-page__user-name',
  maxWidth: '180px'
});

export const userSettings = css({
  label: 'main-page__user-settings',
  marginTop: '15px'
});

export const contentWrapper = (isMobile: boolean): string =>
  css({
    label: 'main-page__content-wrapper',
    minHeight: 0,
    position: 'relative',
    flexGrow: 1,
    paddingTop: isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP,
    overflowY: 'auto',
    scrollbarGutter: 'stable both-edges',

    ['@supports (overflow-y: overlay)']: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      overflowY: 'overlay' as any
    }
  });

export const content = (isMobile: boolean): string =>
  css({
    label: 'main-page__content',
    width: '100%',
    maxWidth: isMobile ? '100%' : '950px',
    margin: isMobile ? '30px 0' : '60px auto',
    padding: isMobile ? '0 20px' : '0 40px',
    flexGrow: 1
  });
