import { Button } from '@t15-ui-kit/button';
import { Footer } from '@t15-ui-kit/layoutFull';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { propagateError } from 'common/utils/processing/propagate-error';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useCallback, useContext } from 'react';

export function AssignMeetingToCourierActionFooter(): JSX.Element {
  const {
    forceLoadList,
    assignToCourier: {
      store: { comment$ },
      assignToCourier
    }
  } = useContext(MeetingSingleCompositionContext);

  const [isLoading, setLoading] = useSafeState(false);
  const comment = useRxBind(comment$);

  const handleClick = useCallback((): void => {
    setLoading(true);

    void assignToCourier()
      .then((): void => {
        forceLoadList(MEETING_LIST_TYPE.MY);
        coreModalRouting.goToRoot();
        coreRootRouting.goToMeetings();
      })
      .catch(propagateError)
      .then((): void => setLoading(false));
  }, [forceLoadList, assignToCourier, setLoading]);

  return (
    <Footer>
      <Button disabled={!comment} onClick={handleClick} isLoading={isLoading}>
        Передать курьеру
      </Button>
    </Footer>
  );
}
