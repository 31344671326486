import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { EditSpecialMeetingRequestModel, MeetingDataModel, TimeSlotModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { ScheduleMeetings } from 'common/utils/business/schedule-meetings';
import { propagateError } from 'common/utils/processing/propagate-error';
import dayjs from 'dayjs';
// TODO в общие утилиты?
import { SpecialDatetimeStore } from 'modules/special/views/single/stores/special-datetime.store';

export class SpecialDatetimeService implements IRevertable, IDestroyable {
  readonly store: SpecialDatetimeStore;

  readonly #data: MeetingDataModel;

  constructor(data: MeetingDataModel) {
    this.#data = data;

    this.store = new SpecialDatetimeStore(this.#data);
  }

  getAvailableDateSlots = (): Promise<Array<dayjs.Dayjs>> => {
    return new Promise((resolve: (data: Array<dayjs.Dayjs>) => void): void =>
      resolve(ScheduleMeetings.getDateSlotsSchedule())
    );
  };

  getAvailableTimeSlots = (day: dayjs.Dayjs): Promise<Array<TimeSlotModel>> => {
    return new Promise((resolve: (data: Array<TimeSlotModel>) => void): void =>
      resolve(ScheduleMeetings.getTimeSlotsSchedule(day))
    );
  };

  saveDatetime = (): Promise<void> => {
    return limeTransport
      .editSpecialMeeting(
        new EditSpecialMeetingRequestModel({
          id: this.#data.id,
          date: this.store.date,
          startAt: this.store.time
        })
      )
      .then((): void => this.store.commit());
  };

  revert(): void {
    this.store.revert();
  }

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
  }
}
