import { AddressGranularType, AddressSuggestion, TipAddressResponse } from '@svc_bobr/client';
import { IAutocompleteFieldElementType } from '@t15-ui-kit/autocompleteField';
import { CityStatus, TCityModel } from 'common/models/rpc/lime';
import { bobrTransport } from 'common/transport/bobr';
import { limeTransport } from 'common/transport/lime';
import { formatTimezone } from 'common/utils/formatters/format-timezone';
import { propagateError } from 'common/utils/processing/propagate-error';
import { CityListStore } from '../stores/city-list.store';

const CITY_COUNT = 40;

export class CityListComposition {
  readonly store: CityListStore = new CityListStore();

  loadCityList = (): void => {
    this.store.list = undefined;
    void limeTransport
      .getCityList()
      .then((cityList: Array<TCityModel>) => {
        this.store.list = cityList;
      })
      .catch(propagateError);
  };

  suggestCity = (query: string): Promise<Array<IAutocompleteFieldElementType>> => {
    return bobrTransport
      .tipAddress({
        query,
        count: CITY_COUNT,
        fromBound: { value: AddressGranularType.CITY },
        toBound: { value: AddressGranularType.CITY }
      })
      .then((response: TipAddressResponse): Array<IAutocompleteFieldElementType> => {
        return response.suggestions.map(
          (suggestion: AddressSuggestion): IAutocompleteFieldElementType => ({
            title: suggestion.data.city ?? '',
            id: suggestion.data.city ?? ''
          })
        );
      })
      .catch(() => {
        return [];
      });
  };

  createCity = async (query: string): Promise<void> => {
    try {
      const rawTimezone = await bobrTransport.cleanTimezone({ query });
      const timezone = formatTimezone(rawTimezone ?? '');
      return await limeTransport.addCity({ name: query, meetingLimit: 5, timezone, status: CityStatus.INACTIVE });
    } catch (err) {
      propagateError(err as Error);
    }
  };

  reset = (): void => {
    this.store.reset();
  };
}

export const cityListComposition = new CityListComposition();
