import { Toolbar } from '@t15-ui-kit/toolbar';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { useCallback, useContext } from 'react';

export function CommentsForSpecialMeetingActionFooter(): JSX.Element {
  const {
    data: { status },
    store
  } = useContext(SpecialSingleCompositionContext);

  const handleClickAddComment = useCallback((): void => {
    store.action = SPECIAL_SINGLE_ACTION.ADD_COMMENT_FOR_SPECIAL_MEETING;
  }, [store]);

  if (status !== MEETING_STATUS.DRAFT) {
    return <></>;
  }

  return <Toolbar onClick={handleClickAddComment} title="Добавить комментарий" />;
}
