import { ICommitable } from 'common/interfaces/commitable.interface';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { BehaviorSubject } from 'rxjs';

export class SpecialAssignToSellerStore implements ICommitable, IRevertable, IDestroyable {
  #defaultSellerId: NotRequired<number> = void 0;

  readonly sellerId$: BehaviorSubject<NotRequired<number>>;

  get sellerId(): NotRequired<number> {
    return this.sellerId$.getValue();
  }

  set sellerId(sellerId: NotRequired<number>) {
    this.sellerId$.next(sellerId);
  }

  constructor({ seller }: MeetingDataModel) {
    this.sellerId$ = new BehaviorSubject<NotRequired<number>>(seller?.id);
  }

  commit(): void {
    this.#defaultSellerId = this.sellerId;
  }

  revert(): void {
    this.sellerId = this.#defaultSellerId;
  }

  destroy(): void {
    this.sellerId$.complete();
  }
}
