import { Button } from '@t15-ui-kit/button';
import { Checkbox } from '@t15-ui-kit/checkbox';
import { DControl, DControlGroup } from '@t15-ui-kit/dControl';
import { Modal } from '@t15-ui-kit/modal';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { MEETING_STATUS_TITLE_DICTIONARY } from 'common/dictionaries/meeting-status.dictionary';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { isDeepEqual } from 'common/utils/features/is-deep-equal';
import { useCallback, useMemo, useState } from 'react';

type TMeetingStatusSelectPopupProps = {
  currentStatuses?: Array<MEETING_STATUS>;
  onSelect(statuses: Array<MEETING_STATUS>): void;
};

export function MeetingStatusSelectPopup({
  currentStatuses = [],
  onSelect
}: TMeetingStatusSelectPopupProps): JSX.Element {
  const [statuses, setStatuses] = useState<Array<MEETING_STATUS>>(currentStatuses);

  const isDisabled = useMemo(
    () => isDeepEqual([...statuses].sort(), [...currentStatuses].sort()),
    [statuses, currentStatuses]
  );

  const handleSelect = useCallback(() => {
    onSelect(statuses);
    uiKitService.closePopup();
  }, [onSelect, statuses]);

  return (
    <Modal
      headerText="Статус встречи"
      singleButton={
        <Button onClick={handleSelect} disabled={isDisabled}>
          Сохранить
        </Button>
      }
      onClose={uiKitService.closePopup}
    >
      <DControlGroup>
        {Object.entries(MEETING_STATUS_TITLE_DICTIONARY).map(([status, title]: [string, string]) => {
          const isChecked = statuses.includes(status as MEETING_STATUS);

          return (
            <DControl key={status}>
              <Checkbox
                onChange={() =>
                  setStatuses(
                    isChecked
                      ? statuses.filter((iterableStatus: MEETING_STATUS) => iterableStatus !== status)
                      : [...statuses, status as MEETING_STATUS]
                  )
                }
                checked={isChecked}
                switch
                right
              >
                {title}
              </Checkbox>
            </DControl>
          );
        })}
      </DControlGroup>
    </Modal>
  );
}
