import { MeetingDateSelectRow } from 'common/components/ui/meeting-date-select-row';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import dayjs from 'dayjs';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useCallback, useContext, useEffect } from 'react';

export function AddressAndDatetimeOfMeetingActionDate(): JSX.Element {
  const {
    addressAndDatetime: { store, getAvailableDateSlots }
  } = useContext(MeetingSingleCompositionContext);

  const date = useRxBind(store.date$);

  const [dateSlots, setDateSlots] = useSafeState<NotRequired<Array<dayjs.Dayjs>>>(void 0);
  useEffect((): void => {
    void getAvailableDateSlots().then(setDateSlots);
  }, [getAvailableDateSlots, setDateSlots]);

  const handleSelect = useCallback(
    (nextDate: dayjs.Dayjs) => {
      store.date = nextDate;
      store.time = void 0;
    },
    [store]
  );

  return <MeetingDateSelectRow date={date} dateSlots={dateSlots} onSelect={handleSelect} />;
}
