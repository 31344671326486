import { ActionSheet, ActionSheetRow } from '@t15-ui-kit/actionSheet';
import { Check } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Check';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { useCallback } from 'react';

type TViewMeetingActionConfirmSheetProps = {
  isOpen: boolean;
  onConfirm(): Promise<void>;
  onCloseRequest(): void;
};

export function ViewMeetingActionConfirmSheet({
  isOpen,
  onConfirm,
  onCloseRequest
}: TViewMeetingActionConfirmSheetProps): JSX.Element {
  const [isLoading, setLoading] = useSafeState(false);

  const handleClickConfirm = useCallback((): void => {
    setLoading(true);

    onConfirm().finally((): void => {
      setLoading(false);
      onCloseRequest();
    });
  }, [onConfirm, onCloseRequest, setLoading]);

  return (
    <ActionSheet text={'Встреча пропадет из\xA0списка и\xA0станет недоступна'} onClose={onCloseRequest} isOpen={isOpen}>
      <ActionSheetRow text="Завершить" icon={<Check />} onClick={handleClickConfirm} isLoading={isLoading} />
    </ActionSheet>
  );
}
