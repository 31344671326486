import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { EditSpecialMeetingRequestModel, MeetingCommentModel, MeetingDataModel } from 'common/models/rpc/lime';
import { DefiniteDispatcherService } from 'common/services/dispatcher';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import dayjs from 'dayjs';
import { SpecialCommentsStore } from 'modules/special/views/single/stores/special-comments.store';

export enum SPECIAL_COMMENTS_EVENT_TYPE {
  NEXT_COMMENT = 'next_comment'
}

export class SpecialCommentsService implements IRevertable, IDestroyable {
  readonly store: SpecialCommentsStore;
  readonly dispatcher = DefiniteDispatcherService.make<
    SPECIAL_COMMENTS_EVENT_TYPE,
    { [SPECIAL_COMMENTS_EVENT_TYPE.NEXT_COMMENT]: MeetingCommentModel }
  >(Object.values(SPECIAL_COMMENTS_EVENT_TYPE));

  readonly #data: MeetingDataModel;

  constructor(data: MeetingDataModel) {
    this.#data = data;

    this.store = new SpecialCommentsStore();
  }

  saveDetails = (): Promise<void> => {
    const comment = this.store.comment;
    if (!comment) {
      return Promise.resolve();
    }

    return limeTransport
      .editSpecialMeeting(
        new EditSpecialMeetingRequestModel({
          id: this.#data.id,
          comment
        })
      )
      .then((): void => {
        this.dispatcher.dispatchNextComment(
          new MeetingCommentModel({
            value: comment,
            createdAt: dayjs()
          })
        );

        this.store.comment = void 0;
      });
  };

  revert(): void {
    this.store.revert();
  }

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
  }
}
