import { Colors, Shadows } from '@t15-ui-kit/enums';
import { hoverOpacity } from '@t15-ui-kit/utils';
import { css } from 'emotion';

export const root = css({
  height: '100%',
  padding: '15px 0'
});

export const appLink = css(hoverOpacity, {
  display: 'block',
  textAlign: 'center',
  padding: '0 15px'
});

const qrCodeDesktopSizePx = 200;
const qrCodeMobileSizePx = 240;

const qrCode = css({
  backgroundColor: Colors.Default,
  boxSizing: 'border-box',
  padding: 15,
  margin: '0 auto',

  '& > img': {
    width: '100%',
    height: '100%'
  }
});

export const qrCodeDesktop = css(qrCode, {
  borderRadius: '20px',
  boxShadow: Shadows.Popout,
  width: qrCodeDesktopSizePx,
  height: qrCodeDesktopSizePx,
  marginTop: 15
});

export const qrCodeMobile = css(qrCode, {
  borderRadius: '10px',
  boxShadow: Shadows.Card,
  width: qrCodeMobileSizePx,
  height: qrCodeMobileSizePx
});

export const downloadButtonLink = css({
  display: 'block',
  color: Colors.Default,
  textDecoration: 'none'
});
