import { AddCommentForMeetingActionComment } from 'modules/meeting/views/single/components/actions/add-comment-for-meeting-action/add-comment-for-meeting-action-comment';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useContext, useEffect } from 'react';

export function AddCommentForMeetingAction(): JSX.Element {
  const { comments } = useContext(MeetingSingleCompositionContext);

  useEffect((): VoidFunction => {
    return (): void => {
      comments.revert();
    };
  }, [comments]);

  return (
    <>
      <AddCommentForMeetingActionComment />
    </>
  );
}
