import { Button } from '@t15-ui-kit/button';
import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { Illustration } from '@t15-ui-kit/illustrations';
import { useMobile } from '@t15-ui-kit/media';
import { Modal } from '@t15-ui-kit/modal';
import { Typography } from '@t15-ui-kit/typography';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Android } from '@tochka-modules/t15-ui-kit-icons/24/Social/Android';
import { Apple } from '@tochka-modules/t15-ui-kit-icons/24/Social/Apple';
import AndroidQrCode from 'assets/images/android-qr-code.png';
import IosQrCode from 'assets/images/ios-qr-code.png';
import * as styles from 'modules/meeting/views/list/components/sections/web-stub-of-meetings-section/web-stub-of-meetings-section.styles';
import { SyntheticEvent, useCallback } from 'react';

const iosLink =
  'itms-services://?action=download-manifest&url=https://mm.tochka.com/tochka-static/meeting/ios/manifest.plist';
const androidLink = 'https://rel.tochka.com/tochka_vstrechi';

export function WebStubOfMeetingsSection(): JSX.Element {
  const isMobile = useMobile();

  const handleClickApp = useCallback(
    (name: string, link: string, qrCodeImage: string) => {
      uiKitService.openPopup(
        <Modal
          singleButton={
            <Button>
              <a href={link} className={styles.downloadButtonLink}>
                Скачать приложение для {name}
              </a>
            </Button>
          }
          onClose={uiKitService.closePopup}
        >
          <div className={isMobile ? styles.qrCodeMobile : styles.qrCodeDesktop}>
            <img src={qrCodeImage} />
          </div>
          <CellEmpty height="30" />
          <Typography align="center" size="m" weight="400">
            Отсканируйте QR-код или нажмите на&nbsp;кнопку ниже
          </Typography>
        </Modal>,
        { onCloseRequest: uiKitService.closePopup, asSheetOnMobile: true }
      );
    },
    [isMobile]
  );

  return (
    <div className={styles.root}>
      <Illustration type="WrongTochka2" isClickToPauseDisabled={false} isPaused={false} isStopped={false} />
      <CellEmpty height="30" />
      <Typography align="center" size="l" weight="500">
        Функционал доступен только в&nbsp;мобильном приложении.
      </Typography>
      <CellEmpty height="40" />
      <Grid container wrap="nowrap" alignItems="center" justify="center" elementOffset={['none', 's']}>
        <Grid>
          <a
            href="#"
            className={styles.appLink}
            onClick={(event: SyntheticEvent) => {
              event.preventDefault();
              handleClickApp('iOS', iosLink, IosQrCode);
            }}
          >
            <Apple size="xl" color={ColorsBasicEnum.Brand} />
            <CellEmpty height="5" />
            <Typography color={ColorsBasicEnum.Primary} align="center" size="m" weight="400">
              iOS
            </Typography>
          </a>
        </Grid>
        <Grid>
          <a
            href="#"
            className={styles.appLink}
            onClick={(event: SyntheticEvent) => {
              event.preventDefault();
              handleClickApp('Android', androidLink, AndroidQrCode);
            }}
          >
            <Android size="xl" color={ColorsBasicEnum.Brand} />
            <CellEmpty height="5" />
            <Typography color={ColorsBasicEnum.Primary} align="center" size="m" weight="400">
              Android
            </Typography>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
