import { IEquality } from 'common/interfaces/equality.interface';

export class EqualityService {
  static equal<T extends IEquality>(a?: T, b?: T): boolean {
    if (!a?.equal || !b?.equal) {
      return a === b;
    }

    return a.equal(b);
  }

  static find<T extends IEquality>(array: Array<T>, item?: T): T | undefined {
    if (!item) {
      return void 0;
    }

    return array.find((iterableItem: T): boolean => EqualityService.equal(iterableItem, item));
  }

  static includes<T extends IEquality>(array: Array<T>, item?: T): boolean {
    if (!item) {
      return false;
    }

    return array.some((iterableItem: T): boolean => EqualityService.equal(iterableItem, item));
  }
}
