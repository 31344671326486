import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import * as styles from 'common/components/ui/main-page/main-page.styles';
import { noop } from 'common/utils/features/noop';
import { useCombinedRefs } from 'common/utils/hooks/use-combined-refs';
import { PropsWithChildren, RefObject, SyntheticEvent, useCallback, useEffect, useRef } from 'react';

type TMainPageProps = PropsWithChildren<{
  title: string;
  logo: string;
  user: JSX.Element;
  links?: JSX.Element;
  scrollableElementRef?: RefObject<HTMLElement>;
}>;

export function MainPage({ children, title, logo, user, links, scrollableElementRef }: TMainPageProps): JSX.Element {
  const isMobile = useMobile();

  const headerRef = useRef<HTMLElement>(null);
  const contentRef = useCombinedRefs<HTMLElement>(scrollableElementRef);
  const contentLastScrollTop = useRef<number>(0);

  const handleClickTitle = useCallback((event: SyntheticEvent): void => {
    if (contentRef.current) {
      if (contentRef.current.scrollTop > 0) {
        event.preventDefault();
        contentRef.current.scrollTo({ top: 0 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): VoidFunction => {
    if (!isMobile) {
      return noop;
    }

    const handleScrollContent = (): void => {
      if (!contentRef.current || !headerRef.current) {
        return;
      }

      const headerHeight = headerRef.current.offsetHeight;
      const contentScrollTop = contentRef.current.scrollTop;

      if (contentScrollTop > contentLastScrollTop.current) {
        if (contentScrollTop > headerHeight) {
          headerRef.current.classList.add(styles.headerOut);
        }
      } else {
        headerRef.current.classList.remove(styles.headerOut);
      }

      contentLastScrollTop.current = contentScrollTop;
    };

    contentRef.current?.addEventListener('scroll', handleScrollContent, { passive: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (): void => contentRef.current?.removeEventListener('scroll', handleScrollContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div className={styles.root}>
      <header ref={headerRef} className={styles.header(isMobile)}>
        <a href="/" key="title" className={styles.title} onClick={handleClickTitle}>
          <img className={styles.logo} src={logo} alt={title} />
          <Typography type="span" size="2xl" weight="700" color={ColorsBasicEnum.Primary}>
            {title}
          </Typography>
        </a>

        {!isMobile && !!links && (
          <div key="links-desktop" className={styles.linksDesktop}>
            {links}
          </div>
        )}

        <div key="user" className={styles.userSection}>
          {user}
        </div>
      </header>

      <main ref={contentRef} className={styles.contentWrapper(isMobile)}>
        <div className={styles.content(isMobile)}>{children}</div>
      </main>

      {isMobile && !!links && (
        <footer key="links-mobile" className={styles.linksMobile}>
          {links}
        </footer>
      )}
    </div>
  );
}
