import { ICommitable } from 'common/interfaces/commitable.interface';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { MeetingDataModel, TimeSlotModel } from 'common/models/rpc/lime';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

export class SpecialDatetimeStore implements ICommitable, IRevertable, IDestroyable {
  #defaultDate: NotRequired<dayjs.Dayjs>;

  readonly date$: BehaviorSubject<NotRequired<dayjs.Dayjs>>;

  get date(): NotRequired<dayjs.Dayjs> {
    return this.date$.getValue();
  }

  set date(dateTime: NotRequired<dayjs.Dayjs>) {
    this.date$.next(dateTime);
  }

  #defaultTime: NotRequired<TimeSlotModel>;

  readonly time$: BehaviorSubject<NotRequired<TimeSlotModel>>;

  get time(): NotRequired<TimeSlotModel> {
    return this.time$.getValue();
  }

  set time(time: NotRequired<TimeSlotModel>) {
    this.time$.next(time);
  }

  constructor({ date, time }: MeetingDataModel) {
    this.date$ = new BehaviorSubject<NotRequired<dayjs.Dayjs>>(date);
    this.#defaultDate = date;

    this.time$ = new BehaviorSubject<NotRequired<TimeSlotModel>>(time);
    this.#defaultTime = time;
  }

  commit(): void {
    this.#defaultDate = this.date;
    this.#defaultTime = this.time;
  }

  revert(): void {
    this.date = this.#defaultDate;
    this.time = this.#defaultTime;
  }

  destroy(): void {
    this.date$.complete();
    this.time$.complete();
  }
}
