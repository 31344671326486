import { Grid } from '@t15-ui-kit/grid/Grid';
import { MeetingsCardsGroupSkeleton } from 'common/components/ui/meetings-cards-group';
import { SpinnerAtCenter } from 'common/components/ui/spinner-at-center';
import { ScrollableElementRefContext } from 'common/contexts/scrollable-element-ref.context';
import { groupMeetings, TMeetingsGroup } from 'common/utils/business/group-meetings';
import { FORMAT_DATE_USE_DAY_TYPE, formatDate } from 'common/utils/formatters/format-date';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { EmptyListOfMeetings } from 'modules/meeting/views/list/components/sections/empty-list-of-meetings';
import { GroupOfMeetingsSection } from 'modules/meeting/views/list/components/sections/group-of-meetings-section';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

const INFINITE_SCROLL_THRESHOLD = 150;

export function ListOfMeetingsSection(): JSX.Element {
  const {
    isAllLoaded,
    store: { list$ },
    loadList
  } = useContext(MeetingListCompositionContext);

  const scrollableElementRef = useContext(ScrollableElementRefContext);

  const handleRequestScrollParent = useCallback(
    (): HTMLElement | null => scrollableElementRef.current,
    [scrollableElementRef]
  );

  const list = useRxBind(list$);
  const groups = useMemo(() => (list ? groupMeetings(list) : void 0), [list]);

  useEffect((): void => loadList(), [loadList]);

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={!isAllLoaded}
      loader={isAllLoaded || !groups ? void 0 : <SpinnerAtCenter key="loader" />}
      loadMore={loadList}
      threshold={INFINITE_SCROLL_THRESHOLD}
      getScrollParent={handleRequestScrollParent}
    >
      <Grid container direction="column" elementOffset={['xl', 'none']} wrap="nowrap">
        {groups ? (
          groups.length ? (
            groups.map((group: TMeetingsGroup): JSX.Element => {
              const date = group.date ? formatDate(group.date, FORMAT_DATE_USE_DAY_TYPE.WITH) : 'Не\xA0назначенные';

              return (
                <Grid key={date}>
                  <GroupOfMeetingsSection title={date} list={group.list} />
                </Grid>
              );
            })
          ) : (
            <EmptyListOfMeetings />
          )
        ) : (
          <Grid key="skeleton">
            <MeetingsCardsGroupSkeleton />
          </Grid>
        )}
      </Grid>
    </InfiniteScroll>
  );
}
