import { NonEnumerable } from 'common/utils/decorators/non-enumerable';
import { setErrorSerializable } from 'common/utils/processing/set-error-serializable';

/** Ошибка со ссылкой на базовую ошибку, на основе которой сгенерирована новая */
export class WithRawError<T extends Error = Error> extends Error {
  @NonEnumerable
  rawError?: T;

  constructor(rawError?: T, message?: string) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'WithRawError';
    this.rawError = rawError;
    this.message = message ?? rawError?.message ?? this.message;

    setErrorSerializable(this);
  }
}
