import { createRef, Ref, RefObject } from 'react';

/** Превращает ref, который может быть функцией, в ref-объект */
export function refToRefObject<T>(ref: Ref<T>): RefObject<T> {
  if (ref && typeof ref === 'object') {
    return ref;
  }

  if (ref && typeof ref === 'function') {
    let refValue: T | null = null;

    const refObject = {
      current: refValue
    };

    Object.defineProperty(refObject, 'current', {
      get(): T | null {
        return refValue;
      },
      set(this: RefObject<T>, value: T): void {
        refValue = value;
      }
    });

    return refObject;
  }

  return createRef<T>();
}
