import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { useMobile } from '@t15-ui-kit/media';
import { MeetingCardAddressAndTime } from 'common/components/ui/meeting-card/meeting-card-address-and-time';
import { MeetingCardCustomer } from 'common/components/ui/meeting-card/meeting-card-customer';
import { MeetingCardIcon } from 'common/components/ui/meeting-card/meeting-card-icon';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { memo, useCallback } from 'react';

type TMeetingCardProps = {
  data: MeetingDataModel;
  onClick(data: MeetingDataModel): void;
};

export const MeetingCard = memo(({ data, onClick }: TMeetingCardProps): JSX.Element => {
  const isMobile = useMobile();

  const handleClick = useCallback((): void => {
    onClick(data);
  }, [data, onClick]);

  return (
    <DCellLabel
      accLeft={<MeetingCardIcon data={data} isExpired={false} />}
      onClick={handleClick}
      offset={[isMobile ? 'xs' : 's', null]}
    >
      <MeetingCardAddressAndTime data={data} isExpired={false} />
      <CellEmpty height="5" />
      <MeetingCardCustomer data={data} />
    </DCellLabel>
  );
});
