import { TimeSlotModel } from 'common/models/rpc/lime/internal';
import { serializeDate } from 'common/utils/serializers/serialize-date';
import dayjs from 'dayjs';
import { JsonName, JsonStruct, serialize } from 'tserialize';

export class EditMeetingRequestModel {
  /** Идентификатор встречи */
  id!: number;

  @JsonName('date', serializeDate)
  date?: dayjs.Dayjs;

  @JsonStruct(TimeSlotModel, 'start_at')
  time?: TimeSlotModel;

  @JsonName('address')
  address?: string;

  @JsonName('assign')
  isAssigned?: boolean;

  @JsonName('finish')
  isFinished?: boolean;

  @JsonName('cancel')
  isCanceled?: boolean;

  @JsonName('comment')
  comment?: string;

  constructor(data: Omit<EditMeetingRequestModel, 'toServer'>) {
    Object.assign(this, data);
  }

  toServer(): object {
    return serialize(this);
  }
}
