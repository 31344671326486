import { ICommitable } from 'common/interfaces/commitable.interface';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { BehaviorSubject } from 'rxjs';

export class MeetingCommentsStore implements ICommitable, IRevertable, IDestroyable {
  #defaultComment: NotRequired<string> = '';

  readonly comment$: BehaviorSubject<NotRequired<string>> = new BehaviorSubject<NotRequired<string>>('');

  get comment(): NotRequired<string> {
    return this.comment$.getValue();
  }

  set comment(comment: NotRequired<string>) {
    this.comment$.next(comment);
  }

  commit(): void {
    this.#defaultComment = this.comment;
  }

  revert(): void {
    this.comment = this.#defaultComment;
  }

  destroy(): void {
    this.comment$.complete();
  }
}
