import Logo from 'assets/images/logo.svg';
import { DeferRedirect } from 'common/components/internal/defer-redirect';
import { Admin } from 'common/components/ui/admin';
import { MainPage } from 'common/components/ui/main-page';
import { ScrollableElementRefContext } from 'common/contexts/scrollable-element-ref.context';
import { CORE_ROOT_ROUTING_PATH } from 'core/enums/core-root-routing.enum';
import { MeetingList } from 'modules/meeting/views/list';
import { LinksRootSection } from 'modules/root/views/main/components/sections/links-root-section';
import { UserRootSection } from 'modules/root/views/main/components/sections/user-root-section';
import { Seller } from 'modules/seller/views/single/components';
import { SpecialList } from 'modules/special/views/list';
import { useRef } from 'react';
import { generatePath, Route, Switch } from 'react-router';

export function Root(): JSX.Element {
  const scrollableElementRef = useRef<HTMLElement>(null);

  return (
    <ScrollableElementRefContext.Provider value={scrollableElementRef}>
      <MainPage
        title="Встречи"
        logo={Logo}
        links={<LinksRootSection />}
        user={<UserRootSection />}
        scrollableElementRef={scrollableElementRef}
      >
        <Switch>
          <Route path={CORE_ROOT_ROUTING_PATH.MEETINGS} component={MeetingList} />
          <Route path={CORE_ROOT_ROUTING_PATH.SPECIAL} component={SpecialList} />
          <Route path={CORE_ROOT_ROUTING_PATH.SELLER} component={Seller} />
          <Route path={CORE_ROOT_ROUTING_PATH.ADMIN} component={Admin} />
          <DeferRedirect
            from={CORE_ROOT_ROUTING_PATH.ROOT}
            to={generatePath(CORE_ROOT_ROUTING_PATH.MEETINGS)}
            isExact
          />
        </Switch>
      </MainPage>
    </ScrollableElementRefContext.Provider>
  );
}
