import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { AddressAndDatetimeOfMeetingActionAddress } from 'modules/meeting/views/single/components/actions/address-and-datetime-of-meeting-action/address-and-datetime-of-meeting-action-address';
import { AddressAndDatetimeOfMeetingActionDate } from 'modules/meeting/views/single/components/actions/address-and-datetime-of-meeting-action/address-and-datetime-of-meeting-action-date';
import { AddressAndDatetimeOfMeetingActionTime } from 'modules/meeting/views/single/components/actions/address-and-datetime-of-meeting-action/address-and-datetime-of-meeting-action-time';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useContext, useEffect } from 'react';

export function AddressAndDatetimeOfMeetingAction(): JSX.Element {
  const { addressAndDatetime } = useContext(MeetingSingleCompositionContext);

  const isMobile = useMobile();

  useEffect((): VoidFunction => {
    return (): void => {
      addressAndDatetime.revert();
    };
  }, [addressAndDatetime]);

  return (
    <Grid container direction="column" elementOffset={[isMobile ? 's' : 'xl']} wrap="nowrap">
      <Grid>
        <AddressAndDatetimeOfMeetingActionAddress />
      </Grid>
      <Grid>
        <AddressAndDatetimeOfMeetingActionDate />
      </Grid>
      <Grid>
        <AddressAndDatetimeOfMeetingActionTime />
      </Grid>
    </Grid>
  );
}
