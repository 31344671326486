import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';

export class UploadMeetingPhotoRequestModel {
  /** Идентификатор встречи */
  id!: number;

  /** Файл */
  file!: File;

  /** Тип фото */
  type!: MEETING_PHOTO_TYPE;

  constructor(data: Omit<UploadMeetingPhotoRequestModel, 'toFormData'>) {
    Object.assign(this, data);
  }

  toFormData(): FormData {
    const formData = new FormData();

    formData.append('file', this.file);
    formData.append('type', this.type);

    return formData;
  }
}
