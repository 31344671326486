import { AddCommentForSpecialMeetingAction } from 'modules/special/views/single/components/actions/add-comment-for-special-meeting-action';
import { AssignSpecialMeetingToCourierAction } from 'modules/special/views/single/components/actions/assign-special-meeting-to-courier-action';
import { AssignSpecialMeetingToSellerAction } from 'modules/special/views/single/components/actions/assign-special-meeting-to-seller-action';
import { CommentsForSpecialMeetingAction } from 'modules/special/views/single/components/actions/comments-for-special-meeting-action';
import { DatetimeOfSpecialMeetingAction } from 'modules/special/views/single/components/actions/datetime-of-special-meeting-action';
import { ViewSpecialMeetingAction } from 'modules/special/views/single/components/actions/view-special-meeting-action';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { ComponentType } from 'react';

export const SPECIAL_SINGLE_ACTION_CONTENT_COMPONENT_DICTIONARY: Record<SPECIAL_SINGLE_ACTION, ComponentType> = {
  [SPECIAL_SINGLE_ACTION.VIEW_SPECIAL_MEETING]: ViewSpecialMeetingAction,
  [SPECIAL_SINGLE_ACTION.DATETIME_OF_SPECIAL_MEETING]: DatetimeOfSpecialMeetingAction,
  [SPECIAL_SINGLE_ACTION.COMMENTS_FOR_SPECIAL_MEETING]: CommentsForSpecialMeetingAction,
  [SPECIAL_SINGLE_ACTION.ADD_COMMENT_FOR_SPECIAL_MEETING]: AddCommentForSpecialMeetingAction,
  [SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_SELLER]: AssignSpecialMeetingToSellerAction,
  [SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_COURIER]: AssignSpecialMeetingToCourierAction
};
