import { Spinner } from '@t15-ui-kit/circleLoader';
import { ColorsShadesEnum } from '@t15-ui-kit/enums';
import classNames from 'classnames';
import { AutoCompleteOption } from 'common/components/ui/autocomplete/auto-complete-option';
import * as styles from 'common/components/ui/autocomplete/autocomplete.styles';
import { TAutoCompleteOption } from 'common/components/ui/autocomplete/autocomplete.type';
import { noop } from 'common/utils/features/noop';
import { refToRefObject } from 'common/utils/features/ref-to-ref-object';
import { ChangeEvent, Component, createRef, KeyboardEvent, Ref, RefObject } from 'react';

type TAutoCompleteProps<T extends TAutoCompleteOption> = {
  autoClose?: boolean;
  className?: string;
  value: string;
  placeholder?: string;
  debounce?: number;
  inputRef?: Ref<HTMLInputElement>;
  isMobile?: boolean;
  onFocus?(): void;
  onBlur?(): void;
  onChange(value: string): void;
  onSearchRequest(value: string): Promise<Array<T>>;
  onSelect(option: T): void;
};

type TAutoCompleteState<T extends TAutoCompleteOption> = {
  isLoading: boolean;
  isFocus: boolean;
  activeOption?: T;
  options: Array<T>;
};

const DEFAULT_DEBOUNCE = 300;
const EVENT_KEY_DEFAULT_PREVENTED = ['ArrowDown', 'ArrowUp', 'Enter'];

/** Инпут-автокомплит: по сути обычный инпут, но с выпадающими подсказками, которые подставляются асинхронно */
export class AutoComplete<T extends TAutoCompleteOption = TAutoCompleteOption> extends Component<
  TAutoCompleteProps<T>
> {
  state: TAutoCompleteState<T> = {
    isLoading: false,
    isFocus: false,
    options: []
  };

  #searchTimeout?: number;
  readonly #inputRef: RefObject<HTMLInputElement>;

  constructor(props: TAutoCompleteProps<T>) {
    super(props);

    this.#inputRef = this.props.inputRef ? refToRefObject(this.props.inputRef) : createRef<HTMLInputElement>();
  }

  componentDidMount(): void {
    document.addEventListener('click', this.#handleClickDocument);
  }

  componentWillUnmount(): void {
    !!this.#searchTimeout && window.clearTimeout(this.#searchTimeout);
    this.#searchTimeout = void 0;

    document.removeEventListener('click', this.#handleClickDocument);
  }

  componentDidUpdate(prevProps: TAutoCompleteProps<T>, prevState: TAutoCompleteState<T>): void {
    if (this.props.value !== prevProps.value) {
      !!this.#searchTimeout && window.clearTimeout(this.#searchTimeout);
      this.#searchTimeout = void 0;

      this.#searchTimeout = window.setTimeout(this.#search, this.props.debounce ?? DEFAULT_DEBOUNCE);
    }

    if (this.state.isFocus !== prevState.isFocus) {
      if (this.state.isFocus) {
        this.props.onFocus?.();
      } else {
        this.props.onBlur?.();
      }
    }
  }

  render(): JSX.Element {
    return (
      <div className={styles.root}>
        <input
          ref={this.#inputRef}
          className={classNames(this.props.className, styles.input)}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onFocus={this.#handleFocus}
          onChange={this.#handleChange}
          onKeyDown={this.#handleKeyDown}
        />

        {this.state.isLoading && (
          <div className={styles.spinner}>
            <Spinner color={ColorsShadesEnum.Grey400} size="s" />
          </div>
        )}

        {this.state.options.length > 0 && this.state.isFocus && (
          <div className={styles.options}>
            {this.state.options.map(
              (option: TAutoCompleteOption): JSX.Element => (
                <AutoCompleteOption
                  key={option.value}
                  option={option}
                  isActive={this.state.activeOption === option}
                  onClick={this.#select}
                />
              )
            )}
          </div>
        )}
      </div>
    );
  }

  #search = (): void => {
    this.setState({ isLoading: true });

    this.props
      .onSearchRequest(this.props.value)
      .then((options: Array<T>): void => this.setState({ options }))
      .catch(noop)
      .finally((): void => this.setState({ isLoading: false }));
  };

  #select = (option: T): void => {
    this.props.onSelect(option);

    if (this.props.autoClose) {
      this.#inputRef.current?.blur();
      this.setState({ isFocus: false });
    } else {
      this.#inputRef.current?.focus();
    }
  };

  #handleClickDocument = (event: MouseEvent): void => {
    const target = event.target as HTMLElement;
    if (!target?.closest?.(`.${styles.root}`)) {
      this.setState({ isFocus: false });
    }
  };

  #handleFocus = (): void => {
    this.setState({ isFocus: true });
  };

  #handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange(event.target.value);
  };

  #handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (EVENT_KEY_DEFAULT_PREVENTED.includes(event.key)) {
      event.preventDefault();
    }

    const { activeOption } = this.state;
    const activeOptionIndex = activeOption ? this.state.options.indexOf(activeOption) : -1;
    const lastOptionIndex = this.state.options.length - 1;

    if (event.key === 'Enter' && activeOption) {
      this.#select(activeOption);
    } else if (event.key === 'ArrowUp') {
      const prevOptionIndex = activeOptionIndex - 1 < 0 ? lastOptionIndex : activeOptionIndex - 1;

      this.setState({ activeOption: this.state.options[prevOptionIndex] });
    } else if (event.key === 'ArrowDown') {
      const nextOptionIndex = activeOptionIndex + 1 > lastOptionIndex ? 0 : activeOptionIndex + 1;

      this.setState({ activeOption: this.state.options[nextOptionIndex] });
    }
  };
}
