import { Space } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { LayoutFull } from '@t15-ui-kit/layoutFull';
import { Typography } from '@t15-ui-kit/typography';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { css } from 'emotion';
import { authorizationService } from 'modules/authorization/services/authorization.service';
import { OauthButton } from 'modules/authorization/views/main/components/oauth-button';
import { PartnerLoginButton } from 'modules/authorization/views/main/components/partner-login-button';
import { useLayoutEffect } from 'react';

const buttons = css({
  maxWidth: '100%'
});

const buttonsWrapper = css({
  label: 'auth-buttons-wrapper',
  display: 'flex',
  flexDirection: 'column',
  gap: Space.s,

  '& button': {
    whiteSpace: 'nowrap',

    '& [class*="button-text"]': {
      overflow: 'visible',
      textOverflow: 'clip'
    }
  }
});

export function Authorization(): JSX.Element {
  useLayoutEffect((): void => {
    void authorizationService.isAuthorized().then((isAuthorized: boolean): void => {
      if (isAuthorized) {
        coreRootRouting.goToRoot();
      }
    });
  }, []);

  return (
    <LayoutFull isCenterContent>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        wrap="nowrap"
        elementOffset={['4xl', 'none']}
      >
        <Grid>
          <Typography size="5xl" weight="700">
            Вход
          </Typography>
        </Grid>

        <Grid className={buttons}>
          <div className={buttonsWrapper}>
            <OauthButton onClick={authorizationService.loginViaOauth} />
            <PartnerLoginButton onClick={authorizationService.loginAsPartner} />
          </div>
        </Grid>
      </Grid>
    </LayoutFull>
  );
}
