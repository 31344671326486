import { deserializeDatetime } from 'common/utils/serializers/deserialize-datetime';
import dayjs from 'dayjs';
import { JsonNameReadonly } from 'tserialize';

export class MeetingCommentModel {
  @JsonNameReadonly('value')
  value!: string;

  @JsonNameReadonly('created_at', deserializeDatetime)
  createdAt?: dayjs.Dayjs;

  constructor(data: MeetingCommentModel) {
    Object.assign(this, data);
  }
}
