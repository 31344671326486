import { UiKitPreferences, UiKitPreferencesContextProps, ZindexContext } from '@t15-ui-kit/providers';
import { withUiKit } from '@t15-ui-kit/withUiKit';
import { ComponentType, createElement, Fragment } from 'react';

const Z_INDEX = 99999;

type TWithUiKitEnvironmentProps = {
  url: string;
};

const WithUiKitService = withUiKit<{ children?: React.ReactNode }>(Fragment);

export function withUiKitEnvironment<T>(
  component: ComponentType<T>,
  { url }: TWithUiKitEnvironmentProps
): ComponentType<T> {
  const uiKitPreferences: UiKitPreferencesContextProps = {
    lottiesOutsideUrl: `${url}/static/lotties`,
    staticUrl: `${url}/static`
  };

  const withUiKitEnvironmentComponent = (props: T): JSX.Element => {
    return (
      <UiKitPreferences.Provider value={uiKitPreferences}>
        <ZindexContext.Provider value={Z_INDEX}>
          <WithUiKitService>{createElement(component, props)}</WithUiKitService>
        </ZindexContext.Provider>
      </UiKitPreferences.Provider>
    );
  };

  withUiKitEnvironmentComponent.displayName = `${component.displayName ?? component.name}WithUiKitEnvironment`;

  return withUiKitEnvironmentComponent;
}
