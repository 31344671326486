import { IDispatcherService } from 'common/services/dispatcher/dispatcher.interface';
import { DispatcherService } from 'common/services/dispatcher/impl/dispatcher.service';
import { CASE_TYPE, CaseFormatter } from 'common/utils/formatters/case-formatter';

type TDefiniteDispatcherService<T extends string, S extends Record<T, unknown>> = {
  [K in T as `listen${Capitalize<Camelize<T>>}`]: (listener: (detail: S[T]) => void) => VoidFunction;
} & {
  [K in T as `dispatch${Capitalize<Camelize<T>>}`]: (detail: S[T]) => void;
};

export class DefiniteDispatcherService extends DispatcherService implements IDispatcherService {
  protected constructor() {
    super();
  }

  static make<T extends string, S extends Record<T, unknown>>(
    types: Array<T>
  ): DefiniteDispatcherService & TDefiniteDispatcherService<T, S> {
    const service = new DefiniteDispatcherService();

    types.forEach((type: T): void => {
      const camelizedType = CaseFormatter.format(CASE_TYPE.SNAKE_CASE, CASE_TYPE.CAMEL_CASE, type);
      const capitalizedType = `${camelizedType[0].toUpperCase()}${camelizedType.slice(1)}`;

      Object.defineProperty(service, `listen${capitalizedType}`, {
        value: (listener: (detail: S[T]) => void): VoidFunction => {
          return service.listen(type, (event: CustomEvent): void => listener(event.detail));
        }
      });

      Object.defineProperty(service, `dispatch${capitalizedType}`, {
        value: (detail: S[T]): void => {
          service.dispatch(new CustomEvent(type, { detail }));
        }
      });
    });

    return service as unknown as DefiniteDispatcherService & TDefiniteDispatcherService<T, S>;
  }
}
