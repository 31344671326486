import { MeetingCard } from 'common/components/ui/meeting-card';
import { MeetingsCardsGroup } from 'common/components/ui/meetings-cards-group';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { useCallback } from 'react';

type TGroupOfSpecialMeetingsSectionProps = {
  title: string;
  list: Array<MeetingDataModel>;
};

export function GroupOfSpecialMeetingsSection({ title, list }: TGroupOfSpecialMeetingsSectionProps): JSX.Element {
  const handleClick = useCallback((data: MeetingDataModel) => {
    coreModalRouting.goToSpecial({ id: data.id.toString() });
  }, []);

  return (
    <MeetingsCardsGroup title={title}>
      {list.map(
        (data: MeetingDataModel): JSX.Element => (
          <MeetingCard key={data.id} data={data} onClick={handleClick} />
        )
      )}
    </MeetingsCardsGroup>
  );
}
