import { LayoutFull } from '@t15-ui-kit/layoutFull';
import { Typography } from '@t15-ui-kit/typography';
import { MEETING_STATUS_TITLE_DICTIONARY } from 'common/dictionaries/meeting-status.dictionary';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { setTitle } from 'common/utils/features/set-title';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION_CONTENT_COMPONENT_DICTIONARY } from 'modules/meeting/views/single/dictionary/meeting-single-action-content-component.dictionary';
import { MEETING_SINGLE_ACTION_FOOTER_COMPONENT_DICTIONARY } from 'modules/meeting/views/single/dictionary/meeting-single-action-footer-component.dictionary';
import { MEETING_SINGLE_ACTION_TITLE_DICTIONARY } from 'modules/meeting/views/single/dictionary/meeting-single-action-title.dictionary';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { createElement, memo, useCallback, useContext, useEffect } from 'react';

export const DashboardOfSingleMeeting = memo((): JSX.Element => {
  const {
    store,
    data: {
      customer: { companyName }
    },
    addressAndDatetime: {
      store: { date$, time$ }
    }
  } = useContext(MeetingSingleCompositionContext);

  const status = useRxBind(store.status$);
  const action = useRxBind(store.action$);
  const date = useRxBind(date$);
  const time = useRxBind(time$);
  const isView = action === MEETING_SINGLE_ACTION.VIEW_MEETING;

  let title: string = companyName;
  if (!isView) {
    title = MEETING_SINGLE_ACTION_TITLE_DICTIONARY[action];
  }

  let subtitle: string = companyName;
  if (isView) {
    if (status === MEETING_STATUS.DRAFT) {
      subtitle = date && time ? 'Встреча назначена' : 'Не назначена';
    } else {
      subtitle = MEETING_STATUS_TITLE_DICTIONARY[status];
    }
  }

  useEffect((): VoidFunction => {
    setTitle(companyName);

    return (): void => setTitle.restore();
  }, [companyName]);

  const handleBackRequest = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.VIEW_MEETING;
  }, [store]);

  const footerComponent = MEETING_SINGLE_ACTION_FOOTER_COMPONENT_DICTIONARY[action];
  const contentComponent = MEETING_SINGLE_ACTION_CONTENT_COMPONENT_DICTIONARY[action];

  return (
    <LayoutFull
      backgroundColor={isView ? 'BgrPrimary' : 'Default'}
      headerNode={
        <Typography size="m" weight="500" align="center">
          {title}
        </Typography>
      }
      footerNode={footerComponent ? createElement(footerComponent) : void 0}
      subtitle={subtitle}
      backAction={!isView ? handleBackRequest : void 0}
      closeAction={coreModalRouting.goToRoot}
    >
      {createElement(contentComponent)}
    </LayoutFull>
  );
});
