import { useMobile } from '@t15-ui-kit/media';
import { AllColors } from '@t15-ui-kit/types';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import * as styles from 'common/components/ui/like-navigator/like-navigator.styles';
import { cloneElement, ReactElement } from 'react';

export type TLikeNavigatorActionProps = {
  icon: ReactElement<IIconProps>;
  onClick(): void;
};

export function LikeNavigatorAction({ icon, onClick }: TLikeNavigatorActionProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <div className={styles.action(isMobile)} onClick={onClick}>
      {cloneElement(icon, { size: 'l', color: 'currentColor' as AllColors })}
    </div>
  );
}
