import { useState, useCallback, ChangeEvent } from 'react';
import { Modal } from '@t15-ui-kit/modal';
import { Button } from '@t15-ui-kit/button';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Typography } from '@t15-ui-kit/typography';
import { DControl } from '@t15-ui-kit/dControl';
import { TCityModel } from 'common/models/rpc/lime';
import { useMobile } from '@t15-ui-kit/media';

type TEditCityPopupProps = { city: TCityModel; handleEdit: (f: string, v: unknown) => void };

export const EditCityPopup = ({ city, handleEdit }: TEditCityPopupProps): JSX.Element => {
  const isMobile = useMobile();
  const [value, setValue] = useState(city.meetingLimit);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  }, []);

  return (
    <Modal
      headerText="Редактировать город"
      singleButton={
        <Button disabled={value === city.meetingLimit} onClick={() => handleEdit('meetingLimit', value)}>
          Изменить
        </Button>
      }
      secondButton={<Button onClick={() => uiKitService.closePopup()}>Закрыть</Button>}
    >
      <Typography type="h4">Лимит встреч</Typography>
      <DControl type="input" borderRadius="l" isMobile={isMobile} isMobileBottomOffset={false}>
        <input type="number" onChange={handleInputChange} placeholder="Электронная почта" value={value} />
      </DControl>
    </Modal>
  );
};
