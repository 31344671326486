/* eslint-disable @typescript-eslint/no-magic-numbers */

import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';
import React, { useCallback, useState } from 'react';
import { animated, useSpring } from 'react-spring-new';

const absolute = css({
  label: `absolute`,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate3d(-50%, -50%, 0) rotate(-90deg)',
  zIndex: 2
});

const config: Record<'mobile' | 'desktop', { size: number; halfSize: number; radius: number }> = {
  mobile: {
    size: 42,
    halfSize: 42 / 2,
    radius: 20
  },
  desktop: {
    size: 30,
    halfSize: 30 / 2,
    radius: 13
  }
};

export const CircleProgress = React.memo(
  ({
    value = 0,
    isMobile = false,
    color = 'Brand'
  }: {
    value?: number;
    isMobile?: boolean;
    color?: 'Brand' | 'Default';
  }) => {
    const [totalLength, setTotalLength] = useState(0);
    const { strokeDashoffset } = useSpring({
      immediate: value === 0 || totalLength === 0,
      from: { strokeDashoffset: 0 },
      strokeDashoffset: totalLength - value * totalLength * 0.01
    });

    const { size, halfSize, radius } = isMobile ? config.mobile : config.desktop;

    const callbackRef = useCallback((node: SVGCircleElement | null) => {
      if (
        node !== null &&
        /**
         * Тесты упадут без этой проверки
         * @see https://github.com/jsdom/jsdom/issues/1330
         * Решения элегантней не придумал
         */
        typeof node.getTotalLength === 'function'
      ) {
        setTotalLength(node.getTotalLength());
      }
    }, []);

    return (
      <svg
        className={absolute}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.circle
          ref={callbackRef}
          r={radius}
          cx={halfSize}
          cy={halfSize}
          stroke={Colors[color]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray={totalLength}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    );
  }
);
