export function stripSlashes(value: string): string {
  if (!value) {
    return value;
  }

  let retVal = value;
  if (retVal[0] === '/') {
    retVal = retVal.slice(1);
  }

  if (retVal[retVal.length - 1] === '/') {
    retVal = retVal.slice(0, retVal.length - 1);
  }

  return retVal;
}
