import { DChip } from '@t15-ui-kit/dChip';
import { Grid } from '@t15-ui-kit/grid';
import { UserGuard } from 'common/components/ui/user-guard';
import { setTitle } from 'common/utils/features/set-title';
import { CORE_ROOT_ROUTING_PATH } from 'core/enums/core-root-routing.enum';
import { UserRight } from 'core/enums/user-right.enum';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { CityList } from 'modules/city/views/list/components';
import { SellerList } from 'modules/seller/views/list/components';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Dashboard } from '../dashboard';

const TITLE = 'Админка';

function AdminInternal(): JSX.Element {
  useEffect(() => {
    setTitle(TITLE);

    return (): void => {
      setTitle.restore();
    };
  }, []);

  return (
    <Dashboard title={TITLE}>
      <Grid container direction="row" elementOffset="s">
        <Grid item>
          <DChip
            isSelected={coreRootRouting.isSellers}
            text="Пользователи"
            onClick={() => coreRootRouting.goToSellers()}
          />
        </Grid>
        <Grid item>
          <DChip text="Города" onClick={() => coreRootRouting.goToCities()} isSelected={coreRootRouting.isCities} />
        </Grid>
      </Grid>
      <Switch>
        <Route path={CORE_ROOT_ROUTING_PATH.SELLERS} component={SellerList} exact />
        <Route path={CORE_ROOT_ROUTING_PATH.CITIES} component={CityList} exact />
      </Switch>
    </Dashboard>
  );
}

export function Admin(): JSX.Element {
  return (
    <UserGuard right={UserRight.ADMIN}>
      <AdminInternal />
    </UserGuard>
  );
}
