import { UserModel } from 'common/models/rpc/lime/internal';
import { deserialize, JsonMeta } from 'tserialize';

export class GetUserResponseModel {
  @JsonMeta(UserModel)
  user!: UserModel;

  static fromServer(data: object): GetUserResponseModel {
    return deserialize(data, GetUserResponseModel);
  }
}
