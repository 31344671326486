export function isDeepEqual<T>(objA: T, objB: T, depth: number = 1): boolean {
  function _isDeepEqual(obj1: unknown, obj2: unknown, currentDepth: number): boolean {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 !== 'object') {
      return Object.is(obj1, obj2);
    }
    if (obj1 === null || obj2 === null) return false;
    const props = Object.getOwnPropertyNames(obj1);
    const propsOther = Object.getOwnPropertyNames(obj2);
    if (props.length !== propsOther.length) return false;
    if (!props.every((key: string) => propsOther.includes(key))) return false;

    const record1 = obj1 as Record<string, unknown>;
    const record2 = obj2 as Record<string, unknown>;

    if (currentDepth > depth) return false;

    return props.every((key: string) => _isDeepEqual(record1[key], record2[key], currentDepth + 1));
  }

  return _isDeepEqual(objA, objB, 0);
}
