/** Делает ошибку сериалуземой: просто делает message enumerable */
export function setErrorSerializable(error: Error): void {
  const descriptor = Object.getOwnPropertyDescriptor(error, 'message');

  if (descriptor?.configurable) {
    // На всякий случай стоит оберечься от ошибок, хотя их и не должно быть
    try {
      Object.defineProperty(error, 'message', { ...descriptor, enumerable: true });
    } catch (e) {
      return;
    }
  }
}
