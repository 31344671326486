import { RoutingService } from 'common/services/routing/impl/routing.service';
import { ROUTING_TYPE } from 'common/services/routing/routing.enum';
import { IRoutingService } from 'common/services/routing/routing.interface';
import { CASE_TYPE, CaseFormatter } from 'common/utils/formatters/case-formatter';

type TDefiniteRoutingKnownRoutes<T extends string = string> = Record<string, T>;

type TDefiniteRoutingService<
  R extends TDefiniteRoutingKnownRoutes = TDefiniteRoutingKnownRoutes,
  S extends object = object
> = {
  [K in keyof R as `goTo${Capitalize<Camelize<string & K>>}`]: (
    params?: Record<string, string>,
    query?: URLSearchParams,
    state?: S
  ) => void;
} & {
  [K in keyof R as `is${Capitalize<Camelize<string & K>>}`]: boolean;
};

export class DefiniteRoutingService<T extends ROUTING_TYPE, S extends object = object>
  extends RoutingService<T, S>
  implements IRoutingService<S>
{
  protected constructor(type: T, basename: string = '/') {
    super(type, basename);
  }

  static make<
    T extends ROUTING_TYPE,
    R extends TDefiniteRoutingKnownRoutes = TDefiniteRoutingKnownRoutes,
    K extends string = R extends Record<string, infer TT> ? TT : never,
    S extends object = object
  >(type: T, knownRoutes: R, basename: string = '/'): DefiniteRoutingService<T, S> & TDefiniteRoutingService<R, S> {
    const service = new DefiniteRoutingService<T, S>(type, basename);

    Object.entries(knownRoutes).forEach(([key, value]: [string, string]): void => {
      const camelizedKey = CaseFormatter.format(CASE_TYPE.SNAKE_CASE, CASE_TYPE.CAMEL_CASE, key);
      const capitalizedKey = `${camelizedKey[0].toUpperCase()}${camelizedKey.slice(1)}`;

      Object.defineProperty(service, `goTo${capitalizedKey}`, {
        value: (...args: [Record<string, string>, S]): unknown => {
          return service.goTo(value as K, ...args);
        }
      });

      Object.defineProperty(service, `is${capitalizedKey}`, {
        get: (): boolean => {
          return service.is(value as K);
        }
      });
    });

    return service as unknown as DefiniteRoutingService<T, S> & TDefiniteRoutingService<R, S>;
  }
}
