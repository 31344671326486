import { Typography } from '@t15-ui-kit/typography';
import classNames from 'classnames';
import * as styles from 'common/components/ui/drop-down-menu/drop-down-menu.styles';
import { ReactNode } from 'react';

type TDropDownMenuContentProps = {
  className?: string;
  children: ReactNode;
  header?: ReactNode;
};

export function DropDownMenuContent({ className, children, header }: TDropDownMenuContentProps): JSX.Element {
  return (
    <div className={classNames(styles.content, className)}>
      {!!header && (
        <div className={styles.header}>
          <Typography type="span" size="m" weight="500">
            {header}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
