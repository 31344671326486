import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  label: 'single-file-loader'
});

export const uploadCardActive = css({
  label: 'single-file-loader__upload-card--active'
});

export const uploadCard = (isMobile: boolean): string =>
  css({
    label: 'single-file-loader__upload-card',
    cursor: 'pointer',
    border: isMobile ? 0 : `1.5px dashed ${Colors.Grey300}`,
    borderRadius: isMobile ? 0 : '10px',
    height: isMobile ? 'auto' : '240px',
    padding: isMobile ? '10px 0' : 0,
    display: 'flex',
    alignItems: isMobile ? 'flex-start' : 'center',
    justifyContent: isMobile ? 'flex-start' : 'center',
    flexDirection: isMobile ? 'row' : 'column',
    columnGap: isMobile ? '15px' : 0,

    [`:hover, &.${uploadCardActive}`]: {
      borderColor: Colors.Brand
    }
  });

export const uploadCardText = (isMobile: boolean): string =>
  css({
    label: 'single-file-loader__upload-card-text',
    display: 'flex',
    alignItems: isMobile ? 'flex-start' : 'center',
    flexDirection: 'column',
    rowGap: isMobile ? '5px' : '12px',
    marginTop: isMobile ? 0 : '12px'
  });

export const fileCard = (isMobile: boolean): string =>
  css({
    label: 'single-file-loader__file-card',
    height: isMobile ? 'auto' : '240px',
    padding: isMobile ? '10px 0' : 0,
    boxShadow: isMobile ? 'none' : '0px 5px 15px rgba(0, 0, 0, 0.05)',
    backgroundColor: isMobile ? 'transparent' : '#f6f6f6',
    borderRadius: isMobile ? 0 : '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'column',
    columnGap: isMobile ? '15px' : 0
  });

export const fileCardContent = (isMobile: boolean): string =>
  css({
    label: 'single-file-loader__file-card-content',
    overflow: 'hidden',
    position: 'relative',
    width: isMobile ? '44px' : 'auto',
    height: isMobile ? '44px' : '170px'
  });

export const fileCardImage = css({
  label: 'single-file-loader__file-card-image',
  height: '100%',
  margin: '0 auto',
  display: 'block'
});

export const fileCardContentIcon = css({
  label: 'single-file-loader__file-card-content-icon',
  position: 'absolute',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  top: '50%',
  zIndex: 1
});

export const fileCardContentButton = css(fileCardContentIcon, {
  label: 'single-file-loader__file-card-content-button',
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0,
  zIndex: 2,
  cursor: 'pointer',
  outline: 'none'
});

export const fileCardContentMobileShade = css({
  label: 'single-file-loader__file-card-content-mobile-shade',
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.5)',
  inset: 0,
  zIndex: -1
});

export const fileCardContentLoader = css({
  label: 'single-file-loader__file-card-content-loader',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate3d(-50%, -50%, 0) rotate(-90deg)',
  zIndex: 2
});

export const fileCardFooter = (isMobile: boolean): string =>
  css({
    label: 'single-file-loader__file-card-footer',
    padding: isMobile ? 0 : '15px 20px',
    flexGrow: 1,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between'
  });

export const fileCardFooterContent = css({
  label: 'single-file-loader__file-card-footer-content',
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'hidden',
  maxWidth: 'calc(100% - 44px)'
});

export const fileCardFooterLoader = css({
  label: 'single-file-loader__file-card-footer-loader',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px'
});

export const fileCardFooterButton = css({
  label: 'single-file-loader__file-card-footer-button',
  border: 'none',
  background: 'none',
  borderRadius: 0,
  cursor: 'pointer',
  outline: 'none',
  color: Colors.Grey400,
  width: '36px',

  [':hover']: {
    color: Colors.Secondary
  }
});
