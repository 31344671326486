import 'assets/styles/app.scss';
import { Application } from 'core/components/application';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SetupWorkerApi } from 'msw';
import ReactDOM from 'react-dom';

if (mock) {
  void import('./mocks/browser').then(({ worker }: { worker: SetupWorkerApi }) => {
    void worker.start();
  });
}

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(localizedFormat);

ReactDOM.render(<Application />, document.querySelector('.root'));
