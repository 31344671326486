import { TCityModel } from 'common/models/rpc/lime';
import { useCallback } from 'react';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { LinkIcon } from '@t15-ui-kit/linkIcon';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { Trash } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Trash';
import { limeTransport } from 'common/transport/lime';

type TCityProps = {
  city: TCityModel;
  sellerId: number;
  reloadData: VoidFunction;
};

export const City = ({ city, sellerId, reloadData }: TCityProps): JSX.Element => {
  const isMobile = useMobile();

  const handleDelete = useCallback(() => {
    limeTransport
      .deleteSellerCityLink({
        sellerId,
        cityId: city.id
      })
      .finally(() => reloadData());
  }, [reloadData, city.id, sellerId]);

  return (
    <DCellLabel
      accRight={
        <LinkIcon hideText icon={<Trash color="Alert" />} onClick={handleDelete}>
          {''}
        </LinkIcon>
      }
      offset={[isMobile ? 'xs' : 's', null]}
    >
      <Typography type="h3" size="l" weight="500" wordBreak="break-word">
        {city.name}
      </Typography>
    </DCellLabel>
  );
};
