import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import { DropDownMenuOption } from 'common/components/ui/drop-down-menu';
import { ReactElement, ReactFragment } from 'react';

export type TSettingsOptionProps = {
  children: string | ReactFragment;
  icon: ReactElement<IIconProps>;
  color?: ColorsBasicEnum.Primary | ColorsBasicEnum.Alert;
  onClick(): void;
};

export function SettingsOption({
  children,
  icon,
  color = ColorsBasicEnum.Primary,
  onClick
}: TSettingsOptionProps): JSX.Element {
  return (
    <DropDownMenuOption icon={icon} color={color} onClick={onClick}>
      {children}
    </DropDownMenuOption>
  );
}
