import { Avatar } from '@t15-ui-kit/avatar';
import { Checkbox } from '@t15-ui-kit/checkbox';
import { ContextMenu, MenuItem } from '@t15-ui-kit/contextMenu';
import { DAlert } from '@t15-ui-kit/dAlert';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { ColorsBasicEnum, ColorsCategoryEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Person } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Person';
import { Trash } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Trash';
import { Undo } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Undo';
import { MoreVertical } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/MoreVertical';
import { UserModel } from 'common/models/rpc/lime';
import { getUserAvatarByLogin } from 'common/utils/features/get-user-avatar-by-login';
import { propagateError } from 'common/utils/processing/propagate-error';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { css } from 'emotion';
import { DeleteSellerConfirmSheet } from 'modules/seller/views/list/components/delete-seller-confirm-sheet';
import { sellerListComposition } from 'modules/seller/views/list/compositions/seller-list.composition';
import { useCallback, useState } from 'react';

const overflowHidden = css({ overflow: 'hidden' });

type TSellerCellProps = {
  user: UserModel;
};

const deletedIconClassName = css({
  filter: 'grayscale(100%)'
});

export const SellerCell = ({ user }: TSellerCellProps): JSX.Element => {
  const isMobile = useMobile();

  const [isDeleteConfirmSheetOpen, setDeleteConfirmSheetOpen] = useState(false);
  const handleConfirmDelete = useCallback(async () => {
    return sellerListComposition.deleteUser(user.id).catch(propagateError);
  }, [user]);

  const handleClickDelete = useCallback(() => {
    setDeleteConfirmSheetOpen(true);
  }, []);
  const handleClickRestore = useCallback(() => {
    sellerListComposition.restoreUser(user.id).catch(propagateError);
  }, [user]);
  const handleClickToggleWebRestriction = useCallback(() => {
    const nextWebRestriction = !user.webRestriction;
    sellerListComposition
      .toggleWebRestriction(user.id, nextWebRestriction)
      .then(
        () =>
          void uiKitService.openAlert(
            <DAlert type="Success" onClose={uiKitService.closeAlert}>
              {nextWebRestriction
                ? `Для ${user.name} теперь lime доступен ТОЛЬКО с\xA0мобильного приложения`
                : `Для ${user.name} теперь lime доступен на\xA0всех платформах`}
            </DAlert>
          )
      )
      .catch(propagateError);
  }, [user]);

  return (
    <div>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid className={overflowHidden} grow="1">
          <DCellLabel
            accLeft={
              <div className={user.isDeleted ? deletedIconClassName : void 0}>
                {user.partner ? (
                  <Avatar bgr={ColorsCategoryEnum.Sky} size="m" se>
                    <Person color={ColorsBasicEnum.Default} />
                  </Avatar>
                ) : (
                  <Avatar size="m" src={getUserAvatarByLogin(user.user)} se />
                )}
              </div>
            }
            onClick={() => coreRootRouting.goToSeller({ id: String(user.id) })}
            offset={[isMobile ? 'xs' : 's', null]}
          >
            <Typography
              type="h3"
              size="l"
              weight="500"
              wordBreak="break-word"
              decoration={user.isDeleted ? 'line-through' : void 0}
              ellipsis
            >
              {user.name}
            </Typography>
          </DCellLabel>
        </Grid>
        <Grid shrink="0">
          <ContextMenu icon={<MoreVertical />} position="left">
            <MenuItem>
              <Checkbox checked={user.webRestriction} onChange={handleClickToggleWebRestriction} switch right>
                <Typography align="left" size="m" weight="500">
                  Доступ только с&nbsp;мобильного приложения
                </Typography>
              </Checkbox>
            </MenuItem>
            <MenuItem onClick={user.isDeleted ? handleClickRestore : handleClickDelete}>
              <Grid container justify="space-between">
                <Grid>
                  <Typography color={user.isDeleted ? 'Primary' : 'Alert'} size="m" weight="500">
                    {user.isDeleted ? 'Восстановить' : 'Удалить'}
                  </Typography>
                </Grid>
                <Grid>{user.isDeleted ? <Undo color="Primary" size="m" /> : <Trash color="Alert" size="m" />}</Grid>
              </Grid>
            </MenuItem>
          </ContextMenu>
        </Grid>
      </Grid>
      <DeleteSellerConfirmSheet
        isOpen={isDeleteConfirmSheetOpen}
        user={user}
        onConfirm={handleConfirmDelete}
        onCloseRequest={() => setDeleteConfirmSheetOpen(false)}
      />
    </div>
  );
};
