import { Toolbar } from '@t15-ui-kit/toolbar';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext } from 'react';

export function CommentsForMeetingActionFooter(): JSX.Element {
  const { store } = useContext(MeetingSingleCompositionContext);

  const status = useRxBind(store.status$);

  const handleClickAddComment = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.ADD_COMMENT_FOR_MEETING;
  }, [store]);

  if (status !== MEETING_STATUS.DRAFT) {
    return <></>;
  }

  return <Toolbar onClick={handleClickAddComment} title="Добавить комментарий" />;
}
