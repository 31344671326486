import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { DatetimeOfSpecialMeetingActionDate } from 'modules/special/views/single/components/actions/datetime-of-special-meeting-action/datetime-of-special-meeting-action-date';
import { DatetimeOfSpecialMeetingActionTime } from 'modules/special/views/single/components/actions/datetime-of-special-meeting-action/datetime-of-special-meeting-action-time';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext, useEffect } from 'react';

export function DatetimeOfSpecialMeetingAction(): JSX.Element {
  const { datetime } = useContext(SpecialSingleCompositionContext);

  const isMobile = useMobile();

  useEffect((): VoidFunction => {
    return (): void => {
      datetime.revert();
    };
  }, [datetime]);

  return (
    <Grid container direction="column" elementOffset={[isMobile ? 's' : 'xl']} wrap="nowrap">
      <Grid>
        <DatetimeOfSpecialMeetingActionDate />
      </Grid>
      <Grid>
        <DatetimeOfSpecialMeetingActionTime />
      </Grid>
    </Grid>
  );
}
