import { CellOffset } from '@t15-ui-kit/cellOffset';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Typography } from '@t15-ui-kit/typography';
import { ReactNode } from 'react';

type TMeetingsCardsGroupProps = {
  title: string;
  children: ReactNode;
};

export function MeetingsCardsGroup({ title, children }: TMeetingsCardsGroupProps): JSX.Element {
  return (
    <>
      <CellOffset offset={['s', null]}>
        <Typography type="h2" color={ColorsBasicEnum.Secondary} size="m" weight="500">
          {title}
        </Typography>
      </CellOffset>

      {children}
    </>
  );
}
