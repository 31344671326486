import { MeetingCard } from 'common/components/ui/meeting-card';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { MeetingDataModel } from 'common/models/rpc/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { coreModalRouting } from 'core/providers/core-modal-routing.provider';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { TakeMeetingToWorkSection } from 'modules/meeting/views/list/components/sections/take-meeting-to-work-section';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { memo, useCallback, useContext, useState } from 'react';

type TBriefOfMeetingSectionProps = {
  data: MeetingDataModel;
};

export const BriefOfMeetingSection = memo(({ data }: TBriefOfMeetingSectionProps): JSX.Element => {
  const { type, takeToWork } = useContext(MeetingListCompositionContext);

  const [isTakeToWorkOpen, setTakeToWorkOpen] = useState(false);

  const handleClick = useCallback((): void => {
    if (type === MEETING_LIST_TYPE.AVAILABLE) {
      setTakeToWorkOpen(true);
    } else {
      coreModalRouting.goToMeeting({ id: data.id.toString() });
    }
  }, [data.id, type]);

  const handleConfirmTakeToWork = useCallback((): Promise<void> => {
    return takeToWork(data.id)
      .then((): void => setTakeToWorkOpen(false))
      .then((): void => {
        coreRootRouting.goToMeetings({ type: MEETING_LIST_TYPE.MY });
        coreModalRouting.goToMeeting({ id: data.id.toString() });
      })
      .catch(propagateError);
  }, [data.id, takeToWork]);
  const handleCloseRequestTakeToWork = useCallback((): void => setTakeToWorkOpen(false), []);

  return (
    <>
      <MeetingCard data={data} onClick={handleClick} />

      {type === MEETING_LIST_TYPE.AVAILABLE && (
        <TakeMeetingToWorkSection
          isOpen={isTakeToWorkOpen}
          onConfirm={handleConfirmTakeToWork}
          onCloseRequest={handleCloseRequestTakeToWork}
        />
      )}
    </>
  );
});
