import { AddCommentForMeetingAction } from 'modules/meeting/views/single/components/actions/add-comment-for-meeting-action';
import { AddressAndDatetimeOfMeetingAction } from 'modules/meeting/views/single/components/actions/address-and-datetime-of-meeting-action';
import { AssignMeetingToCourierAction } from 'modules/meeting/views/single/components/actions/assign-meeting-to-courier-action';
import { CommentsForMeetingAction } from 'modules/meeting/views/single/components/actions/comments-for-meeting-action';
import { PhotosOfMeetingAction } from 'modules/meeting/views/single/components/actions/photos-of-meeting-action';
import { ViewMeetingAction } from 'modules/meeting/views/single/components/actions/view-meeting-action';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { ComponentType } from 'react';

export const MEETING_SINGLE_ACTION_CONTENT_COMPONENT_DICTIONARY: Record<MEETING_SINGLE_ACTION, ComponentType> = {
  [MEETING_SINGLE_ACTION.VIEW_MEETING]: ViewMeetingAction,
  [MEETING_SINGLE_ACTION.ADDRESS_AND_DATETIME_OF_MEETING]: AddressAndDatetimeOfMeetingAction,
  [MEETING_SINGLE_ACTION.PHOTOS_OF_MEETING]: PhotosOfMeetingAction,
  [MEETING_SINGLE_ACTION.COMMENTS_FOR_MEETING]: CommentsForMeetingAction,
  [MEETING_SINGLE_ACTION.ADD_COMMENT_FOR_MEETING]: AddCommentForMeetingAction,
  [MEETING_SINGLE_ACTION.ASSIGN_MEETING_TO_COURIER]: AssignMeetingToCourierAction
};
