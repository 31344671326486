import { NameCellSkeleton } from 'common/components/ui/name-cell-skeleton-list/name-cell-skeleton';

const SKELETON_ITEM_COUNT = 10;

export const NameCellListSkeleton = (): JSX.Element => {
  return (
    <>
      {Array(SKELETON_ITEM_COUNT)
        .fill(null)
        .map((_item: null, index: number) => (
          <NameCellSkeleton key={index} />
        ))}
    </>
  );
};
