import { IEquality } from 'common/interfaces/equality.interface';
import { formatTimeSlot } from 'common/utils/formatters/format-time-slot';

export class TimeSlotModel implements IEquality {
  hour: number = 0;

  minute: number = 0;

  get timestamp(): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return this.hour * 60 + this.minute;
  }

  constructor(data: Omit<TimeSlotModel, 'toServer' | 'equal' | 'timestamp'>) {
    Object.assign(this, data);
  }

  static fromServer(time?: string): NotRequired<TimeSlotModel> {
    if (time) {
      const [hour, minute] = time.split(':').map(Number);

      return new TimeSlotModel({ hour, minute });
    } else {
      return void 0;
    }
  }

  equal(timeSlot: TimeSlotModel): boolean {
    return this.hour === timeSlot.hour && this.minute === timeSlot.minute;
  }

  toServer(): string {
    return formatTimeSlot(this);
  }
}
