import { CellOffset } from '@t15-ui-kit/cellOffset';
import { MeetingCardSkeleton } from 'common/components/ui/meeting-card';
import * as styles from 'common/components/ui/meetings-cards-group/meetings-cards-group.styles';
import { memo } from 'react';

const LIST_COUNT = 3;

export const MeetingsCardsGroupSkeleton = memo((): JSX.Element => {
  return (
    <>
      <CellOffset offset={['s', null]}>
        <div className={styles.skeletonTitle} />
      </CellOffset>

      {Array(LIST_COUNT)
        .fill(0)
        .map(
          (_: number, index: number): JSX.Element => (
            <MeetingCardSkeleton key={index} />
          )
        )}
    </>
  );
});
