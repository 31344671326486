import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { useMobile } from '@t15-ui-kit/media';
import * as styles from './name-cell-skeleton.styles';
import { memo } from 'react';

export const NameCellSkeleton = memo((): JSX.Element => {
  const isMobile = useMobile();

  return (
    <DCellLabel accLeft={<div className={styles.skeletonIcon} />} offset={[isMobile ? 'xs' : 's', null]}>
      <div className={styles.skeletonName} />
    </DCellLabel>
  );
});
