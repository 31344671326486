import { GetSellerListResponseModel, UserModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { SellerListStore } from 'modules/seller/views/list/stores/seller-list.store';

const SELLER_LIST_LIMIT = 20;

export class SellerListComposition {
  readonly store: SellerListStore = new SellerListStore();
  private currentPage: number = 0;
  private lastQuery?: string;
  isAllLoaded: boolean = false;
  isLoading?: boolean;

  loadList = (): void => {
    if (this.isAllLoaded || this.isLoading) {
      return;
    }

    this.isLoading = true;

    limeTransport
      .getSellerList({
        query: this.store.query,
        page: this.currentPage++,
        limit: SELLER_LIST_LIMIT
      })
      .then(({ sellers, pagination }: GetSellerListResponseModel): void => {
        const isOverrideList = this.store.query !== this.lastQuery || !this.currentPage;

        this.isAllLoaded = pagination.lastPage <= this.currentPage;

        this.store.list = isOverrideList ? sellers : [...(this.store.list ?? []), ...sellers];

        this.lastQuery = this.store.query;
      })
      .catch(propagateError)
      .finally((): void => {
        this.isLoading = false;
      });
  };

  deleteUser = async (id: number): Promise<void> => {
    await limeTransport.editSeller(id, { deleted: true });

    const nextList =
      this.store.list?.map((user: UserModel): UserModel => {
        if (user.id === id) {
          return new UserModel({ ...user, isDeleted: true });
        } else {
          return user;
        }
      }) ?? [];

    this.store.list = nextList;
  };

  toggleWebRestriction = async (id: number, webRestriction: boolean): Promise<void> => {
    await limeTransport.editSeller(id, { webRestriction });

    const nextList =
      this.store.list?.map((user: UserModel): UserModel => {
        if (user.id === id) {
          return new UserModel({ ...user, webRestriction });
        } else {
          return user;
        }
      }) ?? [];

    this.store.list = nextList;
  };

  restoreUser = async (id: number): Promise<void> => {
    await limeTransport.editSeller(id, { deleted: false });

    const nextList =
      this.store.list?.map((user: UserModel): UserModel => {
        if (user.id === id) {
          return new UserModel({ ...user, isDeleted: false });
        } else {
          return user;
        }
      }) ?? [];

    this.store.list = nextList;
  };

  forceLoadList = (): void => {
    this.isAllLoaded = false;
    this.currentPage = 0;
    this.store.list = undefined;

    this.loadList();
  };

  reset = (): void => {
    this.store.reset();

    this.isAllLoaded = false;
    this.lastQuery = undefined;
    this.currentPage = 0;
  };
}

export const sellerListComposition = new SellerListComposition();
