setTitle.stack = [document.title] as Array<string>;

setTitle.restore = (): void => {
  if (setTitle.stack.length > 1) {
    setTitle.stack.pop();
    document.title = setTitle.stack[setTitle.stack.length - 1];
  }
};

export function setTitle(title: string): void {
  document.title = title;
  setTitle.stack.push(title);
}
