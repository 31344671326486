import { ActionSheet, ActionSheetRow } from '@t15-ui-kit/actionSheet';
import { Trash } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Trash';
import { UserModel } from 'common/models/rpc/lime';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { useCallback } from 'react';

type TDeleteSellerConfirmSheetProps = {
  isOpen: boolean;
  user: UserModel;
  onConfirm(): Promise<void>;
  onCloseRequest(): void;
};

export function DeleteSellerConfirmSheet({
  isOpen,
  user,
  onConfirm,
  onCloseRequest
}: TDeleteSellerConfirmSheetProps): JSX.Element {
  const [isLoading, setLoading] = useSafeState(false);

  const handleClickConfirm = useCallback((): void => {
    setLoading(true);

    onConfirm().finally((): void => {
      setLoading(false);
      onCloseRequest();
    });
  }, [onConfirm, onCloseRequest, setLoading]);

  return (
    <ActionSheet text={`Удалить пользователя ${user.name}?`} onClose={onCloseRequest} isOpen={isOpen}>
      <ActionSheetRow
        text="Да, удалить"
        color="Alert"
        icon={<Trash color="Alert" />}
        onClick={handleClickConfirm}
        isLoading={isLoading}
      />
    </ActionSheet>
  );
}
