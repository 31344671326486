import { IAvatarProps } from '@t15-ui-kit/avatar/interface';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Copy } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Copy';
import * as styles from 'common/components/ui/like-navigator/like-navigator.styles';
import { copyToClipboard } from 'common/utils/features/copy-to-clipboard';
import { noop } from 'common/utils/features/noop';
import { cloneElement, ReactElement, ReactNode, useCallback } from 'react';

export type TLikeNavigatorRowProps = {
  children: ReactNode;
  title: string;
  accLeft?: ReactElement<IAvatarProps>;
  copyValue?: string;
  isReversed?: boolean;
  onClick?(): void;
};

export function LikeNavigatorRow({
  title,
  children,
  accLeft,
  copyValue,
  isReversed,
  onClick
}: TLikeNavigatorRowProps): JSX.Element {
  const isMobile = useMobile();

  const handleClickCopy = useCallback(() => {
    if (!copyValue) {
      return;
    }

    copyToClipboard(copyValue)
      .then((): void => void uiKitService.openCopyAlert('Скопировано').catch(noop))
      .catch(noop);
  }, [copyValue]);

  return (
    <div className={styles.row(isMobile, !!onClick)}>
      <CellOffset offset={[isMobile ? 'xs' : 's', 'm']}>
        <Grid container alignItems="center" elementOffset={['none', 's']} wrap="nowrap">
          {!!accLeft && <Grid>{cloneElement(accLeft, { size: 'm' })}</Grid>}

          <Grid grow="1">
            <Grid
              container
              direction={isReversed ? 'column-reverse' : 'column'}
              elementOffset={['2xs', 'none']}
              wrap="nowrap"
            >
              <Grid>
                <Typography type="div" size="s" weight="500" color={ColorsBasicEnum.Secondary}>
                  {title}
                </Typography>
              </Grid>
              <Grid>
                <Typography type="div" size="m" weight="500">
                  {children}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {!!copyValue && (
            <Grid>
              <div className={styles.copyButton} onClick={handleClickCopy}>
                <Copy color={ColorsBasicEnum.Brand} />
              </div>
            </Grid>
          )}
        </Grid>
      </CellOffset>
    </div>
  );
}
