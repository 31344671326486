import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { UserModel } from 'common/models/rpc/lime';
import { BehaviorSubject } from 'rxjs';

export class SellerListStore implements IDestroyable {
  readonly list$: BehaviorSubject<NotRequired<Array<UserModel>>> = new BehaviorSubject<NotRequired<Array<UserModel>>>(
    void 0
  );
  readonly query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get list(): NotRequired<Array<UserModel>> {
    return this.list$.getValue();
  }

  set list(list: NotRequired<Array<UserModel>>) {
    this.list$.next(list);
  }

  get query(): string {
    return this.query$.getValue();
  }

  set query(query: string) {
    this.query$.next(query);
  }

  destroy(): void {
    this.list$.complete();
    this.query$.complete();
  }

  reset(): void {
    this.list = undefined;
    this.query = '';
  }
}
