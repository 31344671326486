import { deserialize, JsonArray, JsonStruct } from 'tserialize';
import { UserModel } from './internal';
import { PaginationModel } from './pagination.model';

export class GetUserListResponseModel {
  @JsonArray(UserModel)
  sellers!: Array<UserModel>;

  @JsonStruct(PaginationModel)
  pagination!: PaginationModel;

  static fromServer(data: object): GetUserListResponseModel {
    return deserialize(data, GetUserListResponseModel);
  }
}
