import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { PropsWithChildren } from 'react';

type TDashboardProps = PropsWithChildren<{
  title: string;
}>;

export function Dashboard({ children, title }: TDashboardProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <div>
      <Typography type="h1" size={isMobile ? '2xl' : '4xl'} weight="700">
        {title}
      </Typography>
      <CellEmpty height={isMobile ? '30' : '60'} />
      {children}
    </div>
  );
}
