import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { LinkIcon } from '@t15-ui-kit/linkIcon';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Plus } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Plus';
import { FilterSection } from 'common/components/ui/filter-section';
import { NameCellListSkeleton } from 'common/components/ui/name-cell-skeleton-list/name-cell-list-skeleton';
import { SpinnerAtCenter } from 'common/components/ui/spinner-at-center';
import { ScrollableElementRefContext } from 'common/contexts/scrollable-element-ref.context';
import { UserModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { sellerListComposition } from 'modules/seller/views/list/compositions/seller-list.composition';
import { useCallback, useContext, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { AddSellerPopup } from './add-seller-popup';
import { SellerCell } from './seller-cell';

const INFINITE_SCROLL_THRESHOLD = 150;

export const SellerList = (): JSX.Element => {
  const { isAllLoaded, store, loadList, forceLoadList, reset } = sellerListComposition;

  const query = useRxBind(store.query$);
  const sellerList = useRxBind(store.list$);

  const scrollableElementRef = useContext(ScrollableElementRefContext);

  const handleRequestScrollParent = useCallback(
    (): HTMLElement | null => scrollableElementRef.current,
    [scrollableElementRef]
  );

  const handleFilterChange = useCallback(
    (value: string): void => {
      store.query = value;
    },
    [store]
  );

  useEffect(() => {
    loadList();

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={!isAllLoaded}
      loader={isAllLoaded || !sellerList ? void 0 : <SpinnerAtCenter key="loader" />}
      loadMore={loadList}
      threshold={INFINITE_SCROLL_THRESHOLD}
      getScrollParent={handleRequestScrollParent}
    >
      <CellEmpty height={30} />
      <LinkIcon
        icon={<Plus color={ColorsBasicEnum.Brand} />}
        onClick={() => uiKitService.openPopup(<AddSellerPopup loadData={forceLoadList} />)}
      >
        Добавить пользователя
      </LinkIcon>
      <CellEmpty height={30} />
      <FilterSection
        query={query}
        loadData={forceLoadList}
        onInputChange={handleFilterChange}
        placeholder="Поиск по пользователям"
      />
      <div>
        {sellerList ? (
          sellerList.length ? (
            sellerList.map((seller: UserModel): JSX.Element => <SellerCell user={seller} key={seller.id} />)
          ) : (
            <EmptyView text="Тут ничего нет" />
          )
        ) : (
          <NameCellListSkeleton />
        )}
      </div>
    </InfiniteScroll>
  );
};
