import { Grid } from '@t15-ui-kit/grid';
import { Avatar } from '@t15-ui-kit/icon';
import { ColorsBasic } from '@t15-ui-kit/types';
import { Typography } from '@t15-ui-kit/typography';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import * as styles from 'common/components/ui/drop-down-menu/drop-down-menu.styles';
import { cloneElement, PropsWithChildren, ReactElement } from 'react';

export type TDropDownMenuOptionProps = PropsWithChildren<{
  color?: ColorsBasic;
  icon?: ReactElement<IIconProps>;
  onClick?(): void;
}>;

export function DropDownMenuOption({
  children,
  color = 'Primary',
  icon,
  onClick
}: TDropDownMenuOptionProps): JSX.Element {
  return (
    <div className={styles.option} onClick={onClick}>
      <Grid container alignItems="center" elementOffset={['none', 's']} wrap="nowrap">
        {!!icon && (
          <Grid>
            <Avatar size="xs">{cloneElement(icon, { color, size: 'l' })}</Avatar>
          </Grid>
        )}

        <Grid>
          <Typography whiteSpace="nowrap" type="span" size="m" weight="500" color={color}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
