import { useEffect, useState } from 'react';

export function usePreview(file?: File): NotRequired<string> {
  const [preview, setPreview] = useState<NotRequired<string>>(void 0);

  useEffect((): void => {
    if (file && file.type.indexOf('image') !== -1 && file.type !== 'image/tiff') {
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview(void 0);
    }
  }, [file]);

  useEffect((): VoidFunction => {
    return (): void => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  return preview;
}
