import { Button } from '@t15-ui-kit/button';
import { Checkbox } from '@t15-ui-kit/checkbox';
import { DControl, DControlGroup } from '@t15-ui-kit/dControl';
import { ErrorShortView } from '@t15-ui-kit/errorShortView';
import { Modal } from '@t15-ui-kit/modal';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { GroupInfoModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { isDeepEqual } from 'common/utils/features/is-deep-equal';
import { useCallback, useEffect, useMemo, useState } from 'react';

type TGroupSelectState = {
  isLoading: boolean;
  error?: unknown;
  availableGroups?: Array<GroupInfoModel>;
};

type TGroupSelectPopupProps = {
  currentGroupIds?: Array<number>;
  onSelect(groups: Array<GroupInfoModel>): void;
};

export function GroupSelectPopup({ currentGroupIds = [], onSelect }: TGroupSelectPopupProps): JSX.Element {
  const [state, setState] = useState<TGroupSelectState>({ isLoading: true });
  const [groupIds, setGroupIds] = useState<Array<number>>(currentGroupIds);

  useEffect(() => {
    limeTransport
      .getGroupsList()
      .then((availableGroups: Array<GroupInfoModel>) => {
        setState({ isLoading: false, availableGroups });
      })
      .catch((error: unknown) => setState({ isLoading: false, error }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(
    () => isDeepEqual([...groupIds].sort(), [...currentGroupIds].sort()),
    [groupIds, currentGroupIds]
  );

  const handleSelect = useCallback(() => {
    onSelect(state.availableGroups?.filter((group: GroupInfoModel) => groupIds.includes(group.id)) ?? []);
    uiKitService.closePopup();
  }, [onSelect, groupIds, state.availableGroups]);

  if (state.isLoading) {
    return <Modal onClose={uiKitService.closePopup} isLoading />;
  }

  if (state.error) {
    return (
      <Modal onClose={uiKitService.closePopup}>
        <ErrorShortView type="service-unavailable" />
      </Modal>
    );
  }

  return (
    <Modal
      headerText="Группа"
      singleButton={
        <Button onClick={handleSelect} disabled={isDisabled}>
          Сохранить
        </Button>
      }
      onClose={uiKitService.closePopup}
    >
      <DControlGroup>
        {state.availableGroups!.map((group: GroupInfoModel) => {
          const isChecked = groupIds.includes(group.id);

          return (
            <DControl key={group.id}>
              <Checkbox
                onChange={() =>
                  setGroupIds(
                    isChecked
                      ? groupIds.filter((iterableGroupId: number) => iterableGroupId !== group.id)
                      : [...groupIds, group.id]
                  )
                }
                checked={isChecked}
                switch
                right
              >
                {group.name}
              </Checkbox>
            </DControl>
          );
        })}
      </DControlGroup>
    </Modal>
  );
}
