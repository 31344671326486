import { AddCommentForSpecialMeetingActionFooter } from 'modules/special/views/single/components/actions/add-comment-for-special-meeting-action';
import { AssignSpecialMeetingToCourierActionFooter } from 'modules/special/views/single/components/actions/assign-special-meeting-to-courier-action';
import { AssignSpecialMeetingToSellerActionFooter } from 'modules/special/views/single/components/actions/assign-special-meeting-to-seller-action';
import { CommentsForSpecialMeetingActionFooter } from 'modules/special/views/single/components/actions/comments-for-special-meeting-action';
import { DatetimeOfSpecialMeetingActionFooter } from 'modules/special/views/single/components/actions/datetime-of-special-meeting-action';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { ComponentType } from 'react';

export const SPECIAL_SINGLE_ACTION_FOOTER_COMPONENT_DICTIONARY: Partial<Record<SPECIAL_SINGLE_ACTION, ComponentType>> =
  {
    [SPECIAL_SINGLE_ACTION.DATETIME_OF_SPECIAL_MEETING]: DatetimeOfSpecialMeetingActionFooter,
    [SPECIAL_SINGLE_ACTION.COMMENTS_FOR_SPECIAL_MEETING]: CommentsForSpecialMeetingActionFooter,
    [SPECIAL_SINGLE_ACTION.ADD_COMMENT_FOR_SPECIAL_MEETING]: AddCommentForSpecialMeetingActionFooter,
    [SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_SELLER]: AssignSpecialMeetingToSellerActionFooter,
    [SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_COURIER]: AssignSpecialMeetingToCourierActionFooter
  };
