import { Action } from '@t15-ui-kit/action';
import { ColorsBasicEnum, ColorsCategoryEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { Avatar } from '@t15-ui-kit/icon';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { IIconProps } from '@tochka-modules/t15-ui-kit-icons';
import { Person } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Person';
import * as styles from 'common/components/ui/main-page/main-page.styles';
import { Settings, TSettingsOptionProps } from 'common/components/ui/settings';
import { cloneElement, memo, ReactElement, SyntheticEvent, useCallback, useState } from 'react';

export type TMainPageUserProps = {
  name?: string;
  acc?: string | ReactElement<IIconProps>;
  isLoading?: boolean;
  settings?: ReactElement<TSettingsOptionProps> | Array<ReactElement<TSettingsOptionProps>>;
};

export const MainPageUser = memo(({ name, acc = <Person />, isLoading, settings }: TMainPageUserProps): JSX.Element => {
  const isMobile = useMobile();

  const [isOpenSettings, setOpenSettings] = useState(false);

  const handleClickUser = useCallback((event: SyntheticEvent): void => {
    event.nativeEvent.stopImmediatePropagation();

    setOpenSettings((currentIsOpenSettings: boolean): boolean => !currentIsOpenSettings);
  }, []);

  const handleCloseRequestSettings = useCallback((): void => setOpenSettings(false), []);

  return (
    <div>
      {!!acc && (
        <Action cursor={!!settings} onClick={handleClickUser}>
          <Grid>
            <Avatar bgr={ColorsCategoryEnum.Indigo} size="m">
              {typeof acc === 'string' ? (
                <Typography color={ColorsBasicEnum.Default} size="m" weight="700">
                  {acc}
                </Typography>
              ) : (
                cloneElement(acc, { color: ColorsBasicEnum.Default, size: isMobile ? 'm' : 's' })
              )}
            </Avatar>
          </Grid>
        </Action>
      )}

      <Settings
        isOpen={isOpenSettings}
        header={name}
        className={styles.userSettings}
        onCloseRequest={handleCloseRequestSettings}
      >
        {settings ?? []}
      </Settings>
    </div>
  );
});
