import { Action } from '@t15-ui-kit/action';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid';
import { Alert } from '@t15-ui-kit/informer';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { Copy } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Copy';
import { NotPropagatedError } from 'common/errors/not-propagated.error';
import { copyToClipboard } from 'common/utils/features/copy-to-clipboard';
import { noop } from 'common/utils/features/noop';

const ERROR_ALERT_DURATION = 5000;

propagateError.clear = (): void => {
  uiKitService.closeAlert();
};

/** Показать ошибку пользователю */
export function propagateError(error: NotRequired<Error>): void {
  if (!error || error instanceof NotPropagatedError) {
    return;
  }

  const handleClickCopy = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    copyToClipboard(JSON.stringify(error, null, 2))
      .then((): void => void uiKitService.openCopyAlert('Ошибка скопирована').catch(noop))
      .catch(noop);
  };

  window.setTimeout(propagateError.clear, ERROR_ALERT_DURATION);

  console.error(error);

  uiKitService.openAlert(
    <Alert color={ColorsBasicEnum.Alert} duration={ERROR_ALERT_DURATION} onClose={uiKitService.closeAlert} issetClose>
      <Grid container alignItems="center" elementOffset={['none', 's']} wrap="nowrap">
        <Grid grow="1">
          {error.name !== 'Error' && <>{error.name}: </>}
          {error.message}
        </Grid>
        <Grid>
          <Action cursor onClick={handleClickCopy}>
            <Copy color={ColorsBasicEnum.Default} />
          </Action>
        </Grid>
      </Grid>
    </Alert>
  );
}
