import { Colors } from '@t15-ui-kit/enums';
import { hoverOpacity } from '@t15-ui-kit/utils';
import { css } from 'emotion';

export const action = (isMobile: boolean): string =>
  css({
    label: 'like-navigator__action',
    padding: isMobile ? 0 : '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: isMobile ? 'none' : `1px solid transparent`,
    borderRadius: isMobile ? 0 : '6px',
    color: Colors.Grey400,
    transition: 'color .2s ease-in-out, border-color .2s ease-in-out',

    [':hover']: {
      color: isMobile ? Colors.Grey400 : Colors.Brand,
      borderColor: isMobile ? 'transparent' : Colors.Grey300
    }
  });

export const row = (isMobile: boolean, withHover: boolean): string =>
  css({
    label: 'like-navigator__row',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: '#fff',
    cursor: withHover ? 'pointer' : 'inherit',

    [':hover']: {
      backgroundColor: withHover && !isMobile ? Colors.Grey100 : '#fff'
    }
  });

export const button = css({
  label: 'like-navigator__button',
  cursor: 'pointer',
  color: Colors.Brand,
  transition: 'color .2s ease-in-out',

  [':hover']: {
    color: Colors.LinkHover
  }
});

export const showAllButton = (isMobile: boolean): string =>
  css({
    label: 'like-navigator__show-all-button',
    borderTop: `0.5px solid ${Colors.Grey300}`,
    paddingTop: '15px',
    paddingBottom: isMobile ? '10px' : 0,
    cursor: 'pointer',
    color: Colors.Brand,
    transition: 'color .2s ease-in-out',

    [':hover']: {
      color: Colors.LinkHover
    }
  });

export const copyButton = css(
  hoverOpacity,
  css({
    cursor: 'pointer'
  })
);
