import { Typography } from '@t15-ui-kit/typography';
import * as styles from 'common/components/ui/autocomplete/autocomplete.styles';
import { TAutoCompleteOption } from 'common/components/ui/autocomplete/autocomplete.type';
import { useCallback } from 'react';

type TAutoCompleteOptionProps = {
  option: TAutoCompleteOption;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick(option: TAutoCompleteOption): void;
};

export function AutoCompleteOption({ option, isActive, isDisabled, onClick }: TAutoCompleteOptionProps): JSX.Element {
  const handleClick = useCallback((): void => onClick(option), [onClick, option]);

  return (
    <div className={styles.option(isActive ?? false, isDisabled ?? false)} onClick={handleClick}>
      <Typography>{option.value}</Typography>
    </div>
  );
}
