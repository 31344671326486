import { deserialize, JsonNameReadonly } from 'tserialize';

export class UploadMeetingPhotoResponseModel {
  @JsonNameReadonly('id')
  id!: string;

  constructor(data?: UploadMeetingPhotoResponseModel) {
    data && Object.assign(this, data);
  }

  static fromServer(data: object): UploadMeetingPhotoResponseModel {
    return deserialize(data, UploadMeetingPhotoResponseModel);
  }
}
