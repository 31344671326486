import { MeetingTimeSlotSelectRow } from 'common/components/ui/meeting-time-slot-select-row';
import { TimeSlotModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useCallback, useContext, useLayoutEffect } from 'react';

export function AddressAndDatetimeOfMeetingActionTime(): JSX.Element {
  const {
    data: {
      address: { city },
      timezone
    },
    addressAndDatetime: { store, getAvailableTimeSlots }
  } = useContext(MeetingSingleCompositionContext);

  const date = useRxBind(store.date$);

  const [timeSlots, setTimeSlots] = useSafeState<NotRequired<Array<TimeSlotModel>>>();

  useLayoutEffect((): void => {
    if (date) {
      void getAvailableTimeSlots(date).then(setTimeSlots);
    }
  }, [getAvailableTimeSlots, date, setTimeSlots]);

  const handleSelect = useCallback(
    (nextTime: NotRequired<TimeSlotModel>) => {
      store.time = nextTime;
    },
    [store]
  );

  return (
    <MeetingTimeSlotSelectRow
      date={date}
      timeSlots={timeSlots}
      timezone={timezone}
      city={city}
      defaultTime={store.time}
      onSelect={handleSelect}
    />
  );
}
