import { ColorsBasicEnum, ColorsCategoryEnum } from '@t15-ui-kit/enums';
import { Avatar } from '@t15-ui-kit/icon';
import { Image } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Image';
import { Pencil } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Pencil';
import { LikeNavigator, LikeNavigatorAction, LikeNavigatorRow } from 'common/components/ui/like-navigator';
import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { ProductDocumentInfoModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext, useMemo } from 'react';

export function PhotosOfMeetingSection(): JSX.Element {
  const {
    store,
    data: { product },
    addressAndDatetime: {
      store: { date$, time$ }
    },
    photos: {
      store: { photos$ }
    }
  } = useContext(MeetingSingleCompositionContext);

  const status = useRxBind(store.status$);
  const date = useRxBind(date$);
  const time = useRxBind(time$);
  const photos = useRxBind(photos$);

  const docsWithStates = useMemo((): Array<[ProductDocumentInfoModel, boolean]> => {
    return Array.from(
      new Set([
        ...product.documents
          .filter((document: ProductDocumentInfoModel) => document.isRequired)
          .map((document: ProductDocumentInfoModel) => document.type),
        ...(Object.keys(photos) as Array<MEETING_PHOTO_TYPE>)
      ]),
      (type: MEETING_PHOTO_TYPE): NotRequired<[ProductDocumentInfoModel, boolean]> => {
        const document = product.documents.find(
          (iterableDocument: ProductDocumentInfoModel) => iterableDocument.type === type
        );
        if (!document) {
          return void 0;
        }

        return [document, type in photos];
      }
    ).filter(Boolean);
  }, [product, photos]);

  const handleClickEdit = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.PHOTOS_OF_MEETING;
  }, [store]);

  return (
    <LikeNavigator
      title={'Фото на\xA0встрече'}
      actions={
        status === MEETING_STATUS.DRAFT && !!date && !!time ? (
          <LikeNavigatorAction icon={<Pencil />} onClick={handleClickEdit} />
        ) : (
          void 0
        )
      }
    >
      {docsWithStates.map(
        ([document, state]: [ProductDocumentInfoModel, boolean]): JSX.Element => (
          <LikeNavigatorRow
            key={document.type}
            title={state ? 'Загружено' : 'Не\xA0загружено'}
            accLeft={
              state ? (
                <Avatar size="m" bgr={ColorsCategoryEnum.Emerald} se>
                  <Image size="m" color={ColorsBasicEnum.Default} />
                </Avatar>
              ) : (
                void 0
              )
            }
            isReversed
          >
            {document.label}
          </LikeNavigatorRow>
        )
      )}
    </LikeNavigator>
  );
}
