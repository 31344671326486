import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { useMobile } from '@t15-ui-kit/media';
import { Download } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Download';
import { Forward } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Forward';
import { ActionPanel, ActionPanelAction, TActionPanelActionProps } from 'common/components/ui/action-panel';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { ReactElement, useCallback, useContext } from 'react';

export function ActionsMeetingSection(): JSX.Element {
  const isMobile = useMobile();

  const { store } = useContext(MeetingSingleCompositionContext);

  const status = useRxBind(store.status$);
  const downloadsLink = useRxBind(store.downloadsLink$);

  const handleClickAssignToCourier = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.ASSIGN_MEETING_TO_COURIER;
  }, [store]);

  const actions: Array<ReactElement<TActionPanelActionProps>> = [];

  if (status === MEETING_STATUS.DRAFT) {
    actions.push(
      <ActionPanelAction key="assign" icon={<Forward />} onClick={handleClickAssignToCourier}>
        Передать
      </ActionPanelAction>
    );
  }

  if (downloadsLink) {
    actions.push(
      <ActionPanelAction key="download" href={downloadsLink} icon={<Download />} blank>
        Скачать документы
      </ActionPanelAction>
    );
  }

  if (!actions.length) {
    return <></>;
  }

  return (
    <>
      <ActionPanel>{actions}</ActionPanel>
      <CellEmpty height={isMobile ? '15' : '30'} />
    </>
  );
}
