import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { GetSpecialMeetingListRequestModel, MeetingDataModel } from 'common/models/rpc/lime';
import { DefiniteDispatcherService } from 'common/services/dispatcher';
import { limeTransport } from 'common/transport/lime';
import { propagateError } from 'common/utils/processing/propagate-error';
import { SpecialListStore } from 'modules/special/views/list/store/special-list.store';

const DEFAULT_LIMIT = 10;

export enum SPECIAL_LIST_EVENT_TYPE {
  FORCE_LOAD = 'force_load'
}

export class SpecialListComposition implements IDestroyable {
  static readonly dispatcher = DefiniteDispatcherService.make<
    SPECIAL_LIST_EVENT_TYPE,
    {
      [SPECIAL_LIST_EVENT_TYPE.FORCE_LOAD]: void;
    }
  >(Object.values(SPECIAL_LIST_EVENT_TYPE));

  readonly store: SpecialListStore;
  readonly #unlisten: VoidFunction;

  isAllLoaded: boolean = false;
  #isLoading?: boolean;
  #lastPage?: number;

  constructor() {
    this.store = new SpecialListStore();
    this.#unlisten = SpecialListComposition.dispatcher.listenForceLoad(this.#handleForceLoad);
  }

  loadList = (): void => {
    if (this.isAllLoaded || this.#isLoading) {
      return;
    }

    this.#isLoading = true;

    const page = this.#lastPage ?? 0;

    limeTransport
      .getSpecialMeetingList(
        new GetSpecialMeetingListRequestModel({
          page,
          limit: DEFAULT_LIMIT,
          query: this.store.query,
          groupIds: this.store.groupIds,
          statuses: this.store.statuses
        })
      )
      .then((list: Array<MeetingDataModel>) => {
        const isOverrideList = !this.#lastPage;

        this.isAllLoaded = list.length < DEFAULT_LIMIT;
        this.#lastPage = page + 1;

        this.store.list = isOverrideList ? list : [...(this.store.list ?? []), ...list];
      })
      .catch(propagateError)
      .finally((): void => {
        this.#isLoading = false;
      });
  };

  forceLoadList = (): void => {
    this.isAllLoaded = false;
    this.#lastPage = void 0;

    this.loadList();
  };

  destroy(): void {
    propagateError.clear();

    this.store.destroy();

    this.#unlisten();
  }

  #handleForceLoad = (): void => {
    this.forceLoadList();
  };
}
