import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { MeetingDataModel } from 'common/models/rpc/lime/internal';
import { BehaviorSubject } from 'rxjs';

export class MeetingListStore implements IDestroyable {
  readonly list$: BehaviorSubject<NotRequired<Array<MeetingDataModel>>> = new BehaviorSubject<
    NotRequired<Array<MeetingDataModel>>
  >(void 0);

  get list(): NotRequired<Array<MeetingDataModel>> {
    return this.list$.getValue();
  }

  set list(list: NotRequired<Array<MeetingDataModel>>) {
    this.list$.next(list);
  }

  readonly query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get query(): string {
    return this.query$.getValue();
  }

  set query(query: string) {
    this.query$.next(query);
  }

  readonly isFiltered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isFiltered(): boolean {
    return this.isFiltered$.getValue();
  }

  set isFiltered(isFiltered: boolean) {
    this.isFiltered$.next(isFiltered);
  }

  destroy(): void {
    this.list$.complete();
    this.query$.complete();
  }
}
