import { useEffect, useState, useCallback, useMemo } from 'react';
import { Modal } from '@t15-ui-kit/modal';
import { Button } from '@t15-ui-kit/button';
import { DControl } from '@t15-ui-kit/dControl';
import { DOption, DSelect } from '@t15-ui-kit/dSelect';
import { limeTransport } from 'common/transport/lime';
import { TCityModel } from 'common/models/rpc/lime';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { propagateError } from 'common/utils/processing/propagate-error';
import { Typography } from '@t15-ui-kit/typography';
import { ColorsShadesEnum } from '@t15-ui-kit/enums';

type TAddCityPopupProps = { sellerId: number; reloadData: VoidFunction; sellerCityList: Array<TCityModel> };

export const AddCityPopup = ({ sellerId, reloadData, sellerCityList }: TAddCityPopupProps): JSX.Element => {
  const [cityList, setCityList] = useState<Array<TCityModel>>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined);
  const sellerCityIds = useMemo(() => new Set(sellerCityList.map((city: TCityModel) => city.id)), [sellerCityList]);
  const selectedCityName = cityList.find((city: TCityModel) => String(city.id) === selectedCity)?.name;

  useEffect(() => {
    setLoading(true);
    void limeTransport
      .getCityList()
      .then((cities: Array<TCityModel>) => {
        const uniqCityList = cities.filter((city: TCityModel) => !sellerCityIds.has(city.id));
        setCityList(uniqCityList);
      })
      .catch(propagateError)
      .finally(() => setLoading(false));
  }, [sellerCityIds]);

  const handleSelectChange = useCallback((v: string) => {
    setSelectedCity(v);
  }, []);

  const handleAddCity = useCallback(() => {
    void limeTransport
      .addSellerCityLink({
        sellerId,
        cityId: Number(selectedCity)
      })
      .then(() => {
        reloadData();
      })
      .catch(propagateError)
      .finally(() => uiKitService.closePopup());
  }, [selectedCity, sellerId, reloadData]);

  return (
    <Modal
      isLoading={loading}
      headerText="Добавить город"
      singleButton={
        <Button disabled={!selectedCity} onClick={handleAddCity}>
          Добавить
        </Button>
      }
      secondButton={<Button onClick={() => uiKitService.closePopup()}>Закрыть</Button>}
    >
      {cityList && cityList.length ? (
        <DControl>
          <DSelect
            onChange={handleSelectChange}
            label={
              selectedCityName ?? <Typography color={ColorsShadesEnum.Grey400}>Выберите город из списка</Typography>
            }
          >
            {cityList?.map(({ id, name }: TCityModel) => (
              <DOption selected={selectedCity === String(id)} value={String(id)} key={id}>
                {name}
              </DOption>
            ))}
          </DSelect>
        </DControl>
      ) : (
        <Typography type="span" size="s" weight="500">
          Нет доступных для добавления городов
        </Typography>
      )}
    </Modal>
  );
};
