import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { GroupInfoModel, MeetingDataModel } from 'common/models/rpc/lime';
import { limeTransport } from 'common/transport/lime';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

type TSpecialListFilters = {
  query?: string;
  groupIds?: Array<number>;
  statuses?: Array<MEETING_STATUS>;
};

type TSpecialListHelper = {
  groupsByIds: Record<number, GroupInfoModel>;
};

const filtersKey = 'special-list-filters';

export class SpecialListStore implements IDestroyable {
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly list$: BehaviorSubject<NotRequired<Array<MeetingDataModel>>> = new BehaviorSubject<
    NotRequired<Array<MeetingDataModel>>
  >(void 0);

  get list(): NotRequired<Array<MeetingDataModel>> {
    return this.list$.getValue();
  }

  set list(list: NotRequired<Array<MeetingDataModel>>) {
    this.list$.next(list);
  }

  readonly helper$: BehaviorSubject<TSpecialListHelper> = new BehaviorSubject<TSpecialListHelper>({ groupsByIds: {} });

  readonly filters$: BehaviorSubject<TSpecialListFilters>;

  get query(): NotRequired<string> {
    return this.filters$.getValue().query;
  }

  set query(query: NotRequired<string>) {
    this.filters$.next({ ...this.filters$.getValue(), query });
  }

  get groupIds(): NotRequired<Array<number>> {
    return this.filters$.getValue().groupIds;
  }

  set groupIds(groupIds: NotRequired<Array<number>>) {
    this.filters$.next({ ...this.filters$.getValue(), groupIds });
  }

  set groupsByIds(groupsByIds: Record<number, GroupInfoModel>) {
    this.helper$.next({ ...this.helper$.getValue(), groupsByIds });
  }

  get statuses(): NotRequired<Array<MEETING_STATUS>> {
    return this.filters$.getValue().statuses;
  }

  set statuses(statuses: NotRequired<Array<MEETING_STATUS>>) {
    this.filters$.next({ ...this.filters$.getValue(), statuses });
  }

  constructor() {
    let initialFilters: TSpecialListFilters = {};

    const initialFiltersJSON = localStorage.getItem(filtersKey);
    if (initialFiltersJSON) {
      initialFilters = JSON.parse(initialFiltersJSON) as TSpecialListFilters;
    }

    this.filters$ = new BehaviorSubject<TSpecialListFilters>(initialFilters);

    this.filters$.pipe(skip(1)).subscribe((filters: TSpecialListFilters) => {
      localStorage.setItem(filtersKey, JSON.stringify(filters));
    });

    this.isLoading$.next(true);
    this.init().finally(() => this.isLoading$.next(false));
  }

  destroy(): void {
    this.list$.complete();
    this.filters$.complete();
  }

  private async init(): Promise<void> {
    const filters = this.filters$.getValue();

    const groupsByIds: Record<number, GroupInfoModel> = filters.groupIds
      ? Object.fromEntries(
          await Promise.all(
            filters.groupIds.map((groupId: number) =>
              limeTransport
                .getGroup(groupId)
                .then((group: GroupInfoModel) => [groupId, group])
                .catch((error: unknown) => {
                  console.error(error);

                  return [groupId, '?'];
                })
            )
          )
        )
      : {};

    this.helper$.next({ groupsByIds });
  }
}
