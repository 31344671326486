import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { Exit } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Exit';
import { MainPageUser } from 'common/components/ui/main-page';
import { SettingsOption } from 'common/components/ui/settings';
import { formatInitials } from 'common/utils/formatters/format-initials';
import { userContext } from 'core/providers/user-context-provider';
import { authorizationService } from 'modules/authorization/services/authorization.service';
import { memo, useContext } from 'react';

export const UserRootSection = memo((): JSX.Element => {
  const user = useContext(userContext);

  return (
    <MainPageUser
      acc={user ? formatInitials(user.name) : void 0}
      name={user?.name}
      isLoading={user === void 0}
      settings={[
        <SettingsOption key="exit" icon={<Exit />} color={ColorsBasicEnum.Alert} onClick={authorizationService.logout}>
          Выйти
        </SettingsOption>
      ]}
    />
  );
});
