import { AddressSuggestion } from '@svc_bobr/client';
import { DControl } from '@t15-ui-kit/dControl';
import { useMobile } from '@t15-ui-kit/media';
import { AutoComplete } from 'common/components/ui/autocomplete';
import { SimpleRow } from 'common/components/ui/simple-row';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { useCallback, useContext, useState } from 'react';

export function AddressAndDatetimeOfMeetingActionAddress(): JSX.Element {
  const isMobile = useMobile();

  const {
    addressAndDatetime: { store, suggestAddress }
  } = useContext(MeetingSingleCompositionContext);

  const [isInvalid, setInvalid] = useState(false);

  const address = useRxBind(store.address$);
  const handleBlur = useCallback((): void => {
    if (!store.isAddressApproved) {
      setInvalid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = useCallback(
    (nextAddress: string): void => {
      store.isAddressApproved = false;
      store.address = nextAddress;
    },
    [store]
  );
  const handleSelect = useCallback(
    (option: AddressSuggestion): void => {
      store.isAddressApproved = true;
      store.address = option.value;
      setInvalid(false);
    },
    [store]
  );

  return (
    <SimpleRow title="Где">
      <DControl
        type="input"
        borderRadius="l"
        isMobile={isMobile}
        isMobileBottomOffset={false}
        invalidMessage={!!isInvalid && 'Выберите адрес из списка'}
        showOverflow
      >
        <AutoComplete
          value={address ?? ''}
          placeholder="Адрес"
          onBlur={handleBlur}
          onChange={handleChange}
          onSearchRequest={suggestAddress}
          onSelect={handleSelect}
          autoClose
        />
      </DControl>
    </SimpleRow>
  );
}
