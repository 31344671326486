import { LoadingScreen } from 'common/components/ui/loading-screen';
import { useEffect } from 'react';
import { Route, useHistory } from 'react-router';

type TDeferRedirectProps = {
  from: string;
  to: string;
  timeoutMs?: number;
  isExact?: boolean;
};

export function DeferRedirect({ from, to, timeoutMs, isExact }: TDeferRedirectProps): JSX.Element {
  return (
    <Route path={from} exact={isExact}>
      <DeferRedirectRoute to={to} timeoutMs={timeoutMs} />
    </Route>
  );
}

function DeferRedirectRoute({ to, timeoutMs }: Pick<TDeferRedirectProps, 'to' | 'timeoutMs'>): JSX.Element {
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => void setTimeout((): void => history.push(to), timeoutMs ?? 0), []);

  return <LoadingScreen />;
}
