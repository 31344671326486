import { MeetingDataModel } from 'common/models/rpc/lime';
import dayjs from 'dayjs';

const LIST_GROUPS_HELPER_SYMBOL = Symbol('ListGroupsHelper');

export type TMeetingsGroup = {
  list: Array<MeetingDataModel>;
  date?: dayjs.Dayjs;
};

type TMeetingsGroupsHelper = {
  byDates: Record<string, TMeetingsGroup>;
  withoutDate?: TMeetingsGroup;
};

export type TMeetingsGroups = Array<TMeetingsGroup> & { [LIST_GROUPS_HELPER_SYMBOL]?: TMeetingsGroupsHelper };

/** Группирует список встреч: будет создана своя группа под каждую дату и одна общая группа без даты */
export function groupMeetings(list: Array<MeetingDataModel>): TMeetingsGroups {
  return list.reduce(reduceMeetingsToGroups, []);
}

/** Преобразовывает список встреч в группы */
function reduceMeetingsToGroups(acc: TMeetingsGroups, data: MeetingDataModel): TMeetingsGroups {
  // Вспомогательный символ для хранения ссылок
  if (!(LIST_GROUPS_HELPER_SYMBOL in acc)) {
    Object.defineProperty(acc, LIST_GROUPS_HELPER_SYMBOL, { value: { byDates: {} } });
  }

  const helper = acc[LIST_GROUPS_HELPER_SYMBOL] as TMeetingsGroupsHelper;

  if (!data.date) {
    if (!helper.withoutDate) {
      helper.withoutDate = { list: [] };
      acc.push(helper.withoutDate);
    }

    helper.withoutDate.list.push(data);
  } else {
    const date = data.date.format('YYYY-MM-DD');
    if (!(date in helper.byDates)) {
      helper.byDates[date] = { date: data.date, list: [] };
      acc.push(helper.byDates[date]);
    }

    helper.byDates[date].list.push(data);
  }

  return acc;
}
