import { Grid } from '@t15-ui-kit/grid';
import { EmptyView } from '@t15-ui-kit/emptyView';
import { TCityModel } from 'common/models/rpc/lime';
import { useEffect } from 'react';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { CityCell } from './city-cell';
import { CellOffset } from '@t15-ui-kit/cellOffset';
import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { CreateCityPopup } from './create-city-popup';
import { cityListComposition } from '../compositions/city-list.composition';
import { NameCellListSkeleton } from 'common/components/ui/name-cell-skeleton-list/name-cell-list-skeleton';
import { LinkIcon } from '@t15-ui-kit/linkIcon';
import { Plus } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Plus';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';

export const CityList = (): JSX.Element => {
  const { store, loadCityList, reset } = cityListComposition;
  const cityList = useRxBind(store.list$);

  useEffect(() => {
    loadCityList();

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CellEmpty height={30} />
      <LinkIcon
        icon={<Plus color={ColorsBasicEnum.Brand} />}
        onClick={() => uiKitService.openPopup(<CreateCityPopup cityListComposition={cityListComposition} />)}
      >
        Добавить город
      </LinkIcon>
      <CellOffset offset={['s', null]}>
        <CellEmpty height={30} />
        <Grid container direction="column" elementOffset={['xl', 'none']} wrap="nowrap">
          {cityList ? (
            cityList.length ? (
              cityList.map((city: TCityModel): JSX.Element => <CityCell key={city.id} city={city} />)
            ) : (
              <EmptyView text="Тут ничего нет" />
            )
          ) : (
            <NameCellListSkeleton />
          )}
        </Grid>
      </CellOffset>
    </>
  );
};
