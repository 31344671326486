import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { DCellLabel } from '@t15-ui-kit/dCellLabel';
import { Grid } from '@t15-ui-kit/grid/Grid';
import { useMobile } from '@t15-ui-kit/media';
import * as styles from 'common/components/ui/meeting-card/meeting-card.styles';
import { memo } from 'react';

export const MeetingCardSkeleton = memo((): JSX.Element => {
  const isMobile = useMobile();

  return (
    <DCellLabel accLeft={<div className={styles.skeletonIcon} />} offset={[isMobile ? 'xs' : 's', null]}>
      <Grid
        container
        direction={isMobile ? 'column-reverse' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justify={isMobile ? 'flex-start' : 'space-between'}
        elementOffset={isMobile ? ['2xs', 'none'] : void 0}
      >
        <Grid>
          <div className={styles.skeletonAddress} />
        </Grid>
        <Grid>
          <div className={styles.skeletonTime} />
        </Grid>
      </Grid>
      <CellEmpty height="5" />
      <div className={styles.skeletonCompanyName} />
      <CellEmpty height="2" />
      <div className={styles.skeletonTaxCode} />
    </DCellLabel>
  );
});
