import { Button } from '@t15-ui-kit/button';
import { Suitcase } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Suitcase';

type TPartnerLoginButtonProps = {
  onClick(): void;
};

export function PartnerLoginButton({ onClick }: TPartnerLoginButtonProps): JSX.Element {
  return (
    <Button type="secondary" width="100%" accLeft={<Suitcase />} onClick={onClick}>
      Вход для партнёров
    </Button>
  );
}
