import { getData, sizeList } from '@t15-ui-kit/avatar';
import { Radius } from '@t15-ui-kit/enums';
import { skeletonAnimation, skeletonLoafL, skeletonLoafXs } from '@t15-ui-kit/utils';
import { css } from 'emotion';

export const skeletonIcon = css(skeletonAnimation, {
  label: `meeting-card__skeleton-icon`,
  width: sizeList.m,
  height: sizeList.m,
  borderRadius: Radius.se + '!important',
  mask: `url("${getData('m')}")`
});

export const skeletonAddress = css(skeletonLoafL, {
  label: `meeting-card__skeleton-address`,
  width: '240px',
  height: '22px'
});

export const skeletonTime = css(skeletonLoafL, {
  label: `meeting-card__skeleton-time`,
  width: '60px',
  height: '22px'
});

export const skeletonCompanyName = css(skeletonLoafXs, {
  label: `meeting-card__skeleton-company-name`,
  width: '200px',
  height: '18px'
});

export const skeletonTaxCode = css(skeletonLoafXs, {
  label: `meeting-card__skeleton-tax-code`,
  width: '120px',
  height: '15px'
});
