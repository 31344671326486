import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { JsonNameReadonly } from 'tserialize';

export class ProductDocumentInfoModel {
  @JsonNameReadonly('id')
  id!: number;

  @JsonNameReadonly('productId')
  productId!: string;

  @JsonNameReadonly('type')
  type!: MEETING_PHOTO_TYPE;

  @JsonNameReadonly('label')
  label!: string;

  @JsonNameReadonly('required')
  isRequired!: boolean;
}
