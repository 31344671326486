import { Avatar } from '@t15-ui-kit/avatar';
import { ColorsBasicEnum, ColorsCategoryEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { AllColors } from '@t15-ui-kit/types';
import { Suitcase } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Suitcase';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { MeetingDataModel } from 'common/models/rpc/lime';

type TMeetingCardIconProps = {
  data: MeetingDataModel;
  isExpired: boolean;
};

export function MeetingCardIcon({ data, isExpired }: TMeetingCardIconProps): JSX.Element {
  let iconColor: AllColors = ColorsCategoryEnum.Indigo;
  if ([MEETING_STATUS.CANCEL, MEETING_STATUS.DONE].includes(data.status)) {
    iconColor = ColorsShadesEnum.Grey300;
  } else if (isExpired) {
    iconColor = ColorsCategoryEnum.Coral;
  } else if (data.date && data.time) {
    iconColor = ColorsCategoryEnum.Mint;
  }

  return (
    <Avatar bgr={iconColor} size="m" se>
      <Suitcase color={ColorsBasicEnum.Default} size="m" />
    </Avatar>
  );
}
