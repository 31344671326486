import { AddressSuggestion, TipAddressResponse } from '@svc_bobr/client';
import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { IRevertable } from 'common/interfaces/revertable.interface';
import { EditMeetingRequestModel, MeetingDataModel, TimeSlotModel } from 'common/models/rpc/lime';
import { bobrTransport } from 'common/transport/bobr';
import { limeTransport } from 'common/transport/lime';
import { ScheduleMeetings } from 'common/utils/business/schedule-meetings';
import { propagateError } from 'common/utils/processing/propagate-error';
import dayjs from 'dayjs';
import { MeetingAddressAndDatetimeStore } from 'modules/meeting/views/single/stores/meeting-address-and-datetime.store';

export class MeetingAddressAndDatetimeService implements IRevertable, IDestroyable {
  readonly store: MeetingAddressAndDatetimeStore;

  readonly #data: MeetingDataModel;

  constructor(data: MeetingDataModel) {
    this.#data = data;

    this.store = new MeetingAddressAndDatetimeStore(this.#data);
  }

  suggestAddress = (query: string): Promise<Array<AddressSuggestion>> => {
    return bobrTransport
      .tipAddress({
        query,
        locations: [{ city: this.#data.address.city, region: this.#data.address.region }],
        restrictValue: false
      })
      .then(({ suggestions }: TipAddressResponse): Array<AddressSuggestion> => suggestions)
      .catch((error: Error): Array<AddressSuggestion> => {
        propagateError(error);

        return [];
      });
  };

  getAvailableDateSlots = (): Promise<Array<dayjs.Dayjs>> => {
    return new Promise((resolve: (data: Array<dayjs.Dayjs>) => void): void =>
      resolve(ScheduleMeetings.getDateSlotsSchedule())
    );
  };

  getAvailableTimeSlots = (day: dayjs.Dayjs): Promise<Array<TimeSlotModel>> => {
    return new Promise((resolve: (data: Array<TimeSlotModel>) => void): void =>
      resolve(ScheduleMeetings.getTimeSlotsSchedule(day))
    );
  };

  saveAddressAndDatetime = (): Promise<void> => {
    if (!this.store.isAddressApproved) {
      return Promise.reject(new Error('Выберите адрес из списка'));
    }

    return limeTransport
      .editMeeting(
        new EditMeetingRequestModel({
          id: this.#data.id,
          date: this.store.date,
          time: this.store.time,
          address: this.store.address
        })
      )
      .then((): void => this.store.commit());
  };

  revert(): void {
    this.store.revert();
  }

  destroy(): void {
    propagateError.clear();

    this.store.destroy();
  }
}
