import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

export function createHashSubject<T extends string>(defaultValue: T): BehaviorSubject<T> {
  const [, value] = /^#\/([\w-]+)/.exec(location.hash) || [];

  const subject$ = new BehaviorSubject<T>(value ? (value as T) : defaultValue);

  subject$.pipe(skip(1)).subscribe((nextValue: T): void => {
    const url = new URL(location.href);
    url.hash = nextValue === defaultValue ? '' : `/${nextValue}`;

    window.history.replaceState(null, '', url.toString());
  });

  return subject$;
}
