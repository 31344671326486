import { JsonNameReadonly } from 'tserialize';

export class AddressModel {
  @JsonNameReadonly('city')
  city?: string;

  @JsonNameReadonly('region')
  region?: string;

  @JsonNameReadonly('address')
  address?: string;

  constructor(data: AddressModel) {
    Object.assign(this, data);
  }
}
