import { Spinner } from '@t15-ui-kit/circleLoader';
import { Grid } from '@t15-ui-kit/grid';

export function SpinnerAtCenter(): JSX.Element {
  return (
    <Grid container justify="center">
      <Grid>
        <Spinner size="l" />
      </Grid>
    </Grid>
  );
}
