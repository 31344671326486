import { Checkbox } from '@t15-ui-kit/checkbox';
import { DControl, DControlGroup } from '@t15-ui-kit/dControl';
import { SimpleRow } from 'common/components/ui/simple-row';
import { UserModel } from 'common/models/rpc/lime';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { useSafeState } from 'common/utils/hooks/use-safe-state';
import * as styles from 'modules/special/views/single/components/actions/assign-special-meeting-to-seller-action/assign-special-meeting-to-seller-action.styles';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { useContext, useLayoutEffect } from 'react';

export function AssignSpecialMeetingToSellerActionSelect(): JSX.Element {
  const {
    assignToSeller: { store, getAvailableSellers }
  } = useContext(SpecialSingleCompositionContext);

  const sellerId = useRxBind(store.sellerId$);
  const [availableSellers, setAvailableSellers] = useSafeState<NotRequired<Array<UserModel>>>();

  useLayoutEffect((): void => {
    void getAvailableSellers().then(setAvailableSellers);
  }, [getAvailableSellers, setAvailableSellers]);

  if (!availableSellers) {
    return <></>;
  }

  return (
    <SimpleRow title="Выберите партнера">
      <div className={styles.select}>
        <DControlGroup>
          {availableSellers.map((seller: UserModel) => (
            <DControl key={seller.id}>
              <Checkbox
                onChange={() => {
                  store.sellerId = seller.id;
                }}
                checked={seller.id === sellerId}
                right
              >
                {seller.name}
              </Checkbox>
            </DControl>
          ))}
        </DControlGroup>
      </div>
    </SimpleRow>
  );
}
