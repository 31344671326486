import { Grid } from '@t15-ui-kit/grid';
import { useMobile } from '@t15-ui-kit/media';
import { AddressAndDatetimeOfMeetingSection } from 'modules/meeting/views/single/components/sections/address-and-datetime-of-meeting-section';
import { CommentsOfMeetingSection } from 'modules/meeting/views/single/components/sections/comments-of-meeting-section';
import { ActionsMeetingSection } from 'modules/meeting/views/single/components/sections/actions-meeting-section';
import { DetailsOfMeetingSection } from 'modules/meeting/views/single/components/sections/details-of-meeting-section';
import { PhotosOfMeetingSection } from 'modules/meeting/views/single/components/sections/photos-of-meeting-section';

export function ViewMeetingAction(): JSX.Element {
  const isMobile = useMobile();

  return (
    <>
      <ActionsMeetingSection />
      <Grid container direction="column" elementOffset={[isMobile ? 'm' : 'xl', 'none']} wrap="nowrap">
        <Grid>
          <AddressAndDatetimeOfMeetingSection />
        </Grid>
        <Grid>
          <PhotosOfMeetingSection />
        </Grid>
        <Grid>
          <DetailsOfMeetingSection />
        </Grid>
        <Grid>
          <CommentsOfMeetingSection />
        </Grid>
      </Grid>
    </>
  );
}
