import { IDestroyable } from 'common/interfaces/destroyable.interface';
import { TCityModel } from 'common/models/rpc/lime';
import { BehaviorSubject } from 'rxjs';

export class CityListStore implements IDestroyable {
  readonly list$: BehaviorSubject<NotRequired<Array<TCityModel>>> = new BehaviorSubject<NotRequired<Array<TCityModel>>>(
    void 0
  );

  readonly query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get list(): NotRequired<Array<TCityModel>> {
    return this.list$.getValue();
  }

  set list(list: NotRequired<Array<TCityModel>>) {
    this.list$.next(list);
  }

  get query(): string {
    return this.query$.getValue();
  }

  set query(query: string) {
    this.query$.next(query);
  }

  destroy(): void {
    this.list$.complete();
    this.query$.complete();
  }

  reset(): void {
    this.list = undefined;
    this.query = '';
  }
}
