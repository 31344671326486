import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { HttpRequestError } from 'common/services/http-request/http-request.error';
import { IHttpRequestService } from 'common/services/http-request/http-request.interface';
import { LIME_API_URL } from 'common/transport/lime/lime.const';
import { uuid } from 'common/utils/features/uuid';

const defaultTransformRequest = axios.defaults.transformRequest ? [axios.defaults.transformRequest].flat() : [];

export class HttpRequestService implements IHttpRequestService {
  readonly #transport: AxiosInstance;
  readonly #config: AxiosRequestConfig;

  constructor(config?: Partial<AxiosRequestConfig>) {
    this.#config = config ?? {};
    this.#transport = axios.create({
      withCredentials: true,
      xsrfCookieName: 'x-ld-csrf-token',
      xsrfHeaderName: 'x-ld-csrf-token',
      transformRequest: [
        ...defaultTransformRequest,
        (data: unknown, headers?: AxiosRequestHeaders) => {
          if (headers) {
            headers['x-request-id'] = `${window.location.host}-web-${uuid()}`;
          }

          return data;
        }
      ]
    });

    this.#transport.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: unknown) => {
        if (axios.isAxiosError(error)) {
          const { status, request } = error.response ?? {};

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-magic-numbers
          if (status === 401 && !request.responseURL.includes(`${LIME_API_URL}/auth/check`)) {
            location.reload();
            return;
          }
        }

        return Promise.reject(error);
      }
    );
  }

  get<T = object>(url: string): Promise<T> {
    return this.#transport
      .get(url, this.#config)
      .then(({ data }: { data: T }): T => data)
      .catch(this.handleError);
  }

  post<T = object>(url: string, request?: object, signal?: AbortSignal): Promise<T> {
    return this.#transport
      .post(url, request, {
        ...this.#config,
        signal
      })
      .then(({ data }: { data: T }): T => data)
      .catch(this.handleError);
  }

  put<T = object>(url: string, request?: object, signal?: AbortSignal): Promise<T> {
    return this.#transport
      .put(url, request, {
        ...this.#config,
        signal
      })
      .then(({ data }: { data: T }): T => data)
      .catch(this.handleError);
  }

  delete<T = object>(url: string, request?: object): Promise<T> {
    return this.#transport
      .delete(url, {
        ...this.#config,
        data: {
          ...request
        }
      })
      .then(({ data }: { data: T }): T => data)
      .catch(this.handleError);
  }

  upload<T = object>(
    url: string,
    request?: object,
    signal?: AbortSignal,
    onProgress?: (progress: number) => void
  ): Promise<T> {
    return this.#transport
      .post(url, request, {
        ...this.#config,
        signal,
        headers: { ...this.#config.headers, 'Content-Type': 'multipart/form-data' },
        onUploadProgress: onProgress
          ? (event: ProgressEvent): void => {
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              const progress = Math.round((event.loaded / event.total) * 100);

              onProgress(progress);
            }
          : void 0
      })
      .then(({ data }: { data: T }): T => data)
      .catch(this.handleError);
  }

  protected handleError = (error: Error): Promise<never> => {
    return Promise.reject(new HttpRequestError(error));
  };
}

export const httpRequestService = new HttpRequestService();
